import colors from 'assets/styles/variables.scss';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import LazyLoadImage from 'components/lazy-load-image';
import MODAL_TYPES from 'components/modal-container/modal-container.constants';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import { getRemoteImageUrl } from '../../../../../utils/get-remote-image-url';
import { For } from '../../../../ui-core';
import modalSelector from '../../../modal-container.selectors';

import './styles.scss';

const SubscriptionInfo = () => {
  const dispatch = useDispatch();

  const { additionalData } = useSelector(modalSelector);

  const {
    title,
    businessAccountTitle,
    businessAccountImage,
    endDate,
    priceList = [],
  } = additionalData;
  const handleCancelSubscriptionClicked = () => {
    dispatch(
      setModal({
        modalType: MODAL_TYPES['CANCEL-SUBSCRIPTION-STEP-1'],
        isModalVisible: true,
        additionalData,
      }),
    );
  };

  const handleCloseButtonClicked = () => {
    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  const date = dayjs(endDate).format('MMMM D, YYYY');

  return (
    <div className="subscription-info">
      <div className="subscription-info__header">
        <div className="subscription-info__brand">
          <LazyLoadImage
            imageSource={getRemoteImageUrl(businessAccountImage)}
            className="subscription-info__logo"
          />
          <div className="subscription-info__brand__wrapper">
            <p className="subscription-info__brand__title">{businessAccountTitle}</p>
            <p className="subscription-info__secondary-content">{title}</p>
          </div>
        </div>
        <Button
          onClick={handleCloseButtonClicked}
          leftIcon={<SvgIcon type="cross-2" />}
          size={BUTTON_SIZES.ICON}
          className="fit-content"
        />
      </div>
      <div className="subscription-info__content-wrapper">
        <div className="w-50">
          <p className="subscription-info__secondary-content">
            Your plan will automatically renew on November 14, 2023. You will be charged 150$/month.
          </p>
          <div className="subscription-info__status-wrapper">
            <SvgIcon type="warning" />
            <div>
              <p className="subscription-info__secondary-content">Offer</p>
              <p className="subscription-info__primary-content">Active</p>
            </div>
          </div>
          <div className="subscription-info__status-wrapper">
            <SvgIcon type="calendar" />
            <div>
              <p className="subscription-info__secondary-content">Active till</p>
              <p className="subscription-info__primary-content">{date}</p>
            </div>
          </div>
        </div>
        <div className="w-50">
          <p className="subscription-info__primary-content">Plan includes</p>
          <ul className="subscription-info__list">
            <For
              data={priceList}
              render={(item) => {
                return (
                  <li key={item} className="subscription-info__secondary-content">
                    {item}
                  </li>
                );
              }}
            />
            {/* <li className="subscription-info__secondary-content"> */}
            {/*  Chat with participants and supervisors */}
            {/* </li> */}
            {/* <li className="subscription-info__secondary-content"> */}
            {/*  Chat with participants and supervisors */}
            {/* </li> */}
            {/* <li className="subscription-info__secondary-content"> */}
            {/*  Chat with participants and supervisors */}
            {/* </li> */}
            {/* <li className="subscription-info__secondary-content"> */}
            {/*  Chat with participants and supervisors */}
            {/* </li> */}
            {/* <li className="subscription-info__secondary-content"> */}
            {/*  Chat with participants and supervisors */}
            {/* </li> */}
            {/* <li className="subscription-info__secondary-content"> */}
            {/*  Chat with participants and supervisors */}
            {/* </li> */}
          </ul>
        </div>
      </div>
      <div className="subscription-info__controls">
        <Button
          content="Contact support"
          className="fit-content"
          contentColor={colors.white}
          backgroundColor={colors['neutral-gray-700']}
          size={BUTTON_SIZES.MD}
        />
        <Button
          content="Cancel subscription"
          contentColor={colors['system-red-500']}
          backgroundColor={colors['neutral-gray-700']}
          size={BUTTON_SIZES.MD}
          className="fit-content"
          onClick={handleCancelSubscriptionClicked}
        />
      </div>
    </div>
  );
};

export default SubscriptionInfo;
