const LIVE_CHAT_SOCKET_EVENT_TYPES = {
  CONNECT: 'stream-chat:connect:stream-chat',
  CONNECTED: 'connected:stream-chat',
  DISCONNECT: 'stream-chat:disconnect:stream-chat',
  GET_MESSAGES_STREAM_CHAT: 'stream-chat:get-messages:stream-chat',
  GET_MESSAGES_STREAM_CHAT_RESPONSE: 'get-messages:stream-chat',
  SEND_MESSAGE_STREAM_CHAT: 'stream-chat:send-message:stream-chat',
  RECEIVE_MESSAGE_STREAM_CHAT: 'receive-message:stream-chat',
  DELETE_MESSAGE_STREAM_CHAT: 'stream-chat:delete-message:stream-chat',
  MUTE_USER_STREAM_CHAT: 'stream-chat:mute-user:stream-chat',
  MARK_USER_STREAM_CHAT: 'stream-chat:mark-user:stream-chat',
  RESET_USER_STREAM_CHAT: 'stream-chat:reset-user:stream-chat',
  DROPPED_USER_STATUS_STREAM_CHAT: 'dropped-user-status:stream-chat',
  CONFIG_WORDS_STREAM_CHAT: 'stream-chat:config-stop-words:stream-chat',
  GET_STOP_WORDS_STREAM_CHAT: 'stream-chat:get-stop-words:stream-chat',
  GET_STOP_WORDS_STREAM_CHAT_RESPONSE: 'get-stop-words:stream-chat',
  GET_MUTED_AND_MARKED_USERS_STREAM_CHAT: 'stream-chat:get-muted-and-marked-users:stream-chat',
  GET_MUTED_AND_MARKED_USERS_STREAM_CHAT_RESPONSE: 'get-muted-and-marked-users:stream-chat',
  GET_ON_APPROVE_MESSAGES_STREAM_CHAT: 'stream-chat:get-on-approve-messages:stream-chat',
  GET_ON_APPROVE_MESSAGES_STREAM_CHAT_RESPONSE: 'get-on-approve-messages:stream-chat',
  APPROVE_MESSAGE_STREAM_CHAT: 'stream-chat:approve-message:stream-chat',
  APPROVED_MESSAGE_STREAM_CHAT: 'approved-message:stream-chat',
  USER_MUTED_STREAM_CHAT: 'user-muted:stream-chat',
  USER_UNMUTED_STREAM_CHAT: 'user-unmuted:stream-chat',
  DELETED_MESSAGES_STREAM_CHAT: 'deleted-message:stream-chat',
  RECEIVE_ON_APPROVE_MESSAGE_STREAM_CHAT: 'receive-on-approve-message:stream-chat',
  ERROR: 'error',
  USERS_ONLINE: 'users-online:stream-chat',
  CLOSED_STREAM_CHAT: 'closed-chat:stream-chat',
};

export const USER_CHAT_STATUS = {
  MUTED: 'MUTED',
  ACTIVE: 'ACTIVE',
  MARKED: 'MARKED',
};

export default LIVE_CHAT_SOCKET_EVENT_TYPES;
