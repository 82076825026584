import API_LIST from 'constants/api/api-list';
import { get, post } from 'services/api';

export const getCalendarList = async ({ lang, dateFrom, dateTo }) => {
  const url = `${API_LIST.BLOCKS.CALENDAR}?lang=${lang}&dateFrom=${dateFrom}&dateTo=${dateTo}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export const getCalendarEventByID = async (id) => {
  const url = `${API_LIST.BLOCKS.CALENDAR}/${id}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export const postCalendarEventSubscribeByID = async ({ id, lang }) => {
  const url = `${API_LIST.CALENDAR}/${id}/subscribe?lang=${lang}`;

  try {
    const { data } = await post(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export const postCalendarEventUnsubscribeByID = async ({ id, lang }) => {
  const url = `${API_LIST.CALENDAR}/${id}/unsubscribe?lang=${lang}`;

  try {
    const { data } = await post(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};
