import { post } from '../../api';

class CoursesRepository {
  enableCourseTrial = async (dto) => {
    try {
      const { data } = await post(`courses/${dto.id}/trials`, {
        priceCourseId: dto.priceCourseId,
      });
      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  catchError = (err) => {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  };
}

export const coursesRepository = new CoursesRepository();
