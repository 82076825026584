import * as yup from 'yup';

const managementChannelFormSchema = yup.object({
  title: yup.string().required('errors.required-field'),
  description: yup.string().required('errors.required-field'),
  discordChannelId: yup.string().matches(/.{19,}/, {
    excludeEmptyString: true,
    message: 'errors.small-discord',
  }),
});

export default managementChannelFormSchema;
