import cn from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import './styles.scss';

const CancelSubscriptionHeader = ({ title, titleClassName }) => {
  const dispatch = useDispatch();

  const rootClassName = cn(`cancel-subscription-header__title`, titleClassName);

  const handleCloseButtonClicked = () => {
    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  return (
    <div className="cancel-subscription-header">
      <p className={rootClassName}>{title}</p>
      <Button
        onClick={handleCloseButtonClicked}
        leftIcon={<SvgIcon type="cross-2" />}
        size={BUTTON_SIZES.ICON}
        className="fit-content"
      />
    </div>
  );
};

CancelSubscriptionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  titleClassName: PropTypes.string,
};

CancelSubscriptionHeader.defaultProps = {
  titleClassName: '',
};

export default CancelSubscriptionHeader;
