import React, { useMemo, useRef } from 'react';
import { useReactMediaRecorder } from 'react-media-recorder';

import { RecorderContext } from './recorder-context';

export const RecorderProvider = (props) => {
  const {
    children,
    onStop,
    onStart,
    blobPropertyBag,
    screen,
    mediaRecorderOptions,
    customMediaStream,
    stopStreamsOnStop,
    askPermissionOnMount,
  } = props;

  const withSaving = useRef(true);

  const innerOnStop = (url, blob) => {
    onStop(url, blob, withSaving.current);
  };

  const { startRecording, stopRecording, mediaBlobUrl, status, previewStream, clearBlobUrl } =
    useReactMediaRecorder({
      audio: true,
      video: true,
      onStop: innerOnStop,
      onStart,
      blobPropertyBag,
      screen,
      mediaRecorderOptions,
      customMediaStream,
      stopStreamsOnStop,
      askPermissionOnMount,
    });

  const innerStopRecording = (save = true) => {
    withSaving.current = save;
    stopRecording();
  };

  const value = useMemo(
    () => ({
      startRecording,
      stopRecording: innerStopRecording,
      mediaBlobUrl,
      status,
      previewStream,
      clearBlobUrl,
      isRecording: status === 'recording',
    }),
    [startRecording, innerStopRecording, mediaBlobUrl, status, previewStream, clearBlobUrl],
  );

  return <RecorderContext.Provider value={value}>{children}</RecorderContext.Provider>;
};
