import PropTypes from 'prop-types';

import '../styles.scss';

const Email = ({ label, name, register, placeholder, error, disabled }) => {
  return (
    <div className="field-container">
      <label htmlFor={name} className="field__label">
        {label}
      </label>
      {error && <span className="field__error">{error}</span>}
      <input
        id={name}
        className={`field${disabled ? '--disabled' : ''}`}
        type="email"
        disabled={disabled}
        autoComplete="off"
        {...register(name)}
        placeholder={placeholder}
      />
    </div>
  );
};

Email.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

Email.defaultProps = {
  placeholder: '',
  error: '',
  disabled: false,
};

export default Email;
