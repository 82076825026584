import React from 'react';

import SpinnerCircle from '../../spinner-circle';

import './styles.scss';

export const SpinnerLoader = () => {
  return (
    <div className="spinner-loader">
      <SpinnerCircle />
    </div>
  );
};
