const BUTTON_TYPES = {
  SUBMIT: 'submit',
  BUTTON: 'button',
};

export const BUTTON_SIZES = {
  LG: 'lg',
  MD: 'md',
  SM: 'sm',
  XS: 'xs',
  ICON: 'icon',
};

export const BUTTON_VARIANTS = {
  OUTLINE: 'outline',
  FILLED: 'filled',
};
export default BUTTON_TYPES;
