import cl from 'classnames';
import { useClassName } from 'hooks/use-class-name';
import { useOnClickOutside } from 'hooks/use-on-click-outside';
import React from 'react';

import { Portal } from '../portal';
import SvgIcon from '../svg-icon';

import './styles.scss';

const getModalSize = (size = 'md') => {
  if (!size) return '0 0 27.8rem';
  switch (size) {
    case 'sm':
      return '0 0 23.75rem';
    case 'md':
      return '0 0 27.5rem';
    case 'lg':
      return '0 0 40.75rem';
    case 'xl':
      return '0 0 48.75rem';
    case 'xll':
      return '0 0 58.75rem';
    case 'auto':
      return '0 0 auto';
    default:
      if (typeof size === 'number') {
        return `0 0 ${size}px`;
      }
      return `0 0 ${size}`;
  }
};

export const Modal = (props) => {
  const {
    className,
    classNameWrapper,
    title,
    children,
    id,
    onClose,
    isOpen = false,
    withHeader = true,
    centered = false,
    variant = 'primary',
    size = 'md',
    isNeedToUnmount = false,
    ...restProps
  } = props;

  const ref = useOnClickOutside(() => onClose?.());
  const { rootClassName, appendClass } = useClassName('modal');
  const wrapperId = id ?? `modal-${new Date().getUTCDate()}`;

  const rootClasses = cl(
    rootClassName,
    appendClass(`--${variant}`),
    {
      [appendClass('--open')]: isOpen,
      [appendClass('--hidden')]: !isOpen,
      [appendClass('--centered')]: centered,
    },
    className,
  );
  const wrapperClasses = cl(appendClass('-wrapper'), classNameWrapper);

  const wrapperStyles = {
    flex: getModalSize(size),
  };

  if (!isOpen && isNeedToUnmount) return null;

  return (
    <Portal wrapperId={wrapperId}>
      <div ref={ref} className={rootClasses} {...restProps}>
        <div className={appendClass('-overlay')} onClick={onClose} />
        <div className={appendClass('-inner')}>
          <section
            className={wrapperClasses}
            role="dialog"
            style={wrapperStyles}
            aria-modal={true}
            tabIndex={-1}
          >
            {withHeader && (
              <div className={appendClass('-header')}>
                {title}
                <button className={appendClass('-close-btn')} onClick={onClose}>
                  <SvgIcon type="cross-2" />
                </button>
              </div>
            )}
            <div className={appendClass('-body')}>{children}</div>
          </section>
        </div>
      </div>
    </Portal>
  );
};
