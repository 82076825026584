import Checkbox from './checkbox';
import Email from './email';
import ExpandedTextArea from './expanded-text-area';
import File from './file';
import IosToggle from './ios-toggle';
import Number from './number';
import Password from './password';
import Search from './search';
import Text from './text';
import TextArea from './text-area';

const Input = {
  Text,
  Password,
  Email,
  Number,
  Checkbox,
  TextArea,
  File,
  IosToggle,
  Search,
  ExpandedTextArea,
};

export default Input;
