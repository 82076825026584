import deleteIcon from 'assets/images/group-chatting/delete-icon.png';
import colors from 'assets/styles/variables.scss';
import { selectActiveCommunityCategory } from 'pages/community/group-chatting/group-chatting.selectors';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  closeCommunityCategory,
  deleteChannelMessage,
  updateCategoryChannel,
} from 'store/actions/community.action';

import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/ui-core/button/button.constants';

import { COMMUNICATION_ITEM_TYPE } from '../communication-items.constants';

import adaptPayloadForSocketOnDeleteChannel from './delete-communication-item.utils';

import './styles.scss';

const DeleteCommunicationItem = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { additionalData } = useSelector(modalSelector);
  const { communicationItemType, itemData } = additionalData;
  const { categoryId } = useSelector(selectActiveCommunityCategory);

  const handleDeleteButtonClick = () => {
    switch (communicationItemType) {
      case COMMUNICATION_ITEM_TYPE.MESSAGE:
        dispatch(deleteChannelMessage(itemData.id));
        break;

      case COMMUNICATION_ITEM_TYPE.CHANNEL:
        dispatch(updateCategoryChannel(adaptPayloadForSocketOnDeleteChannel(itemData, categoryId)));
        break;

      case COMMUNICATION_ITEM_TYPE.CATEGORY:
        dispatch(closeCommunityCategory(categoryId));

        break;

      default:
        break;
    }

    dispatch(setModal({ isModalVisible: false }));
  };

  return (
    <div className="delete-communication-item">
      <img src={deleteIcon} className="delete-communication-item__icon" alt="delete" />

      <p className="delete-communication-item__title">
        {t(`modals.delete-communication-item.${communicationItemType}.title`)}
      </p>
      <p className="delete-communication-item__description">
        {t(`modals.delete-communication-item.${communicationItemType}.content-1`)}
        <span className="delete-communication-item__description__channel-name">
          &nbsp;{itemData.title}?&nbsp;
        </span>

        {t(`modals.delete-communication-item.${communicationItemType}.content-2`)}
      </p>
      <div className="delete-communication-item__controls">
        <Button
          content={t('shared-content.cancel')}
          onClick={() => {
            dispatch(setModal({ isModalVisible: false }));
          }}
          size={BUTTON_SIZES.SM}
          variant={BUTTON_VARIANTS.OUTLINE}
          contentColor={colors.white}
          backgroundColor={colors['neutral-gray-600']}
        />
        <Button
          content={t('shared-content.delete')}
          onClick={() => handleDeleteButtonClick()}
          size={BUTTON_SIZES.SM}
          contentColor={colors.white}
          backgroundColor={colors['system-red-500']}
        />
      </div>
    </div>
  );
};

export default DeleteCommunicationItem;
