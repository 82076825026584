const commonCollection = {
  receive: {
    receiveUnreadStatus: 'receive-unread-status',
    error: 'error',
  },
};

const privateChatCollection = {
  receive: {
    privateChats: 'receive-chats:private-chat',
    privateChatConnect: 'connect:private-chat',
    privateChatMessage: 'receive-message:private-chat',
    privateChatMessages: 'get-messages:private-chat',
    privateChatEditMessage: 'edited-message:private-chat',
    privateChatEmoji: 'receive-emoji:private-chat',
    privateChatUserOffline: 'user-offline:private-chat',
    privateChatUserOnline: 'user-online:private-chat',
    privateMessageViewedCount: 'update-viewed-count:private-chat',
    privateChatCreate: 'created-chat:private-chat',
    privateChatUnread: 'unread:private-chat',
    privateChatDisconnect: 'disconnected:private-chat',
  },
  send: {
    privateChatConnect: 'private-chat:connect',
    privateChatByIdConnect: 'private-chat:connect:private-chat',
    privateChatCreate: 'private-chat:create-chat:private-chat',
    privateChatEmojiToggle: 'private-chat:emoji-toggle:private-chats',
    privateChatSendMessage: 'private-chat:send-message:private-chat',
    privateChatMessagesGet: 'private-chat:get-messages:private-chat',
    privateChatMessageEdit: 'private-chat:edit-message:private-chat',
    privateChatMessageView: 'private-chat:view-messages:private-chat',
    privateChatDisconnect: 'private-chat:disconnect:private-chat',
  },
};

const communityCollection = {
  receive: {
    communityConnect: 'connect',
    communityConnectCategory: 'connect:category',
    communityConnectCommunication: 'connect:communication',
    communityMessage: 'receive-message:communication',
    communityMessages: 'get-messages:communication',
    communityEmoji: 'receive-emoji:communication',
    communityUnread: 'unread:communication',
    communityViewAll: 'viewed-all-messages:communication',
    communityCreatedCommunication: 'created-communication-item:communication',
    communityUpdatedCommunication: 'updated-communication-item:communication',
    communityEditedMessage: 'edited-message:communication',
    communityTogglePinCategory: 'pin-toggle:category',
    communityTogglePinMessage: 'pined-toggle-message:communication',
    communityPinnedMessages: 'get-pinned-message:communication',
    communityRemovedCategory: 'deleted:category',
    communityUpdatedCategory: 'server:updated-category',
    communityFoundUsersInCommunication: 'search-user-in-communication-item:communication',
    communityMessagesByMessageId: 'receive-messages-by-message-id:communication',
  },
  send: {
    communityConnect: 'community:connect',
    communityCategoryConnect: 'community:connect:category',
    communityCommunicationConnect: 'community:connect:communication',
    communityMessageSend: 'community:send-message:communication',
    communityEmojiToggle: 'community:emoji-toggle:communication',
    communityAllMessageView: 'community:view-all-messages:communication',
    communityMessageEdit: 'community:edit-message:communication',
    communityMessagesGet: 'community:get-messages:communication',
    communityMessageView: 'community:view-messages:communication',
    communityCommunicationCreate: 'community:create-communication-item:communication',
    communityCommunicationUpdate: 'community:update-communication-item:communication',
    communityMessageRemove: 'community:delete-message:communication',
    communityMessageTogglePin: 'community:pin-toggle-message:communication',
    communityCategoryTogglePin: 'community:pin-toggle:category',
    communityPinnedMessagesGet: 'community:get-pinned-messages:communication',
    communityCategoryUpdatePosition: 'community:change-position-categories',
    communityCommunicationUpdatePosition:
      'community:change-position-communication-items:communication',
    communityCategoryRemove: 'community:delete:category',
    communityCategoryUpdate: 'community:update-category',
    communityCommunicationUsersSearch: 'community:search-user-in-communication-item:communication',
    communityGetMessageById: 'community:get-messages-by-message-id:communication',
  },
};

const notificationCollection = {
  receive: {
    unreadNotifications: 'receive-unread-notifications',
    newNotification: 'new-notification:notification',
  },
};

export const socketActionCollection = {
  common: commonCollection,
  privateChat: privateChatCollection,
  community: communityCollection,
  notification: notificationCollection,
};
