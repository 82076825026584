import { useDispatch, useSelector } from 'react-redux';

import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';
import ZoomImage from 'components/zoom-image';

import generateTargetURL from 'utils/attachments';

import './styles.scss';

const FullSizeImageWithZoom = () => {
  const dispatch = useDispatch();

  const {
    additionalData: { attachmentUrl },
  } = useSelector(modalSelector);

  return (
    <div className="full-size-image-with-zoom">
      <div className="full-size-image-with-zoom__close-btn">
        <Button
          onClick={() => {
            dispatch(
              setModal({
                isModalVisible: false,
              }),
            );
          }}
          leftIcon={<SvgIcon type="cross-2" />}
          size={BUTTON_SIZES.ICON}
        />
      </div>

      <div className="full-size-image-with-zoom__container">
        <ZoomImage image={generateTargetURL(attachmentUrl)} />
      </div>
    </div>
  );
};

export default FullSizeImageWithZoom;
