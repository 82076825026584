import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import animationJSON from './animation.json';
import loaderSelector from './loader.selectors';

import './styles.scss';

const Loader = ({ isSuspense }) => {
  const lottieRef = useRef();
  const [isAnimationCompleted, setIsAnimationCompleted] = useState(false);

  const { isVisible } = useSelector(loaderSelector);

  useEffect(() => {
    // needs for smooth transition between page and for good animation when request was successful
    if (!isVisible && lottieRef && isAnimationCompleted) {
      lottieRef.current.stop();
    }

    if ((isVisible || isSuspense || !isAnimationCompleted) && lottieRef) {
      setIsAnimationCompleted(false);
      lottieRef.current.play();
    }
  }, [isVisible, isAnimationCompleted]);

  const handleEnterFrameCallback = (e) => {
    const targetFrame = 40;
    const currentFrame = Math.floor(e.currentTime);

    if (currentFrame === targetFrame && !isVisible) {
      setIsAnimationCompleted(true);
    }
  };

  const onLoopCompleteCallback = () => {
    setIsAnimationCompleted(true);
  };

  return (
    <div className={`loader${isVisible || isSuspense || !isAnimationCompleted ? '' : '--hidden'}`}>
      <Lottie
        className="loader__animation"
        animationData={animationJSON}
        loop
        lottieRef={lottieRef}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        onEnterFrame={handleEnterFrameCallback}
        onLoopComplete={onLoopCompleteCallback}
      />
    </div>
  );
};

Loader.propTypes = {
  isSuspense: PropTypes.bool,
};

Loader.defaultProps = {
  isSuspense: false,
};

export default Loader;
