import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import DAY_JS_ADAPTED_LOCALE_CONFIG from 'components/language-switcher/language-switcher.constants';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import PropTypes from 'prop-types';
import settingsSelector from 'store/settings/settings.selectors';

import i18n from './localization.config';

const UA_MONTHS_KEYS_FOR_TRANSLATIONS = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];
const LocalizationProvider = ({ children }) => {
  const { t } = useTranslation();

  const { language } = useSelector(settingsSelector);

  const updateUkrainianShortMonthNames = () => {
    dayjs.extend(updateLocale);
    dayjs.updateLocale('uk', {
      monthsShort: UA_MONTHS_KEYS_FOR_TRANSLATIONS.map((translationKey) => {
        return t(`months-for-dayjs.${translationKey}`);
      }),
    });
  };

  useEffect(() => {
    dayjs.locale(DAY_JS_ADAPTED_LOCALE_CONFIG[i18n.language.toUpperCase()]);

    updateUkrainianShortMonthNames();
  }, [language]);

  return children;
};

LocalizationProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LocalizationProvider;
