import { yupResolver } from '@hookform/resolvers/yup';
import colors from 'assets/styles/variables.scss';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import MODAL_TYPES from 'components/modal-container/modal-container.constants';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import BUTTON_TYPES, { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import Input from 'components/ui-core/inputs';

import { unsubscribe } from '../../../../../store/actions';
import modalSelector from '../../../modal-container.selectors';
import CancelSubscriptionHeader from '../cancel-subscription-header';

import cancelSubscriptionStep2Schema from './cancel-subscription-step-2.schema';

import './styles.scss';

const REASONS = [
  `There isn't enough information`,
  `I will replace NAVI with another source of information`,
  `I don't have time to read NAVI`,
  `NAVI is too expensive for me`,
  `Other`,
];

const CancelSubscriptionStep2 = () => {
  const dispatch = useDispatch();
  const [isOwnReasonFieldVisible, setIsOwnReasonFieldVisible] = useState(false);

  const { additionalData } = useSelector(modalSelector);

  const {
    formState: { errors },
    watch,
    handleSubmit,
    register,
  } = useForm({
    resolver: yupResolver(cancelSubscriptionStep2Schema),
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === `selectedFields.${REASONS.length - 1}`) {
        if (value.selectedFields[value.selectedFields.length - 1]) {
          setIsOwnReasonFieldVisible(true);
        } else {
          setIsOwnReasonFieldVisible(false);
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = () => {
    dispatch(unsubscribe(additionalData.subscriptionId));
    dispatch(
      setModal({
        modalType: MODAL_TYPES['CANCEL-SUBSCRIPTION-STEP-3'],
        isModalVisible: true,
        additionalData,
      }),
    );
  };

  return (
    <div className="cancel-subscription-step-2">
      <CancelSubscriptionHeader title="Cancel subscription" />

      <p className="cancel-subscription-step-2__primary-content">
        Why did you cancel your subscription?
      </p>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="cancel-subscription-step-2__reasons">
          {REASONS.map((reason, reasonIndex) => {
            return (
              <Input.Checkbox
                name={`selectedFields.${reasonIndex}`}
                register={register}
                label={reason}
                className="cancel-subscription-step-2__checkbox"
                key={reason}
                error={errors?.selectedFields?.message}
              />
            );
          })}
        </div>
        {isOwnReasonFieldVisible && (
          <Input.TextArea name="ownReason" register={register} error={errors?.ownReason?.message} />
        )}
        <Button
          content="Finish cancellation"
          className="cancel-subscription-step-2__button"
          contentColor={colors.white}
          backgroundColor={colors['linear-2']}
          size={BUTTON_SIZES.MD}
          type={BUTTON_TYPES.SUBMIT}
          disabled={Boolean(Object.keys(errors).length)}
        />
      </form>
    </div>
  );
};

export default CancelSubscriptionStep2;
