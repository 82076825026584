import HtmlReactParser from 'html-react-parser';
import { ReactTinyLink } from 'react-tiny-link';

export const removeHtmlElementsFromString = (str) => {
  const div = document.createElement('div');
  div.innerHTML = str;

  const text = div.textContent;
  div.remove();

  return text;
};

export const asHtml = (html, options) => {
  if (typeof html === 'string') {
    return HtmlReactParser(html, options);
  }

  return null;
};

export const parseAnyLinksFromMessage = (message) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const userTagPattern = /<@(\d+)>/g;

  const parsedMessageWithUserTags = message ? message.replace(userTagPattern, '').trim() : '';

  const parsedMessageWithLinkTags = parsedMessageWithUserTags.replace(
    urlRegex,
    (match) => `<a href="${match}" class="link--blue-filled" target="_blank">${match}</a>`,
  );
  return asHtml(parsedMessageWithLinkTags);
};

export const extractLastLinkFromMessage = (message) => {
  const linkRegex = /(?:^|\s)(https?:\/\/[^\s]+)(?=$|\s)/g;
  const matches = message ? message.match(linkRegex) : '';

  if (matches && matches.length > 0) {
    return (
      <ReactTinyLink
        cardSize="small"
        showGraphic
        maxLine={1}
        minLine={1}
        url={matches[matches.length - 1].trim()}
        proxyUrl={process.env.REACT_APP_PROXY_URL}
      />
    );
  }

  return null;
};

const parallaxEffect = (event) => {
  document.querySelectorAll('[data-parallax-value]').forEach((parallaxItem) => {
    const y = event.clientY / window.innerHeight;

    parallaxItem.style.transform = `translate(0px, ${
      y * parallaxItem.getAttribute('data-parallax-value')
    }px)`;
  });
};

const createFormData = (body) => {
  const formData = new FormData();
  const entries = Object.entries(body);

  entries.forEach(([key, value]) => {
    if (value instanceof File) {
      formData.append(key, value);
    } else if (value instanceof Object || value instanceof Array) {
      formData.append(key, JSON.stringify(value));
    } else {
      formData.append(key, value);
    }
  });

  return formData;
};

const adaptOTPCodeForAPI = (otp) => {
  return Number(otp.split('-').join(''));
};

const adaptPhoneNumberForAPI = (phoneNumber) => {
  return phoneNumber.replace(/\s/g, '');
};

const isSubmitButtonDisabled = (isOTPFieldVisible, OTPValue) => {
  return isOTPFieldVisible && OTPValue.length !== 9;
};

const sendMessageThroughSocket = (data, socketInstance) => {
  socketInstance.send(JSON.stringify(data));
};

let timer;

const debounce = (callback, timeout = 300) => {
  clearTimeout(timer);

  timer = setTimeout(() => {
    callback();
  }, timeout);
};

const highlightAccountFields = (ref, searchParams, type) => {
  if (searchParams.has('isNeedToHighlight')) {
    const queryArr = searchParams.get('isNeedToHighlight').split(', ');
    const queryValue = queryArr.find((item) => item === type);

    if (queryValue === type) {
      ref.current.classList.add('attention');
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }
};

export {
  parallaxEffect,
  createFormData,
  adaptOTPCodeForAPI,
  isSubmitButtonDisabled,
  adaptPhoneNumberForAPI,
  sendMessageThroughSocket,
  debounce,
  highlightAccountFields,
};
