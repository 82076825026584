import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import './styles.scss';

const MAX_FILE_SIZE_IN_BYTES = 2000000;

const ImageUploader = ({ handleFileSelected, buttonContent }) => {
  const { t } = useTranslation();

  const handleFileChange = async (targetFile) => {
    if (MAX_FILE_SIZE_IN_BYTES >= targetFile.files[0].size) {
      handleFileSelected(targetFile.files[0]);
    } else {
      toast.error(t('errors.big-file'));
    }
  };

  return (
    <label htmlFor="image-uploader" className="image-uploader">
      {buttonContent}
      <input
        id="image-uploader"
        type="file"
        accept="image/png, image/jpeg, image/jpg, image/gif"
        onChange={({ target }) => handleFileChange(target)}
      />
    </label>
  );
};

ImageUploader.propTypes = {
  handleFileSelected: PropTypes.func.isRequired,
  buttonContent: PropTypes.string.isRequired,
};

export default ImageUploader;
