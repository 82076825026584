/* eslint-disable consistent-return */
import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import { getCourseViewBlocksRequest } from 'services/requests/blocks';
import { createLessonCommentRequest, getLessonCommentsRequest } from 'services/requests/course';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getCourseViewBlocksOperation = createAsyncThunk(
  'course-view/GET_COURSE_VIEW_BLOCKS',
  async (requestData, { rejectWithValue }) => {
    try {
      const courseBlocks = await getCourseViewBlocksRequest(requestData);

      return courseBlocks.block;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getLessonCommentsOperation = createAsyncThunk(
  'course-view/GET_LESSON_COMMENTS',
  async (requestData, { rejectWithValue }) => {
    try {
      const { lessonComments } = await getLessonCommentsRequest(requestData);

      return lessonComments;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const createLessonCommentOperation = createAsyncThunk(
  'course-view/CREATE_LESSONS_COMMENT',
  async (requestData, { rejectWithValue }) => {
    try {
      const commentData = await createLessonCommentRequest(requestData);

      return commentData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getCourseViewPageOperation = createAsyncThunk(
  'course-view/GET_COURSE_VIEW_PAGE',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(getCourseViewBlocksOperation(requestData));

    await dispatch(setLoaderVisibility(false));
  },
);

export {
  createLessonCommentOperation,
  getCourseViewBlocksOperation,
  getCourseViewPageOperation,
  getLessonCommentsOperation,
};
