import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import colors from 'assets/styles/variables.scss';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createPaymentMethod, createSubscription, getPaymentMethods } from 'store/actions';

import { For } from '../../../../ui-core';
import Button from '../../../../ui-core/button';
import { BUTTON_SIZES } from '../../../../ui-core/button/button.constants';
import MODAL_TYPES from '../../../modal-container.constants';
import { setModal } from '../../../modal-container.slice';
import { PAYMENT_VARIANT } from '../_constants';

import { FormVariant } from './form-variant';
import { SavedCardVariant } from './saved-card-variant';

import './styles.scss';

const RADIO_NAME = 'payment-variant';

export const CheckoutForm = (props) => {
  const {
    clientSecret,
    toggleIsProcessing,
    subscriptionName,
    price,
    isSubscription,
    isTrial,
    ...restProps
  } = props;
  const dispatch = useDispatch();

  const stripe = useStripe();
  const elements = useElements();

  const [savedCards, setSavedCards] = useState([]);

  const [currentVariant, setCurrentVariant] = useState({
    id: PAYMENT_VARIANT.NEW_CARD,
    type: PAYMENT_VARIANT.NEW_CARD,
  });

  const defaultAdditionalData = {
    subscriptionName,
    price,
    ...restProps,
  };

  const makeSubscription = async () => {
    const cardElement = elements?.getElement(CardNumberElement);
    const result = await stripe.createToken(cardElement);

    if ('error' in result) {
      dispatch(
        setModal({
          isModalVisible: true,
          modalType: MODAL_TYPES.PAYMENT_RESULT,
          additionalData: {
            ...defaultAdditionalData,
            resultType: 'failure',
          },
        }),
      );
      throw new Error('Payment error');
    } else {
      try {
        await dispatch(createPaymentMethod(result.token.id));
        await dispatch(createSubscription({ id: restProps.id, isTrial })).unwrap();
      } catch (e) {
        dispatch(
          setModal({
            isModalVisible: true,
            modalType: MODAL_TYPES.PAYMENT_RESULT,
            additionalData: {
              ...defaultAdditionalData,
              resultType: 'failure',
            },
          }),
        );
        throw e;
      }
    }

    return result;
  };

  const makeOneTimePayment = async () => {
    const cardElement = elements?.getElement(CardNumberElement);
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
      },
    });

    if ('error' in result) {
      dispatch(
        setModal({
          isModalVisible: true,
          modalType: MODAL_TYPES.PAYMENT_RESULT,
          additionalData: {
            ...defaultAdditionalData,
            resultType: 'failure',
          },
        }),
      );
      throw new Error('Payment error');
    }
  };

  const handlePaymentBySavedCard = async () => {
    try {
      await dispatch(
        createSubscription({
          id: restProps.id,
          isTrial,
          paymentMethodId: currentVariant.id,
        }),
      ).unwrap();
    } catch (e) {
      dispatch(
        setModal({
          isModalVisible: true,
          modalType: MODAL_TYPES.PAYMENT_RESULT,
          additionalData: {
            ...defaultAdditionalData,
            resultType: 'failure',
          },
        }),
      );
      throw e;
    }
  };

  const showSuccessModal = () => {
    dispatch(
      setModal({
        isModalVisible: true,
        modalType: MODAL_TYPES.PAYMENT_RESULT,
        additionalData: {
          ...defaultAdditionalData,
          resultType: 'success',
          isSubscription,
        },
      }),
    );
  };

  const handleSubmit = async (event) => {
    toggleIsProcessing(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (currentVariant.type === PAYMENT_VARIANT.SAVED_CARD) {
      try {
        await handlePaymentBySavedCard();
        showSuccessModal();
      } finally {
        toggleIsProcessing(false);
      }
    } else {
      try {
        if (isSubscription || isTrial) {
          await makeSubscription();
        } else {
          await makeOneTimePayment();
        }
        showSuccessModal();
      } finally {
        toggleIsProcessing(false);
      }
    }
  };

  const handleChangeRadio = (variant) => {
    setCurrentVariant(() => variant);
  };

  const renderSavedCards = (card) => {
    const isChecked = currentVariant.id === card.id;
    return (
      <SavedCardVariant
        key={card.id}
        name={RADIO_NAME}
        onChange={handleChangeRadio}
        checked={isChecked}
        {...card}
      />
    );
  };

  useEffect(() => {
    if (isSubscription) {
      dispatch(getPaymentMethods())
        .unwrap()
        .then((data) => {
          setSavedCards(() => data);
        });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit} className="stripe-modal-checkout-form">
      <div className="stripe-modal-checkout-form-elements-wrapper">
        <FormVariant
          name={RADIO_NAME}
          onChange={handleChangeRadio}
          checked={currentVariant.id === PAYMENT_VARIANT.NEW_CARD}
          isSubscription={isSubscription}
        />
        {isSubscription && <For data={savedCards} render={renderSavedCards} />}
      </div>
      <div className="stripe-modal-checkout-form-btn-wrapper">
        <Button
          disabled={!stripe}
          content="Proceed"
          type="submit"
          size={BUTTON_SIZES.MD}
          contentColor={colors.white}
          backgroundColor={colors['linear-3']}
        />
      </div>
    </form>
  );
};
