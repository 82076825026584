import { get, post } from 'services/api';

const getLessonCommentsRequest = async (requestData = {}) => {
  const url = `/courses/${requestData.courseID}/lessons/${requestData.lessonID}/comments`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const createLessonCommentRequest = async (requestData = {}) => {
  const url = `/courses/${requestData.courseID}/lessons/${requestData.lessonID}/comments`;

  try {
    const { data } = await post(url, { comment: requestData.comment });

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const markTimeCodeAsWatchedRequest = async (requestData = {}) => {
  const url = `/group/${requestData.courseID}/video-timecode/${requestData.timeCodeID}/mark`;

  const requestBody = {
    isWatched: true,
  };

  try {
    const { data } = await post(url, requestBody);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export { createLessonCommentRequest, getLessonCommentsRequest, markTimeCodeAsWatchedRequest };
