const USER_PERMISSIONS = {
  userCanCreateCategory: 'userCanCreateCategory',
  userCanUpdateCategory: 'userCanUpdateCategory',
  userCanDeleteCategory: 'userCanDeleteCategory',
  userCanCreateCommunicationItem: 'userCanCreateCommunicationItem',
  userCanUpdateCommunicationItem: 'userCanUpdateCommunicationItem',
  userCanDeleteCommunicationItem: 'userCanDeleteCommunicationItem',
  userCanUpdateMessage: 'userCanUpdateMessage',
  userCanDeleteMessage: 'userCanDeleteMessage',
  userCanModerateChat: 'userCanModerateChat',
};

export const USER_PERMISSIONS_KEY = {
  communityCategoryCreate: 'communityCategory.create',
  communityCategoryRead: 'communityCategory.read',
  communityCategoryUpdate: 'communityCategory.update',
  communityCategoryDelete: 'communityCategory.delete',

  communicationItemCreate: 'communityCommunicationItem.create',
  communicationItemRead: 'communityCommunicationItem.read',
  communicationItemUpdate: 'communityCommunicationItem.update',
  communicationItemDelete: 'communityCommunicationItem.delete',

  communityMessageCreate: 'communityMessage.create',
  communityMessageRead: 'communityMessage.read',
  communityMessageUpdate: 'communityMessage.update',
  communityMessageDelete: 'communityMessage.delete',

  communityMessageMention: 'communityMessageMention.create',
};

export default USER_PERMISSIONS;
