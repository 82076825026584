import getStreamByIdOperation from './stream.thunk';
import { getNormalizeStreamBanner, getNormalizeStreamInfo } from './stream.utils';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  info: {
    streamKey: null,
    videoID: null,
    title: '',
    description: '',
    redirectUrl: '',
    status: '',
  },
  banner: {
    bannerID: null,
    bannerUrl: '',
    title: '',
    description: '',
    buttonText: '',
    buttonLink: '',
  },
  permissions: {
    userCanModerateChat: false,
  },
  usersOnline: 0,
  error: null,
};

const coursesSlice = createSlice({
  name: 'stream',
  initialState,
  reducers: {
    setUsersOnlineInformation(state, { payload }) {
      state.usersOnline = payload.usersOnline;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getStreamByIdOperation.fulfilled, (state, { payload }) => {
        state.info = getNormalizeStreamInfo(payload);
        state.banner = getNormalizeStreamBanner(payload);
        state.permissions.userCanModerateChat = payload.stream.userCanModerateChat;
      })
      .addCase(getStreamByIdOperation.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export const { setUsersOnlineInformation } = coursesSlice.actions;

export default coursesSlice.reducer;
