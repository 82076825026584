import PropTypes from 'prop-types';

import './styles.scss';

const AttachmentsList = ({ attachmentsLength, children }) => {
  if (!attachmentsLength) {
    return null;
  }

  return <div className="attachments-list">{children}</div>;
};

AttachmentsList.propTypes = {
  attachmentsLength: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default AttachmentsList;
