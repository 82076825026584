import React from 'react';

import { useClassName } from '../../../../../hooks/use-class-name';

import { getMenuItems } from './get-menu-items';
import { LinkAction } from './link-action';
import { MenuItem } from './menu-item/menu-item';

import './styles.scss';

export const MenuBar = (props) => {
  const { editor } = props;

  const { rootClassName, appendClass } = useClassName('rich-text-editor-menu-bar', 'atom');

  const mapItems = getMenuItems(editor).map((item) => {
    if ('type' in item && item.type === 'divider') {
      return <span className={appendClass('-divider')} />;
    }

    return <MenuItem icon={item.icon} isActive={item.isActive} action={item.action} />;
  });

  return (
    <div className={rootClassName}>
      <div className={appendClass('-container')}>
        {mapItems}
        <LinkAction editor={editor} />
      </div>
    </div>
  );
};
