import { FloatingPortal } from '@floating-ui/react';
import cl from 'classnames';
import React, { useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { useBaseFloating } from '../../../../../../hooks/use-base-floating';
import { useClassName } from '../../../../../../hooks/use-class-name';
import { MenuItem } from '../menu-item/menu-item';

import { LinkForm } from './link-form';

import './styles.scss';

export const LinkAction = (props) => {
  const { editor } = props;

  const { isOpen, toggleOpen, getReferenceProps, getFloatingProps, refs, floatingStyles } =
    useBaseFloating({
      placement: 'right',
    });

  const transitionRef = useRef(null);

  const { appendClass } = useClassName('rich-text-editor-link-action', 'atom');

  const isActive = () => editor.isActive('link');

  const handleClose = useCallback(() => toggleOpen(false), [toggleOpen]);

  return (
    <>
      <MenuItem ref={refs.setReference} icon="link" isActive={isActive} {...getReferenceProps()} />

      <CSSTransition
        nodeRef={transitionRef}
        in={isOpen}
        timeout={300}
        classNames={appendClass('-animation')}
        unmountOnExit
        mountOnEnter
      >
        <FloatingPortal>
          <div
            ref={(node) => {
              transitionRef.current = node;
              refs.setFloating(node);
            }}
            style={floatingStyles}
            {...getFloatingProps({
              className: cl(appendClass('-float-container'), {
                [appendClass('-float-container--opened')]: isOpen,
              }),
            })}
          >
            <LinkForm onClose={handleClose} editor={editor} />
          </div>
        </FloatingPortal>
      </CSSTransition>
    </>
  );
};
