/* eslint-disable consistent-return */
import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import i18n from 'localization/localization.config';
import {
  getUserInfoRequest,
  sendOldAndNewUserPasswordsRequest,
  sendOTPToUserEmailRequest,
  sendOTPToUserPhoneRequest,
  updateUserPersonalInformationRequest,
  uploadUserAvatarRequest,
  verifyOTPAndAddUserEmailRequest,
  verifyOTPAndAddUserPhoneRequest,
  verifyOTPAndChangeUserPasswordRequest,
} from 'services/requests/my-account';

import { adaptAddUserEmailFormDataForAPISecondStep } from './components/add-email/components/add-email-form.utils';
import {
  adaptUserPhoneNumberFormDataForAPIFirstStep,
  adaptUserPhoneNumberFormDataForAPISecondStep,
} from './components/add-phone/components/add-phone-form.utils';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getUserInfoOperation = createAsyncThunk(
  'my-account/GET_USER_INFO',
  async (requestData, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfoRequest(requestData);

      return userInfo;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const updateUserPersonalInformationOperation = createAsyncThunk(
  'my-account/UPDATE_USER_PERSONAL_INFORMATION_OPERATION',
  async ({ isNeedToHideToast, ...otherRequestData }, { rejectWithValue }) => {
    try {
      await updateUserPersonalInformationRequest(otherRequestData);

      if (!isNeedToHideToast) {
        toast.success(i18n.t('my-account.personal-information.successfully-changed'));
      }

      return otherRequestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const uploadUserAvatarOperation = createAsyncThunk(
  'my-account/UPLOAD_USER_AVATAR_OPERATION',
  async (requestData, { rejectWithValue }) => {
    try {
      await uploadUserAvatarRequest(requestData);

      toast.success(i18n.t('my-account.personal-information.avatar-successfully-changed'));

      return requestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const sendOTPToUserEmailOperation = createAsyncThunk(
  'my-account/SEND_OTP_TO_USER_EMAIL_OPERATION',
  async (requestData, { rejectWithValue }) => {
    try {
      await sendOTPToUserEmailRequest(requestData);

      return requestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const verifyOTPAndAddUserEmailOperation = createAsyncThunk(
  'my-account/VERIFY_OTP_AND_ADD_USER_EMAIL_OPERATION',
  async (requestData, { rejectWithValue }) => {
    try {
      await verifyOTPAndAddUserEmailRequest(adaptAddUserEmailFormDataForAPISecondStep(requestData));

      toast.success(i18n.t('my-account.personal-information.successfully-changed'));

      return requestData.email;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const sendOTPToUserPhoneOperation = createAsyncThunk(
  'my-account/SEND_OTP_TO_USER_PHONE_OPERATION',
  async (requestData, { rejectWithValue }) => {
    try {
      await sendOTPToUserPhoneRequest(requestData);

      return requestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const verifyOTPAndAddUserPhoneOperation = createAsyncThunk(
  'my-account/VERIFY_OTP_AND_ADD_USER_PHONE_OPERATION',
  async (requestData, { rejectWithValue }) => {
    try {
      await verifyOTPAndAddUserPhoneRequest(
        adaptUserPhoneNumberFormDataForAPISecondStep(requestData),
      );

      toast.success(i18n.t('my-account.personal-information.successfully-changed'));

      return adaptUserPhoneNumberFormDataForAPIFirstStep(requestData);
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getMyAccountPageOperation = createAsyncThunk(
  'my-account/GET_MY_ACCOUNT_PAGE',
  async (requestData, { dispatch, getState }) => {
    const isGlobalLoaderVisible = getState().loader.isVisible;

    if (!isGlobalLoaderVisible) {
      dispatch(setLoaderVisibility(true));
    }
    await dispatch(getUserInfoOperation(requestData));

    await dispatch(setLoaderVisibility(false));
  },
);

const sendOldAndNewUserPasswordsOperation = createAsyncThunk(
  'my-account/SEND_OLD_AND_NEW_USER_PASSWORDS',
  async (requestData, { rejectWithValue }) => {
    try {
      await sendOldAndNewUserPasswordsRequest(requestData);

      return requestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const verifyOTPAndChangeUserPasswordOperation = createAsyncThunk(
  'my-account/VERIFY_OTP_AND_CHANGE_USER_PASSWORD',
  async (requestData, { rejectWithValue }) => {
    try {
      await verifyOTPAndChangeUserPasswordRequest(requestData);

      toast.success(i18n.t('my-account.personal-information.successfully-changed'));

      return requestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

export {
  getMyAccountPageOperation,
  getUserInfoOperation,
  sendOldAndNewUserPasswordsOperation,
  sendOTPToUserEmailOperation,
  sendOTPToUserPhoneOperation,
  updateUserPersonalInformationOperation,
  uploadUserAvatarOperation,
  verifyOTPAndAddUserEmailOperation,
  verifyOTPAndAddUserPhoneOperation,
  verifyOTPAndChangeUserPasswordOperation,
};
