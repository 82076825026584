import { useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import BUTTON_TYPES, { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import ImageUploader from 'components/ui-core/image-uploader';
import Input from 'components/ui-core/inputs';

import { selectActiveCommunityId } from '../../../../../../pages/community/group-chatting/group-chatting.selectors';
import { connectToCommunitySocket } from '../../../../../../store/actions';
import { getRemoteImageUrl } from '../../../../../../utils/get-remote-image-url';
import { isBlob } from '../../../../../../utils/is-blob';

import {
  adaptDataFromSocketForDefaultValues,
  generateFormDataAndStartUpdatingCategoryImage,
  generateFormDataAndStartUploadingCategoryImage,
} from './management-category-form.utils';
import managementCategoryFormSchema from './schemas';

import './styles.scss';
import colors from 'assets/styles/variables.scss';

const ManagementCategoryForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    additionalData: { itemData },
  } = useSelector(modalSelector);

  const [categoryImage, setCategoryImage] = useState(itemData?.imageUrl || null);
  const [isLoading, setIsLoading] = useState(false);

  const activeCommunityId = useSelector(selectActiveCommunityId);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(managementCategoryFormSchema),
    defaultValues: adaptDataFromSocketForDefaultValues(itemData),
  });

  const handleFileChange = async (targeFile) => {
    setCategoryImage(targeFile);
  };

  const currentMediaImage = useMemo(() => {
    if (categoryImage instanceof File) {
      return URL.createObjectURL(categoryImage);
    }

    if (isBlob(categoryImage)) {
      return categoryImage;
    }

    if (itemData && itemData.imageUrl) {
      return getRemoteImageUrl(itemData.imageUrl);
    }

    return null;
  }, [categoryImage, itemData]);

  const onSubmit = async ({ title, isPro = false }) => {
    if (!categoryImage) {
      return toast.error(t('errors.category-photo-required'));
    }

    setIsLoading(true);

    if (itemData?.id) {
      const data = {
        title,
        categoryImage,
        id: itemData.id,
        isPro,
      };

      try {
        await generateFormDataAndStartUpdatingCategoryImage(data);
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        await generateFormDataAndStartUploadingCategoryImage(title, categoryImage, isPro);
        dispatch(connectToCommunitySocket(activeCommunityId));
      } catch (error) {
        toast.error(error);
      }
    }

    setIsLoading(false);

    return dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="management-category-form">
      <div className="management-category-form__category-image">
        {categoryImage ? (
          <img
            className="management-category-form__category-image__image"
            src={currentMediaImage}
            alt="category"
          />
        ) : (
          <p className="management-category-form__category-image__empty">
            {t('shared-content.no-image')}
          </p>
        )}
        <div className="management-category-form__category-image__upload">
          <ImageUploader
            handleFileSelected={handleFileChange}
            buttonContent={t('shared-content.upload-category-photo')}
          />

          <p className="management-category-form__category-image__upload__description">
            {t('my-account.personal-information.avatar-description')}
          </p>
        </div>
      </div>
      <Input.Text
        name="title"
        register={register}
        label={t('inputs.create-category.label')}
        placeholder={t('inputs.create-category.placeholder')}
        error={t(errors.title?.message)}
      />

      <Button
        type={BUTTON_TYPES.SUBMIT}
        isLoading={isLoading}
        content={t('modals.create-communication-item.category.submit-button')}
        backgroundColor={colors['linear-2']}
        contentColor={colors.white}
        size={BUTTON_SIZES.SM}
        className="w-160px"
      />
    </form>
  );
};

export default ManagementCategoryForm;
