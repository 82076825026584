import store from 'store/store';

import { CHANNEL_STATUSES, COMMUNICATION_ITEM_TYPE } from '../../communication-items.constants';

const adaptDataFromSocketForDefaultValues = (channelData) => {
  if (!channelData) {
    return {};
  }

  const { type, ...otherFormData } = channelData;

  const isViewOnly = type === COMMUNICATION_ITEM_TYPE.CHANNEL.toUpperCase();

  return {
    ...otherFormData,
    type: isViewOnly,
  };
};

const adaptManagementChannelFormDataForSocket = (
  { description, title, discordChannelId, ...otherFormData },
  additionalChannelData,
) => {
  const { categoryId } = store.getState().groupChatting.activeCategory;

  const communicationType = otherFormData.type
    ? COMMUNICATION_ITEM_TYPE.CHANNEL.toUpperCase()
    : COMMUNICATION_ITEM_TYPE.CHAT.toUpperCase();

  const adaptedObject = {
    ...additionalChannelData,
    categoryId,
    title,
    status: CHANNEL_STATUSES.ACTIVE.toUpperCase(),
    type: communicationType,
  };

  if (discordChannelId) {
    adaptedObject.discordChannelId = discordChannelId.replace(/\s/g, '');
  }

  if (description) {
    adaptedObject.description = description;
  }

  return adaptedObject;
};

export { adaptDataFromSocketForDefaultValues, adaptManagementChannelFormDataForSocket };
