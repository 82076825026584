import { ManagementCategoryForm, ManagementChannelForm } from './forms';

const CHANNEL_SETTINGS_OPTIONS_LIST = {
  'GENERAL-INFO': <ManagementChannelForm />,
};

const CATEGORY_SETTINGS_OPTIONS_LIST = {
  'GENERAL-INFO': <ManagementCategoryForm />,
};

export { CHANNEL_SETTINGS_OPTIONS_LIST, CATEGORY_SETTINGS_OPTIONS_LIST };
