import { useContext } from 'react';

import { RecorderContext } from './recorder-context';

export const useRecorderContext = () => {
  const ctx = useContext(RecorderContext);

  if (!ctx) {
    throw new Error('RecordedContext must be inside a Provider with a value');
  }

  return ctx;
};
