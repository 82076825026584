import BLOCK_TYPES from './course-landing.constants';
import { getCourseLandingBlocksOperation } from './course-landing.thunks';
import extractDataForSpecificSection from './course-landing.utils';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  course: {},
  lessons: [],
  modules: [],
  tariffs: [],
  reviews: {
    items: [],
    rating: {},
  },
  events: [],
  courses: [],
  benefits: [],
  error: '',
};

const courseLandingSlice = createSlice({
  name: 'courseLanding',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCourseLandingBlocksOperation.fulfilled, (state, { payload }) => {
        state.course = extractDataForSpecificSection(payload, BLOCK_TYPES.COURSE);
        state.lessons = extractDataForSpecificSection(payload, BLOCK_TYPES.LESSON);
        state.modules = extractDataForSpecificSection(payload, BLOCK_TYPES.MODULES);
        state.tariffs = extractDataForSpecificSection(payload, BLOCK_TYPES.PRICE);
        state.reviews = extractDataForSpecificSection(payload, BLOCK_TYPES.REVIEW);
        state.events = extractDataForSpecificSection(payload, BLOCK_TYPES.EVENT);
        state.courses = extractDataForSpecificSection(payload, BLOCK_TYPES.COURSES);
        state.benefits = extractDataForSpecificSection(payload, BLOCK_TYPES.BENEFIT);
        state.error = '';
      })
      .addCase(getCourseLandingBlocksOperation.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default courseLandingSlice.reducer;
