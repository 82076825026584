import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

import ZoomImageControls from './components/zoom-image-controls';

import './styles.scss';

const SCROLL_SENSITIVITY = 0.05;
const MAX_ZOOM = 7;
const MIN_ZOOM = 0.5;

const ZoomImage = ({ image }) => {
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [isReady, setIsReady] = useState(false);

  const touch = useRef({ x: 0, y: 0 });
  const canvasRef = useRef(null);
  const containerRef = useRef(null);
  const background = useRef(new Image());

  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const handleWheel = (event) => {
    const { deltaY } = event;
    if (!dragging) {
      setZoom((z) => clamp(z + deltaY * SCROLL_SENSITIVITY * -1, MIN_ZOOM, MAX_ZOOM));
    }
  };

  const handleMouseMove = (event) => {
    if (dragging) {
      const { x, y } = touch.current;
      const { clientX, clientY } = event;
      setOffset({
        x: offset.x + (x - clientX),
        y: offset.y + (y - clientY),
      });
      touch.current = { x: clientX, y: clientY };
    }
  };

  const handleMouseDown = (event) => {
    const { clientX, clientY } = event;
    touch.current = { x: clientX, y: clientY };

    setDragging(true);
  };

  const handleMouseUp = () => setDragging(false);

  const drawLoading = (x, y) => {
    if (canvasRef.current) {
      canvasRef.current.width = x * 2;
      canvasRef.current.height = y * 2;
      const context = canvasRef.current.getContext('2d');

      context.save();
      context.font = '20px Lato';
      context.textAlign = 'center';
      context.fillStyle = 'white';
      context.textBaseline = 'middle';
      context.fillText('Loading ...', x, y);
      context.restore();
    }
  };

  const draw = () => {
    if (canvasRef.current) {
      const { width, height } = background.current;

      // Set canvas dimensions
      canvasRef.current.width = width;
      canvasRef.current.height = height;

      const context = canvasRef.current.getContext('2d');
      context.clearRect(0, 0, width, height);

      // Clear canvas and scale it
      context.translate(-offset.x, -offset.y);
      context.scale(zoom, zoom);

      // Make sure we're zooming to the center
      const x = (context.canvas.width / zoom - background.current.width) / 2;
      const y = (context.canvas.height / zoom - background.current.height) / 2;

      // Draw image
      context.drawImage(background.current, x, y);
    }
  };

  useEffect(() => {
    if (isReady) {
      draw();
    }
  }, [zoom, offset, isReady]);

  useEffect(() => {
    drawLoading(150, 75);
    background.current.src = image;

    if (canvasRef.current) {
      background.current.onload = () => {
        setIsReady(true);
      };
    }
  }, []);

  return (
    <div ref={containerRef} className="zoom-image__container">
      <canvas
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onWheel={handleWheel}
        onMouseMove={handleMouseMove}
        ref={canvasRef}
        className="zoom-image"
      />
      {isReady && <ZoomImageControls zoom={zoom} setZoom={setZoom} image={image} />}
    </div>
  );
};

ZoomImage.propTypes = {
  image: PropTypes.string.isRequired,
};

export default ZoomImage;
