import LANGUAGES_LIST from 'constants/localization/languages.list';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: LANGUAGES_LIST.UA.toLowerCase(),
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setInterfaceLanguage(state, { payload }) {
      state.language = payload;
    },
  },
});

export const { setInterfaceLanguage, setIsMobile } = settingsSlice.actions;

export default settingsSlice.reducer;
