import { createAction } from '@reduxjs/toolkit';
import { socketActionCollection } from 'constants';

import { socketService } from '../../services/socket';

const { community } = socketActionCollection;

const createName = (name = '') => `community/${name}`;

// ------------- Simple Actions

export const setActiveCommunityChannelId = createAction(
  createName('setActiveCommunityChannel'),
  (channelId) => ({
    payload: { channelId },
  }),
);

// ------------- Connectors
export const connectToCommunitySocket = createAction(
  createName('connectToCommunitySocket'),
  (communityId) => {
    socketService.send(community.send.communityConnect, { communityId });
    return {
      payload: { communityId },
    };
  },
);

export const connectToCommunityCategory = createAction(
  createName('connectToCommunityCategory'),
  (categoryId) => {
    socketService.send(community.send.communityCategoryConnect, { categoryId });
    return { payload: { categoryId } };
  },
);

export const connectToCommunityCommunication = createAction(
  createName('connectToCommunityCommunication'),
  (communicationItemId) => {
    socketService.send(community.send.communityCommunicationConnect, {
      communicationItemId,
    });
    return {
      payload: { communicationItemId },
    };
  },
);

// ------------- Receivers

export const receiveConnectToCommunity = createAction(
  createName('receiveConnectToCommunity'),
  (payload) => {
    return { payload };
  },
);

export const receiveConnectToCommunityCategory = createAction(
  createName('receiveConnectToCommunityCategory'),
  (payload) => {
    return { payload };
  },
);

export const receiveConnectToCommunityCommunication = createAction(
  createName('receiveConnectToCommunityCommunication'),
  (payload) => {
    return { payload };
  },
);
