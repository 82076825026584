import PropTypes from 'prop-types';

import SvgIcon from 'components/ui-core/svg-icon';

import {
  ACCEPTABLE_FILE_FORMATS,
  ACCEPTABLE_VIDEO_FORMATS,
  ATTACHMENT_CONTENT_TYPE,
  ATTACHMENT_ITEM_TYPE,
} from './attachment.constants';
import getFileFormat from './attachment.utils';
import FileAttachment from './components/files';
import ImageAttachment from './components/image';
import UploadStatusCircle from './components/uploading-status-circle';
import VideoAttachment from './components/video';

import './styles.scss';

const Attachment = (props) => {
  const { file, handleRemove, type, contentType, attachments, index } = props;
  const generateRemoveIconLayout = () => {
    if (ATTACHMENT_ITEM_TYPE.EDITABLE === type) {
      return (
        <button
          className="attachment-item__remove"
          type="button"
          onClick={() => handleRemove(file)}
        >
          <SvgIcon type="cross" />
        </button>
      );
    }

    return null;
  };

  const generatePreviewForAttachmentsFromMediaServer = () => {
    if (ACCEPTABLE_VIDEO_FORMATS.includes(getFileFormat(file, true))) {
      return <VideoAttachment file={file} attachments={attachments} index={index} />;
    }

    if (ACCEPTABLE_FILE_FORMATS.includes(getFileFormat(file, true))) {
      return <FileAttachment file={file} />;
    }

    return <ImageAttachment file={file} attachments={attachments} index={index} />;
  };

  const generatePreviewForLocalAttachments = () => {
    if (ACCEPTABLE_VIDEO_FORMATS.includes(getFileFormat(file, true))) {
      return (
        <div className="attachment-item__preview--video">
          <SvgIcon type="video" />
        </div>
      );
    }

    if (ACCEPTABLE_FILE_FORMATS.includes(getFileFormat(file, true))) {
      return (
        <div className="attachment-item__preview--file">
          <SvgIcon type="doc" />
        </div>
      );
    }

    return (
      <>
        {file.preview && (
          <img
            src={file.preview}
            alt={file.file.name}
            className="attachment-item__preview--image"
          />
        )}
      </>
    );
  };

  const generateAttachmentLayout = () => {
    if (contentType === ATTACHMENT_CONTENT_TYPE.LOCAL) {
      return (
        <>
          {file.uploading && (
            <UploadStatusCircle size={50} strokeWidth={5} percentage={file.progress} />
          )}
          {generatePreviewForLocalAttachments()}
        </>
      );
    }

    return generatePreviewForAttachmentsFromMediaServer();
  };

  return (
    <div className="attachment-item">
      {generateAttachmentLayout()}
      {generateRemoveIconLayout()}
    </div>
  );
};

Attachment.propTypes = {
  handleRemove: PropTypes.func,
  file: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      preview: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      uploading: PropTypes.bool.isRequired,
      file: PropTypes.object.isRequired,
    }).isRequired,
  ]).isRequired,
  type: PropTypes.oneOf(Object.values(ATTACHMENT_ITEM_TYPE)),
  contentType: PropTypes.string.isRequired,
};

Attachment.defaultProps = {
  handleRemove: () => {},
  type: ATTACHMENT_ITEM_TYPE.EDITABLE,
};

export default Attachment;
