import { useCallback } from 'react';

export const useClassName = (className, customPrefix) => {
  const prefix = customPrefix || 'ui';

  const rootClassName = `${prefix}-${className}`;

  const appendClass = useCallback(
    (childClass) => {
      if (Array.isArray(childClass)) {
        return childClass.map((child) => `${rootClassName}${child}`).join(' ');
      }

      return `${rootClassName}${childClass}`;
    },
    [rootClassName],
  );

  return { rootClassName, appendClass };
};
