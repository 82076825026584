import ROUTER_BOOK from '../../../../../../../constants/routes/routes-book';

const getYouTubeID = (url) => {
  if (!url) return null;
  // regex pattern to match the ID in a YouTube URL
  const pattern = /(?:\/|%3D|v=)([0-9A-Za-z_-]{11})(?:[%#?&]|$)/;
  const match = url.match(pattern);
  if (match && match[1]) {
    // return the ID if it's found
    return match[1];
  }
  // return null if the ID is not found
  return null;
};

const getNormalizeStreamInfo = (payload) => {
  const {
    stream: { youtubeLink, title, description, id, redirectUrl, status },
  } = payload;

  const normalizedData = {
    title,
    description,
    streamID: id,
    streamKey: getYouTubeID(youtubeLink),
    redirectUrl,
    status,
  };

  return normalizedData;
};

const getNormalizeStreamBanner = (payload) => {
  const {
    banner: { backgroundUrl, title, description, button, id },
  } = payload;

  const normalizedData = {
    buttonText: button?.text,
    buttonLink: button?.link,
    bannerID: id,
    bannerUrl: backgroundUrl,
    title,
    description,
  };

  return normalizedData;
};

export const streamRedirect = (status = '', redirectUrl = '', navigate = () => {}) => {
  if (typeof status !== 'string') {
    return new TypeError('Status must be a string.');
  }

  if (status.toLowerCase() === 'live') {
    return 'live';
  }

  if (typeof redirectUrl === 'string' && redirectUrl !== '') {
    window.location.assign(redirectUrl);
    return 'redirect';
  }
  navigate(ROUTER_BOOK.DASHBOARD);
  return 'redirect';
};

export { getNormalizeStreamBanner, getNormalizeStreamInfo, getYouTubeID };
