const BLOCK_TYPES = {
  COURSE: 'course',
  LESSON: 'lesson',
  MODULES: 'modules',
  PRICE: 'price',
  REVIEW: 'review',
  EVENT: 'event',
  COURSES: 'courses',
  BENEFIT: 'benefit',
};

export default BLOCK_TYPES;
