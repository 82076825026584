import React, { useCallback, useMemo, useRef, useState } from 'react';

import { getCameraPermission } from './_utils';
import { VideoRecordContext } from './video-record-context';

export const RECORDING_STATUS = {
  RECORDING: 'recording',
  INACTIVE: 'inactive',
};

const MIME_TYPE = 'video/webm;codecs=vp8';

export const VideoRecordProvider = (props) => {
  const { children, onComplete, mimeType = MIME_TYPE } = props;

  const [permission, setPermission] = useState(false);

  const mediaRecorder = useRef(null);

  const liveVideoFeed = useRef(null);

  const [recordingStatus, setRecordingStatus] = useState(RECORDING_STATUS.INACTIVE);

  const [stream, setStream] = useState(null);

  const [recordedVideo, setRecordedVideo] = useState(null);

  const [videoChunks, setVideoChunks] = useState([]);

  const startRecording = useCallback(async () => {
    setRecordedVideo(null);

    try {
      const { combinedStream, videoStream } = await getCameraPermission();

      liveVideoFeed.current.srcObject = videoStream;
      liveVideoFeed.current.url = videoStream;
      setPermission(true);

      setStream(combinedStream);

      const media = await new MediaRecorder(combinedStream, {
        mimeType: 'video/webm',
      });

      mediaRecorder.current = media;

      mediaRecorder.current.start();

      const localVideoChunks = [];

      mediaRecorder.current.ondataavailable = (event) => {
        if (typeof event.data === 'undefined') return;
        if (event.data.size === 0) return;
        localVideoChunks.push(event.data);
      };

      setVideoChunks(localVideoChunks);

      setRecordingStatus(RECORDING_STATUS.RECORDING);
    } catch (e) {
      setRecordingStatus(RECORDING_STATUS.INACTIVE);
      stream?.getTracks().forEach((track) => track.stop());
      throw new Error(e.message);
    }
  }, []);

  const stopRecording = useCallback(
    (withSaving = true) => {
      setPermission(false);
      stream?.getTracks().forEach((track) => track.stop());
      mediaRecorder.current?.stop();

      if (mediaRecorder.current) {
        mediaRecorder.current.onstop = () => {
          const videoBlob = new Blob(videoChunks, { type: mimeType });
          const videoUrl = URL.createObjectURL(videoBlob);

          setRecordedVideo(videoUrl);
          onComplete(videoUrl, videoBlob, withSaving);
          liveVideoFeed.current.videoUrl = videoUrl;

          setVideoChunks([]);
        };
      }

      setTimeout(() => setRecordingStatus(RECORDING_STATUS.INACTIVE), 0);
    },
    [videoChunks, mimeType, mediaRecorder, stream],
  );

  const value = useMemo(
    () => ({
      permission,
      isRecording: recordingStatus === RECORDING_STATUS.RECORDING,
      isInactive: recordingStatus === RECORDING_STATUS.INACTIVE,
      recordedVideo,
      startRecording,
      stopRecording,
      liveVideoFeed,
      stream,
      videoChunks,
    }),
    [
      startRecording,
      stopRecording,
      permission,
      recordingStatus,
      liveVideoFeed,
      stream,
      recordedVideo,
      videoChunks,
    ],
  );

  return <VideoRecordContext.Provider value={value}>{children}</VideoRecordContext.Provider>;
};
