import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { notificationType } from '../../../../../../../../constants';
import { joinToPrivateChatById } from '../../../../../../../../store/actions';
import { setLoaderVisibility } from '../../../../../../../loader/loader.slice';

export const useRedirectToPrivateMessage = (redirectInfo) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startPrivateMessageFlow = useCallback(
    (path) => {
      if (redirectInfo.type === notificationType.newPrivateMessage) {
        dispatch(setLoaderVisibility(true));
        new Promise((resolve) => {
          setTimeout(() => {
            dispatch(joinToPrivateChatById(redirectInfo.privateChatId));
            resolve();
          }, 0);
        })
          .then(() => navigate(path))
          .finally(() => dispatch(setLoaderVisibility(false)));
      }
    },
    [redirectInfo],
  );

  return startPrivateMessageFlow;
};
