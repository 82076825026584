import SvgIcon from 'components/ui-core/svg-icon';

import ROUTER_BOOK from 'constants/routes/routes-book';

const LMS_MENU_LIST = [
  {
    link: ROUTER_BOOK.DASHBOARD,
    icon: <SvgIcon type="dashboard" />,
  },
  {
    link: ROUTER_BOOK.COMMUNITY.BRANDS,
    icon: <SvgIcon type="community" />,
    hasBadge: true,
  },
  {
    link: `${ROUTER_BOOK.MY_WORKSPACE.GENERAL}/${ROUTER_BOOK.MY_WORKSPACE.COURSES}`,
    icon: <SvgIcon type="workspace" />,
    activePath: ROUTER_BOOK.MY_WORKSPACE.GENERAL,
  },
  {
    link: ROUTER_BOOK.COMMUNITY.MESSAGES,
    icon: <SvgIcon type="messages" />,
    hasBadge: true,
  },
  {
    link: ROUTER_BOOK.EVENTS,
    icon: <SvgIcon type="events" />,
  },
  {
    link: ROUTER_BOOK.MY_ACCOUNT,
    icon: <SvgIcon type="account" />,
    activePath: ROUTER_BOOK.MY_ACCOUNT,
  },
];

export const getMenuLinkList = ({ hasBadgePrivateMessage, hasBadgeCommunity }) => {
  return [
    {
      link: ROUTER_BOOK.DASHBOARD,
      icon: <SvgIcon type="dashboard" />,
    },
    {
      link: ROUTER_BOOK.COMMUNITY.BRANDS,
      icon: <SvgIcon type="community" />,
      hasBadge: hasBadgeCommunity,
    },
    {
      link: `${ROUTER_BOOK.MY_WORKSPACE.GENERAL}/${ROUTER_BOOK.MY_WORKSPACE.COURSES}`,
      icon: <SvgIcon type="workspace" />,
      activePath: ROUTER_BOOK.MY_WORKSPACE.GENERAL,
    },
    {
      link: ROUTER_BOOK.COMMUNITY.MESSAGES,
      icon: <SvgIcon type="messages" />,
      hasBadge: hasBadgePrivateMessage,
    },
    {
      link: ROUTER_BOOK.EVENTS,
      icon: <SvgIcon type="events" />,
    },
    {
      link: ROUTER_BOOK.MY_ACCOUNT,
      icon: <SvgIcon type="account" />,
      activePath: ROUTER_BOOK.MY_ACCOUNT,
    },
  ];
};

const MOBILE_MENU_LIST = [
  {
    link: ROUTER_BOOK.DASHBOARD,
    icon: <SvgIcon type="dashboard" />,
  },
  {
    link: ROUTER_BOOK.COMMUNITY.BRANDS,
    icon: <SvgIcon type="community" />,
    hasBadge: true,
  },
  {
    link: `${ROUTER_BOOK.MY_WORKSPACE.GENERAL}/${ROUTER_BOOK.MY_WORKSPACE.COURSES}`,
    icon: <SvgIcon type="workspace" />,
    activePath: ROUTER_BOOK.MY_WORKSPACE.GENERAL,
  },
  {
    link: ROUTER_BOOK.COMMUNITY.MESSAGES,
    icon: <SvgIcon type="messages" />,
    hasBadge: true,
  },
  {
    link: ROUTER_BOOK.EVENTS,
    icon: <SvgIcon type="events" />,
  },
  {
    link: ROUTER_BOOK.MY_ACCOUNT,
    icon: <SvgIcon type="account" />,
    activePath: ROUTER_BOOK.MY_ACCOUNT,
  },
];

const MENU_ITEMS_WITH_OWN_TRANSLATIONS = [
  {
    link: `${ROUTER_BOOK.MY_WORKSPACE.GENERAL}/${ROUTER_BOOK.MY_WORKSPACE.COURSES}`,
    translationPath: 'my-workspace',
  },
  {
    link: `${ROUTER_BOOK.MY_ACCOUNT.GENERAL}/${ROUTER_BOOK.MY_ACCOUNT.PERSONAL_INFORMATION}`,
    translationPath: 'my-account',
  },

  {
    link: ROUTER_BOOK.COMMUNITY.MESSAGES,
    translationPath: 'community.messages',
    hasBadge: true,
  },
  {
    link: ROUTER_BOOK.EVENTS,
    translationPath: 'calendar',
  },
  {
    link: ROUTER_BOOK.COMMUNITY.BRANDS,
    translationPath: 'community',
    hasBadge: true,
  },
];

export { MENU_ITEMS_WITH_OWN_TRANSLATIONS, LMS_MENU_LIST, MOBILE_MENU_LIST };
