import PropTypes from 'prop-types';

const Text = ({ label, name, register, placeholder, error }) => {
  return (
    <div className="field-container">
      {label && (
        <label htmlFor={name} className="field__label">
          {label}
        </label>
      )}
      {error && <span className="field__error">{error}</span>}
      <input
        id={name}
        className="field"
        type="text"
        {...register(name)}
        placeholder={placeholder}
      />
    </div>
  );
};
Text.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

Text.defaultProps = {
  placeholder: '',
  error: '',
  disabled: false,
  label: '',
};

export default Text;
