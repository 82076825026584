import cl from 'classnames';
import React from 'react';

import { useRecorderAudioContext, useRecorderContext } from '../../../context';
import Attachment from '../../attachment';
import AttachmentUploader from '../../attachment-uploader';
import { ATTACHMENT_CONTENT_TYPE } from '../../attachment/attachment.constants';
import { AudioAction, CircleVideoAction } from '../../message-actions';
import { EmojiSelection } from '../../ui-core';

import { SubmitBtn } from './submit-btn';

import s from './styles.module.scss';

export const FormFooter = (props) => {
  const {
    isSubmitting,
    setEmoji,
    hiddenAudioRecordAction = false,
    hiddenVideoRecordAction = false,
    hiddenAttachmentsButton = false,
    hiddenEmojiButton = false,
    fileUploaderRef,
    attachmentMediaServerData,
    filesList,
    setFilesList,
    onClickFileRemove,
  } = props;

  const videoRecorder = useRecorderContext();
  const audioRecorder = useRecorderAudioContext();

  const videoRecorderClasses = cl(s.formFooterRecorder, {
    [s.formFooterRecorderIsRecordingVideo]: videoRecorder.isRecording,
  });
  const audioRecorderClasses = cl(s.formFooterRecorder, {
    [s.formFooterRecorderIsRecordingAudio]: audioRecorder.isRecording,
  });

  return (
    <div className={s.formFooter}>
      <div className={s.formFooterFileList}>
        {filesList.map((file) => (
          <Attachment
            key={file.file.name}
            handleRemove={onClickFileRemove}
            file={file}
            contentType={ATTACHMENT_CONTENT_TYPE.LOCAL}
          />
        ))}
      </div>
      <div className={s.formFooterActions}>
        {!hiddenVideoRecordAction && (
          <div className={videoRecorderClasses}>
            <CircleVideoAction />
          </div>
        )}
        {!hiddenAudioRecordAction && (
          <div className={audioRecorderClasses}>
            <AudioAction />
          </div>
        )}
        {!hiddenAttachmentsButton && (
          <AttachmentUploader
            ref={fileUploaderRef}
            mediaServerData={attachmentMediaServerData}
            files={filesList}
            onChange={setFilesList}
          />
        )}
        {!hiddenEmojiButton && (
          <EmojiSelection
            iconType="smile"
            onEmojiSelect={setEmoji}
            className={s.formFooterEmojiSelection}
          />
        )}
        <SubmitBtn isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
