import API_LIST from 'constants/api/api-list';
import { get } from 'services/api';

const getDashboardBlocksRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.DASHBOARD}?lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getNotificationsBlocksRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.NOTIFICATION}?lang=${requestData.language}&orderBy=DESC`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getCourseLandingBlocksRequest = async (requestData = {}) => {
  const path = API_LIST.BLOCKS.COURSE_LANDING;
  const url = `${path}/${requestData.courseID}`;
  const params = { params: { lang: requestData.language } };

  try {
    const { data } = await get(url, params);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getCommunityLandingBlocksRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.COMMUNITY_LANDING}?lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getPurchasedCoursesBlocksRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.PURCHASED_COURSES}?lang=${requestData.language || 'ua'}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getCourseViewBlocksRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.COURSE_VIEW}/${requestData.groupID}?lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getOnboardingInterestsCategoriesRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.ONBOARDING_INTERESTS_CATEGORIES}?lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export {
  getCommunityLandingBlocksRequest,
  getCourseLandingBlocksRequest,
  getCourseViewBlocksRequest,
  getDashboardBlocksRequest,
  getNotificationsBlocksRequest,
  getOnboardingInterestsCategoriesRequest,
  getPurchasedCoursesBlocksRequest,
};
