import { adaptOTPCodeForAPI, adaptPhoneNumberForAPI } from 'utils';

const adaptUserPhoneNumberFormDataForAPIFirstStep = ({ phoneNumber }) => {
  const adaptedFormValues = {
    phone: adaptPhoneNumberForAPI(phoneNumber),
  };

  return adaptedFormValues;
};

const adaptUserPhoneNumberFormDataForAPISecondStep = ({ otp }) => {
  const adaptedFormValues = {
    firstOtp: adaptOTPCodeForAPI(otp),
  };

  return adaptedFormValues;
};

export {
  adaptUserPhoneNumberFormDataForAPIFirstStep,
  adaptUserPhoneNumberFormDataForAPISecondStep,
};
