export class SocketService {
  #token = null;
  #path = null;
  #url = null;
  #attemptCounter = 0;
  #maxAttemptCounter = 5;

  socket;
  isConnected;

  constructor() {
    this.isConnected = false;
  }

  createSocket = () => {
    this.socket = new WebSocket(this.#url);
  };

  connect = ({ onOpen, onError, onErrorAfterAllAttempt, onClose, onMessage, path, token }) => {
    this.#configure({ path, token });

    this.createSocket();

    this.socket.onopen = () => {
      onOpen?.();

      this.isConnected = true;
      this.#attemptCounter = 0;
    };

    this.socket.onerror = () => {
      if (this.#maxAttemptCounter > this.#attemptCounter) {
        this.#attemptCounter += 1;
        setTimeout(
          () =>
            this.connect({
              onOpen,
              onError,
              onClose,
              onMessage,
              path,
              token,
              onErrorAfterAllAttempt,
            }),
          100 * this.#attemptCounter,
        );

        onError?.();
      } else {
        onErrorAfterAllAttempt?.();
        this.isConnected = false;
      }
    };

    this.socket.onmessage = (message) => {
      onMessage?.(message);
    };

    this.socket.onclose = () => {
      onClose?.();
      this.isConnected = false;
    };
  };

  #configure = ({ path, token }) => {
    this.#token = token;
    this.#path = path;
    this.#url = `${path}?access=${token}`;
  };

  close = () => {
    if (!this.socket) return;

    this.isConnected = false;
    this.socket.close();
  };

  send = (action, data = {}) => {
    if (!this.socket || !this.isConnected) return;

    this.socket.send(JSON.stringify({ action, ...data }));
  };
}

export const socketService = new SocketService();
