import API_LIST from 'constants/api/api-list';
import { put } from 'services/api';

const sendSelectedInterestsCategoriesRequest = async (requestData = {}) => {
  try {
    const { data } = await put(API_LIST.USER.ADD_INTERESTS, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export default sendSelectedInterestsCategoriesRequest;
