import React from 'react';

import MODAL_TYPES from '../modal-container.constants';

import CommunicationItemSettings from './communication-items/communication-item-settings';
import CreateCommunicationItem from './communication-items/create-communication-item';
import DeleteCommunicationItem from './communication-items/delete-communication-item';
import EditMessage from './communication-items/edit-message';
import ConfigStopWords from './config-stop-words';
import FullSizeImageWithZoom from './full-size-image-with-zoom';
import MediaGallery from './media-gallery/media-gallery';
import { PaymentModal } from './payment-modal';
import { PaymentResultModal } from './payment-result-modal';
import PDFPreviewer from './pdf-previewer';
import { StripeModal } from './stripe-modal';
import CancelSubscriptionStep1 from './subscription/cancel-subscription-step-1';
import CancelSubscriptionStep2 from './subscription/cancel-subscription-step-2';
import CancelSubscriptionStep3 from './subscription/cancel-subscription-step-3';
import SubscriptionInfo from './subscription/subscription-info';
import { SuccessPaymentModal } from './success-payment-modal';
import { VideoModal } from './video-modal';

const MODALS = {
  [MODAL_TYPES.PAYMENTS]: <PaymentModal />,
  [MODAL_TYPES.PAYMENT_RESULT]: <PaymentResultModal />,
  [MODAL_TYPES['STRIPE-MODAL']]: <StripeModal />,
  [MODAL_TYPES['CREATE-COMMUNICATION-ITEM']]: <CreateCommunicationItem />,
  [MODAL_TYPES['DELETE-COMMUNICATION-ITEM']]: <DeleteCommunicationItem />,
  [MODAL_TYPES['COMMUNICATION-ITEM-SETTINGS']]: <CommunicationItemSettings />,
  [MODAL_TYPES['EDIT-MESSAGE']]: <EditMessage />,
  [MODAL_TYPES['FULL-SIZE-IMAGE-WITH-ZOOM']]: <FullSizeImageWithZoom />,
  [MODAL_TYPES['PDF-PREVIEWER']]: <PDFPreviewer />,
  [MODAL_TYPES['CONFIG-STOP-WORDS']]: <ConfigStopWords />,
  [MODAL_TYPES['CANCEL-SUBSCRIPTION-STEP-1']]: <CancelSubscriptionStep1 />,
  [MODAL_TYPES['CANCEL-SUBSCRIPTION-STEP-2']]: <CancelSubscriptionStep2 />,
  [MODAL_TYPES['CANCEL-SUBSCRIPTION-STEP-3']]: <CancelSubscriptionStep3 />,
  [MODAL_TYPES['SUBSCRIPTION-INFO']]: <SubscriptionInfo />,
  [MODAL_TYPES['VIDEO-PLAYER']]: <VideoModal />,
  [MODAL_TYPES['SUCCESS-PAYMENT-MODAL']]: <SuccessPaymentModal />,
  [MODAL_TYPES['MEDIA-GALLERY']]: <MediaGallery />,
};

export default MODALS;
