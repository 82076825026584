import API_LIST from 'constants/api/api-list';
import { get, patch, post } from 'services/api';

const getUserInfoRequest = async (requestData) => {
  const url = `${API_LIST.USER.GET_ME}?lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const updateUserPersonalInformationRequest = async (requestData) => {
  const url = API_LIST.USER.GET_ME;

  try {
    const { data } = await patch(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const uploadUserAvatarRequest = async (requestData) => {
  const url = `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}/attachment/lms${API_LIST.USER.UPLOAD_IMAGE_FOR_USER}`;

  try {
    const { data } = await post(url, requestData, {
      ContentType: 'multipart/form-data',
      baseURL: process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH,
    });

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const sendOTPToUserEmailRequest = async (requestData) => {
  const url = API_LIST.USER.ADD_EMAIL.FIRST_STEP;

  try {
    const { data } = await patch(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const verifyOTPAndAddUserEmailRequest = async (requestData) => {
  const url = API_LIST.USER.ADD_EMAIL.SECOND_STEP;

  try {
    const { data } = await patch(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const sendOTPToUserPhoneRequest = async (requestData) => {
  const url = API_LIST.USER.ADD_PHONE.FIRST_STEP;

  try {
    const { data } = await patch(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const verifyOTPAndAddUserPhoneRequest = async (requestData) => {
  const url = API_LIST.USER.ADD_PHONE.SECOND_STEP;

  try {
    const { data } = await patch(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const sendOldAndNewUserPasswordsRequest = async (requestData) => {
  const url = API_LIST.USER.CHANGE_PASSWORD.FIRST_STEP;

  try {
    const { data } = await patch(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const verifyOTPAndChangeUserPasswordRequest = async (requestData) => {
  const url = API_LIST.USER.CHANGE_PASSWORD.SECOND_STEP;

  try {
    const { data } = await patch(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export {
  getUserInfoRequest,
  sendOldAndNewUserPasswordsRequest,
  sendOTPToUserEmailRequest,
  sendOTPToUserPhoneRequest,
  updateUserPersonalInformationRequest,
  uploadUserAvatarRequest,
  verifyOTPAndAddUserEmailRequest,
  verifyOTPAndAddUserPhoneRequest,
  verifyOTPAndChangeUserPasswordRequest,
};
