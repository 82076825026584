import React, { useState } from 'react';

import cl from 'classnames';
import { useTranslation } from 'react-i18next';

import { useClassName } from '../../../../../../../hooks/use-class-name';
import { useToggle } from '../../../../../../../hooks/use-toggle';

import { linkPattern } from './schema';

import './styles.scss';

const checkIsValidUrl = (url = '') => {
  return linkPattern.test(url);
};

export const LinkForm = (props) => {
  const { editor, onClose } = props;

  const { t } = useTranslation();

  const { rootClassName, appendClass } = useClassName('rich-text-editor-link-form', 'atom');

  const [url, setUrl] = useState(() => {
    return editor.getAttributes('link').href || '';
  });

  const [hasError, toggleHasError] = useToggle(false);

  const handleSetUrl = (event) => {
    const { value } = event.target;

    if (checkIsValidUrl(value) && hasError) {
      toggleHasError(false);
    }

    setUrl(value);
  };

  const handleClickSave = () => {
    if (hasError) return;

    if (url === null) {
      return;
    }

    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    if (!checkIsValidUrl(url)) {
      toggleHasError(true);
      return;
    }

    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();

    setUrl('');
    onClose();
  };

  const fieldClasses = cl(appendClass('-field'), {
    [appendClass('-field--with-error')]: hasError,
  });

  return (
    <div className={rootClassName}>
      <div className={appendClass('-field-container')}>
        <input
          value={url}
          placeholder="https://example.com"
          className={fieldClasses}
          onChange={handleSetUrl}
        />
      </div>
      <button className={appendClass('-btn')} onClick={handleClickSave} type="button">
        <span>{t('shared-content.save')}</span>
      </button>
    </div>
  );
};
