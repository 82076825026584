import ROUTER_BOOK from 'constants/routes/routes-book';

import { notificationType } from '../../../../../../../../constants';

export const getRedirectPath = (redirectInfo) => {
  if (!redirectInfo && !('type' in redirectInfo)) return '';
  const type = redirectInfo.type;

  const { renewSubscription, communityMention, upcomingStream, newPrivateMessage } =
    notificationType;

  switch (type) {
    case communityMention:
      return ROUTER_BOOK.COMMUNITY.CATEGORIES.replace(':brandId', redirectInfo.communityId);
    case renewSubscription:
      return '';
    case upcomingStream:
      return '';
    case newPrivateMessage:
      return ROUTER_BOOK.COMMUNITY.MESSAGES;
    default:
      return '';
  }
};
