import { nanoid } from '@reduxjs/toolkit';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Link from 'components/ui-core/link';
import LINK_TYPES from 'components/ui-core/link/link.constants';

import { selectHasUnreadCommunity, selectHasUnreadPrivateChat } from '../../../../store/selectors';

import { getMenuLinkList } from './menu.constants';
import getTranslationForMenuItem from './menu.utils';

import './styles.scss';


const Menu = () => {
  const hasUnreadPrivateMessage = useSelector(selectHasUnreadPrivateChat);
  const hasUnreadCommunity = useSelector(selectHasUnreadCommunity);

  const { t } = useTranslation();

  const menuListItems = getMenuLinkList({
    hasBadgePrivateMessage: hasUnreadPrivateMessage,
    hasBadgeCommunity: hasUnreadCommunity,
  });

  return (
    <ul className="menu">
      {menuListItems.map((item) => {
        const isActiveLink = window.location.pathname.includes(item.activePath || item.link);

        return (
          <li key={nanoid()} className={cn('menu__item', { 'menu__item--active': isActiveLink })}>
            <Link
              href={item.link}
              type={LINK_TYPES.MENU}
              content={
                <>
                  {item.icon}
                  <span className="menu__item__label">
                    <span> {t(`${getTranslationForMenuItem(item.link)}.title`)}</span>
                    {item?.hasBadge && <span className="menu__item__badge"></span>}
                  </span>
                </>
              }
            />
          </li>
        );
      })}
    </ul>
  );
};

export default Menu;
