import { MENU_ITEMS_WITH_OWN_TRANSLATIONS } from './menu.constants';

const getTranslationForMenuItem = (menuLink) => {
  const menuItemWithOwnTranslation = MENU_ITEMS_WITH_OWN_TRANSLATIONS.find(
    ({ link }) => link === menuLink,
  );

  if (menuItemWithOwnTranslation) {
    return menuItemWithOwnTranslation.translationPath;
  }

  return menuLink.substring(1, menuLink.length);
};

export default getTranslationForMenuItem;
