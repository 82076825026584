/* eslint-disable react/prop-types */

/* eslint-disable react/prefer-stateless-function */
import colors from 'assets/styles/variables.scss';
import ROUTER_BOOK from 'constants/routes/routes-book';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
// import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Button from 'components/ui-core/button';
import { BUTTON_SIZES, BUTTON_VARIANTS } from 'components/ui-core/button/button.constants';

// import { setError } from './error-boundary.slice';
import './styles.scss';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children, t } = this.props;

    if (hasError) {
      return (
        <div className="error-boundary">
          <div className="error-boundary__content">
            <h1 className="error-boundary__heading">{t('error-page.title')}</h1>
            <p className="error-boundary__sub-heading">{t('error-page.subtitle')}</p>
            {/* <p className="error-boundary__description">{t('error-page.description')}</p> */}
            <div className="error-boundary__actions-block">
              <Button
                onClick={() => window.location.reload()}
                content={t('shared-content.try-again')}
                size={BUTTON_SIZES.SM}
                contentColor={colors.white}
                variant={BUTTON_VARIANTS.OUTLINE}
                backgroundColor={colors['secondary-purple-300']}
                className="fit-content"
              />
              <Link
                to={ROUTER_BOOK.DASHBOARD}
                onClick={() => this.setState({ hasError: false })}
                className="error-boundary__link"
              >
                {t('shared-content.back-to-main')}
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

// const mapStateToProps = state => {
//   return {
//     hasError: state.errorBoundary.hasError,
//   };
// };

// const mapDispatchToProps = { setError };

// export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ErrorBoundary));

export default withTranslation()(ErrorBoundary);
