/* eslint-disable no-underscore-dangle */
import { createSlice, current } from "@reduxjs/toolkit";
import { connectToPrivateMessagesSocket, createPrivateChat, getPrivateMessages, joinToPrivateChatById, receiveCreatedChat, receiveEditMessage, receiveEmojiToggle, receivePrivateChatError, receivePrivateChats, receivePrivateMessage, receivePrivateMessages, receiveUnreadChat, receiveViewPrivateMessages, setActiveChat, setRecipientIdFromCommunity, setUserActiveStatus, viewPrivateMessageLocale } from "store/actions";



import CHANNEL_ITEM_TYPES from "./components/private-messages/components/sidebar/components/channel-item/channel-item.constants";


export const initialSupportData = {
  authorName: 'Crypto crew support',
  unreadCount: 0,
  chatInfo: {
    _id: CHANNEL_ITEM_TYPES.SUPPORT,
  },
  lastMessage: null,
  isParticipantOnline: true,
};

const initialState = {
  supportChatData: { ...initialSupportData },
  chatsList: [],
  filteredChatList: [],
  activeChatID: null,
  activeChat: {
    chatId: null,
    owner: null,
    companion: null,
    messages: [],
    isLastMessagePresent: false,
    isFirstMessagePresent: false,
    loadedHashMap: {},
  },
  isMessageListLoading: true,
  recipientIdFromCommunity: null,
};

const privateMessagesSlice = createSlice({
  name: 'privateMessages',
  initialState,
  reducers: {
    handleReceiveChatsList(state, { payload }) {
      state.chatsList = payload;
      state.filteredChatList = payload;
    },

    decreaseCountOfUnreadMessages(state, { payload }) {
      if (
        state.activeChatID === CHANNEL_ITEM_TYPES.SUPPORT &&
        state.supportChatData.unreadCount > 0
      ) {
        state.supportChatData.unreadCount -= payload;
      } else {
        state.chatsList.forEach((chat) => {
          if (chat.chatInfo._id === state.activeChatID && chat.unreadCount > 0) {
            chat.unreadCount -= payload;
          }
        });

        if (state.filteredChatList.length) {
          state.filteredChatList.forEach((chat) => {
            if (chat.chatInfo._id === state.activeChatID && chat.unreadCount > 0) {
              chat.unreadCount -= payload;
            }
          });
        }
      }
    },
    handleUserOffline(state, { payload }) {
      state.chatsList.forEach((chat) => {
        if (chat.chatInfo.participantIds.includes(payload.userId)) {
          chat.isParticipantOnline = false;
        }
      });

      if (state.filteredChatList.length) {
        state.chatsList.forEach((chat) => {
          if (chat.chatInfo.participantIds.includes(payload.userId)) {
            chat.isParticipantOnline = false;
          }
        });
      }
    },
    handleUserOnline(state, { payload }) {
      state.chatsList.forEach((chat) => {
        if (chat.chatInfo.participantIds.includes(payload.userId)) {
          chat.isParticipantOnline = true;
        }
      });

      if (state.filteredChatList.length) {
        state.chatsList.forEach((chat) => {
          if (chat.chatInfo.participantIds.includes(payload.userId)) {
            chat.isParticipantOnline = true;
          }
        });
      }
    },
    handleReceiveUnreadMessage(state, { payload }) {
      let indexOfElementToChangePosition;

      state.chatsList.forEach((chat, chatIndex) => {
        if (chat.chatInfo._id === payload.privateChatId) {
          chat.lastMessage = payload;
          chat.unreadCount += 1;
          indexOfElementToChangePosition = chatIndex;
        }
      });

      if (indexOfElementToChangePosition) {
        const tmpChatList = current(state.chatsList);
        const firstElementToAddInChatList = tmpChatList.splice(indexOfElementToChangePosition, 1);

        tmpChatList.unshift(...firstElementToAddInChatList);

        state.chatsList = tmpChatList;
      }

      if (state.filteredChatList.length) {
        state.filteredChatList.forEach((chat, chatIndex) => {
          if (chat.chatInfo._id === payload.privateChatId) {
            chat.lastMessage = payload;
            chat.unreadCount += 1;
            indexOfElementToChangePosition = chatIndex;
          }
        });

        if (indexOfElementToChangePosition) {
          const tmpChatList = current(state.filteredChatList);
          const firstElementToAddInChatList = tmpChatList.splice(indexOfElementToChangePosition, 1);

          tmpChatList.unshift(...firstElementToAddInChatList);

          state.filteredChatList = tmpChatList;
        }
      }
    },

    setAdditionalDataForRecipientFromCommunity(state, { payload }) {
      if (payload.isExist) {
        state.activeChatID = payload.privateChatId;
        state.recipientFromCommunity = initialState.recipientFromCommunity;
      } else {
        state.recipientFromCommunity = {
          ...state.recipientFromCommunity,
          ...payload,
        };
      }
    },
    setLastMessageForSupportChat(state, { payload }) {
      state.supportChatData.lastMessage = payload.message;
      state.supportChatData.unreadCount = payload.unreadCount;
    },
    handleReceivedUnreadMessageSupportChat(state, { payload }) {
      state.supportChatData.lastMessage = payload;
      state.supportChatData.unreadCount += 1;
    },
    handleOnSearchInputTriggered(state, { payload }) {
      if (!payload) {
        state.filteredChatList = state.chatsList;
      }

      const resultList = current(state).chatsList.filter((chatItem) => {
        if (chatItem.authorName.toLowerCase().includes(payload.toLowerCase())) {
          return chatItem;
        }

        return undefined;
      });

      state.filteredChatList = resultList;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectToPrivateMessagesSocket.fulfilled, (state) => {
      state.isMessageListLoading = true;
    });
    builder.addCase(createPrivateChat.fulfilled, (state) => {
      state.recepientIdFromCommunity = null;
      state.isMessageListLoading = true;
    });
    builder.addCase(receivePrivateChats.fulfilled, (state, { payload }) => {
      state.chatsList = payload.chats;
      state.recipientIdFromCommunity = null;
      state.isMessageListLoading = false;

      if (payload.activeChatId) {
        state.activeChat.chatId = payload.activeChatId;
      }
    });
    builder.addCase(joinToPrivateChatById, (state, { payload }) => {
      state.activeChat.chatId = payload;
      state.isMessageListLoading = true;
    });
    builder.addCase(setActiveChat.fulfilled, (state, { payload }) => {
      state.activeChat = {
        ...state.activeChat,
        ...payload,
      };

      if (payload.messages.length === 0) {
        state.activeChat.isLastMessagePresent = true;
        state.activeChat.isFirstMessagePresent = true;
      }

      state.isMessageListLoading = false;
    });
    builder.addCase(getPrivateMessages.fulfilled, (state) => {
      state.isMessageListLoading = true;
    });
    builder.addCase(receivePrivateMessages.fulfilled, (state, { payload }) => {
      const { messages, isFirstMessagePresent, isLastMessagePresent, direction } = payload;
      const { activeChat } = state;

      state.isMessageListLoading = false;

      if (direction === 'up') {
        const reversedMessages = messages.slice().reverse();

        activeChat.messages = [...reversedMessages, ...activeChat.messages];
        if (!activeChat.isFirstMessagePresent && isFirstMessagePresent) {
          activeChat.isFirstMessagePresent = isFirstMessagePresent;
        }
      }

      if (direction === 'down') {
        activeChat.messages = [...activeChat.messages, ...messages];

        if (!activeChat.isLastMessagePresent && isLastMessagePresent) {
          activeChat.isLastMessagePresent = isLastMessagePresent;
        }
      }
    });
    builder.addCase(receivePrivateMessage.fulfilled, (state, { payload }) => {
      const { messages, chatId } = state.activeChat;

      state.chatsList.forEach((chat) => {
        if (chat.chatInfo.id === chatId) {
          chat.lastMessage = payload;

          if (payload.accountId !== payload.authorId) {
            chat.chatInfo.unreadCount += 1;
          }
        }
      });

      const canPushMessage =
        state.activeChat.isLastMessagePresent || state.activeChat.messages.length === 0;

      if (canPushMessage) {
        messages.push(payload);
      }
    });
    builder.addCase(receiveEditMessage.fulfilled, (state, { payload }) => {
      state.activeChat.messages = payload.messages;
    });
    builder.addCase(receiveEmojiToggle.fulfilled, (state, { payload }) => {
      state.activeChat.messages.forEach((message) => {
        if (message.id === payload.messageId) {
          message.reactions = payload;
        }
      });
    });
    builder.addCase(setUserActiveStatus.fulfilled, (state, { payload }) => {
      const { userId, isActive } = payload;
      const { activeChat } = state;
      const { companion } = activeChat;

      if (companion && companion.id === userId) {
        companion.isOnline = isActive;
      }

      state.chatsList.forEach((chat) => {
        chat.participants.forEach((participant) => {
          if (participant.id === userId) {
            participant.isOnline = isActive;
          }
        });
      });
    });
    builder.addCase(receivePrivateChatError.fulfilled, (state) => {
      state.isMessageListLoading = false;
    });
    builder.addCase(receiveCreatedChat.fulfilled, (state, { payload }) => {
      state.chatsList.push(payload);
    });
    builder.addCase(setRecipientIdFromCommunity.fulfilled, (state, { payload }) => {
      state.recipientIdFromCommunity = payload;
    });
    builder.addCase(receiveUnreadChat.fulfilled, (state, { payload }) => {
      state.chatsList.forEach((chat) => {
        if (chat.chatInfo.id === payload.privateChatId) {
          chat.chatInfo.unreadCount += 1;
          chat.lastMessage = payload;
        }
      });
    });

    builder.addCase(receiveViewPrivateMessages.fulfilled, (state, { payload }) => {
      state.activeChat.messages.forEach((message) => {
        if (payload.includes(message.id)) {
          message.isViewed = true;
          message.viewCount += 1;
        }
      });

      state.chatsList.forEach((chat) => {
        if (payload.includes(chat?.lastMessage?.id)) {
          chat.lastMessage.isViewed = true;
          chat.lastMessage.viewCount += 1;
        }
      });
    });
    builder.addCase(viewPrivateMessageLocale.fulfilled, (state, { payload }) => {
      state.activeChat.messages.forEach((message) => {
        if (payload === message.id) {
          message.isViewed = true;
          message.viewCount += 1;
        }
      });

      state.chatsList.forEach((chat) => {
        if (chat.chatInfo.id === state.activeChat.chatId && chat.chatInfo.unreadCount > 0) {
          chat.chatInfo.unreadCount -= 1;

          if (chat.lastMessage.id === payload) {
            chat.lastMessage.isViewed = true;
          }
        }
      });
    });
  },
});

export const {
  handleReceiveChatsList,
  setActiveChatID,
  handleReceiveMessagesList,
  handleReceiveMessage,
  handleReceiveEmoji,
  handleEditedMessageData,
  handleReceiveMessagesFromPagination,
  markMessagesAsViewed,
  decreaseCountOfUnreadMessages,
  handleUserOffline,
  handleUserOnline,
  handleReceiveUnreadMessage,
  handleUpdateViewCount,
  setRecipientFromCommunity,
  setAdditionalDataForRecipientFromCommunity,
  setLastMessageForSupportChat,
  handleReceivedUnreadMessageSupportChat,
  handleOnSearchInputTriggered,
} = privateMessagesSlice.actions;

export default privateMessagesSlice.reducer;
