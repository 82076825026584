const API_LIST = Object.freeze({
  AUTH: {
    SIGN_UP: '/auth/sign-up',
    SIGN_IN: '/auth/sign-in',
    SIGN_IN_WITH_GOOGLE: '/auth/sign-in-with-google',
    SIGN_IN_WITH_APPLE: '/auth/sign-in-with-apple',
    FORGOT_PASSWORD_FIRST_STEP: '/auth/forgot-password/first-step',
    FORGOT_PASSWORD_SECOND_STEP: '/auth/forgot-password/second-step',
    VERIFY: '/auth/verify',
  },
  BLOCKS: {
    DASHBOARD: '/block/dashboard',
    NOTIFICATION: '/block/notification',
    COURSE_LANDING: '/block/courses',
    COMMUNITY_LANDING: '/block/community-prices',
    COURSE_VIEW: '/block/courses/groups',
    PURCHASED_COURSES: '/block/my-workspace',
    HOMEWORK: '/block/my-workspace/homeworks',
    ONBOARDING_INTERESTS_CATEGORIES: '/block/onboard',
    CALENDAR: '/block/calendar',
    BRANDS: '/block/communities',
    BRAND: '/block/business-account',
  },
  NOTIFICATIONS: {
    VIEWED: '/notifications/viewed',
  },
  USER: {
    GET_ME: '/users/me',
    UPLOAD_IMAGE_FOR_USER: '/images/user',
    ADD_EMAIL: {
      FIRST_STEP: '/users/add-email/first-step',
      SECOND_STEP: '/users/add-email/second-step',
    },
    ADD_PHONE: {
      FIRST_STEP: '/users/add-phone/first-step',
      SECOND_STEP: '/users/add-phone/second-step',
    },
    CHANGE_PASSWORD: {
      FIRST_STEP: '/users/change-password/first-step',
      SECOND_STEP: '/users/change-password/second-step',
    },
    ADD_INTERESTS: '/onboard/user',
  },

  PAYMENTS: {
    PAY_WAY_FOR_PAY: '/payments/pay-with-wayforpay',
    PAY_PAY_PAL: '/payments/pay-with-paypal',
    PAY_BIT_PAY: '/payments/pay-with-bitpay',
    CHECK_WAY_FOR_PAY: '/payments/pay-with-wayforpay/success',
    CHECK_PAY_PAL: '/payments/pay-with-paypal/success',
    CHECK_BIT_PAY: '/payments/pay-with-bitpay/success',
  },

  MEDIA_SERVER: {
    UPLOAD_CHANNEL_ATTACHMENTS: '/attachment/community/message',
    UPLOAD_CATEGORY_IMAGE: '/attachment/community/category',
    UPLOAD_PRIVATE_MESSAGES_ATTACHMENTS: '/attachment/private-chat/message',
    UPLOAD_SUPPORT_ATTACHMENTS: '/attachment/support-chat/message',
  },
  CALENDAR: '/calendar',

  PAYMENT: {
    CONFIG: '/stripe/config',
    CREATE_SUBSCRIPTION: '/stripe/create-subscription',
    CREATE_PAYMENT_INTENT: '/stripe/create-payment-intent',
    CREATE_PAYMENT_METHOD: '/stripe/payment-methods',
    GET_PAYMENT_METHODS: '/stripe/payment-methods',
    REMOVE_PAYMENT_METHOD: '/stripe/detach-payment-method',
    UNSUBSCRIBE: '/stripe/unsubscribe',
  },
  PRICE: {
    COMMUNITY: '/price/community',
  },
});

export default API_LIST;
