import API_LIST from 'constants/api/api-list';
import { patch, post } from 'services/api';

const authThroughGoogleRequest = async (reqData = {}) => {
  const url = API_LIST.AUTH.SIGN_IN_WITH_GOOGLE;

  try {
    const { data } = await post(url, reqData);

    return data.item;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const authThroughAppleRequest = async (reqData = {}) => {
  const url = API_LIST.AUTH.SIGN_IN_WITH_APPLE;

  try {
    const { data } = await post(url, { platform: 'web', ...reqData });

    return data.item;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const signUpRequest = async (reqData = {}) => {
  const url = API_LIST.AUTH.SIGN_UP;

  try {
    const { data } = await post(url, reqData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const signInRequest = async (reqData = {}) => {
  const url = API_LIST.AUTH.SIGN_IN;

  try {
    const { data } = await post(url, reqData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const sendOTPCodeForForgotPasswordFormRequest = async (reqData = {}) => {
  const url = API_LIST.AUTH.FORGOT_PASSWORD_FIRST_STEP;

  try {
    const { data } = await patch(url, reqData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const changeUserPasswordRequest = async (reqData = {}) => {
  const url = API_LIST.AUTH.FORGOT_PASSWORD_SECOND_STEP;

  try {
    const { data } = await patch(url, reqData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const verifyOTPRequest = async (reqData = {}) => {
  const url = API_LIST.AUTH.VERIFY;

  try {
    const { data } = await post(url, reqData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getReCaptchaTokenRequest = async () => {
  const captcha = await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY);

  return captcha;
};

export {
  authThroughAppleRequest,
  authThroughGoogleRequest,
  changeUserPasswordRequest,
  getReCaptchaTokenRequest,
  sendOTPCodeForForgotPasswordFormRequest,
  signInRequest,
  signUpRequest,
  verifyOTPRequest,
};
