import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { asHtml } from '../../../../../utils';

import './styles.scss';

export const Body = (props) => {
  const { subscriptionName, price } = props;

  const { t } = useTranslation();

  const purchaseDate = dayjs().format('MMMM DD YYYY');

  return (
    <div className="payment-result-modal-body">
      <div className="payment-result-modal-body-info">
        <span className="payment-result-modal-body-info-label">{t('shared-content.date')}:</span>
        <span className="payment-result-modal-body-info-label">{purchaseDate}</span>
      </div>
      <div className="payment-result-modal-body-divider" />
      <div className="payment-result-modal-body-purchase">
        <span className="payment-result-modal-body-purchase-label">{asHtml(subscriptionName)}</span>
        <span className="payment-result-modal-body-purchase-label">{price}$</span>
      </div>
    </div>
  );
};
