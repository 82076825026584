import API_LIST from 'constants/api/api-list';
import { get, post } from 'services/api';
import { createFormData } from 'utils';

import { CONTENT_TYPES } from '../../api';

const getHomeworkFromUserCourseRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.HOMEWORK}?groupId=${requestData.courseID}&lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const getHomeworkExercisesRequest = async (requestData = {}) => {
  const url = `${API_LIST.BLOCKS.HOMEWORK}/${requestData.homeworkID}?groupId=${requestData.courseID}&lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const sendTestExerciseAnswerRequest = async (requestData = {}) => {
  const url = `/homework/${requestData.homeworkID}/test/${requestData.exerciseID}/complete?groupId=${requestData.courseID}`;

  try {
    const { data } = await post(url, { selectedHomeworkTestOptionIds: requestData.userAnswers });

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const sendWritingExerciseAnswerRequest = async (requestData = {}) => {
  const url = `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}/attachment/lms/homework/${requestData.homeworkID}/writing/${requestData.exerciseID}/complete?groupId=${requestData.courseID}`;

  const generateFormDataForRequest = () => {
    if (requestData.userAnswer.file) {
      return createFormData({
        payload: {
          answer: requestData.userAnswer.answer,
        },
        file: requestData.userAnswer.file,
      });
    }

    return createFormData({
      payload: {
        answer: requestData.userAnswer.answer,
      },
    });
  };

  try {
    const { data } = await post(url, generateFormDataForRequest(), {
      ...CONTENT_TYPES.FORM_DATA,
    });

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export {
  getHomeworkExercisesRequest,
  getHomeworkFromUserCourseRequest,
  sendTestExerciseAnswerRequest,
  sendWritingExerciseAnswerRequest,
};
