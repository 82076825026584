import React, { useCallback, useState } from 'react';

export const useToggle = (initialValue = false) => {
  const [value, setValue] = useState(initialValue);
  const toggle = React.useCallback((setupValue) => {
    setValue((prevState) => {
      if (typeof setupValue === 'boolean') {
        return setupValue;
      }
      return !prevState;
    });
  }, []);

  const turnOn = useCallback(() => setValue(true), []);
  const turnOff = useCallback(() => setValue(false), []);

  return [value, toggle, { turnOn, turnOff }];
};
