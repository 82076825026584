import { memo } from 'react';

import { useSelector } from 'react-redux';
import { selectNotificationList } from 'store/selectors';

import { For } from 'components/ui-core';

import { NotificationItem } from './notification-item';


export const NotificationList = memo((props) => {
  const { toggleIsOpen } = props;
  const notificationList = useSelector(selectNotificationList);
  const renderItems = (item) => {
    return (
      <NotificationItem
        key={item.id}
        text={item.body}
        title={item.title}
        image={item.imageUrl}
        redirectInfo={item.redirectInfo}
        id={item.id}
        toggleIsOpen={toggleIsOpen}
      />
    );
  };

  return (
    <ul>
      <For data={notificationList} render={renderItems} />
    </ul>
  );
});
