import * as pages from 'pages';

import ROUTER_BOOK from './routes-book';

const routesList = [
  {
    path: ROUTER_BOOK.COMMUNITY.DASHBOARD,
    page: pages.CommunityGroupChatsDashboard,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.COMMUNITY.CATEGORIES,
    page: pages.CommunityGroupChatsCategories,
    isProtected: true,
  },
  // {
  //   path: ROUTER_BOOK.COMMUNITY.DASHBOARD,
  //   page: CommunityCommunication,
  //   isProtected: true,
  // },
  {
    path: ROUTER_BOOK.COMMUNITY.MESSAGES,
    page: pages.CommunityMessages,
    isProtected: true,
  },

  {
    path: ROUTER_BOOK.DASHBOARD,
    page: pages.Dashboard,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.MY_ACCOUNT,
    page: pages.MyAccount,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.MY_WORKSPACE.MAPPING_PATH,
    page: pages.MyWorkspace,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.EVENTS,
    page: pages.Calendar,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.PAYMENT_STATUS,
    page: pages.PaymentStatus,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.ONBOARDING,
    page: pages.Onboarding,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.COMMUNITY.BRANDS,
    page: pages.CommunityBrands,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.COMMUNITY.BRAND,
    page: pages.CommunityBrand,
    isProtected: true,
    isAllowAccess: (location) => {
      const urlParams = location.search ? new URLSearchParams(location.search) : null;

      if (urlParams) {
        const groupId = urlParams.get('groupId');

        return Boolean(Number(groupId));
      }

      return false;
    },
  },
  {
    path: ROUTER_BOOK.COMMUNITY.COURSE,
    page: pages.CommunityCourseLanding,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.NOT_FOUND,
    page: pages.NotFound,
  },
  {
    path: '*',
    page: pages.Auth,
  },
  {
    path: ROUTER_BOOK.REFERRAL_PROGRAM,
    page: pages.ReferralProgram,
    isProtected: true,
  },
  {
    path: ROUTER_BOOK.ACCESS_RULES.PRIVACY_POLICY,
    page: pages.PrivacyPolicy,
  },

  {
    path: ROUTER_BOOK.ACCESS_RULES.TERMS_AND_CONDITIONALS,
    page: pages.TermsAndConditionals,
  },
];
export default routesList;
