import API_LIST from 'constants/api/api-list';
import { get } from 'services/api';

const getBrandRequest = async (id) => {
  const url = `${API_LIST.BLOCKS.BRAND}/${id}/dashboard`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export default getBrandRequest;
