import FORM_NAMES from './auth.constants';

const preferredAuthTypeSelector = (state) => state.auth.preferredAuthType;
const isUserHasAccessSelector = (state) => state.auth.isUserHasAccess;
const signUpThroughEmailFormDataSelector = (state) => {
  return state.auth.forms[FORM_NAMES.SIGN_UP_THROUGH_EMAIL];
};
const signUpThroughPhoneFormDataSelector = (state) => {
  return state.auth.forms[FORM_NAMES.SIGN_UP_THROUGH_PHONE];
};
const signInThroughEmailFormDataSelector = (state) => {
  return state.auth.forms[FORM_NAMES.SIGN_IN_THROUGH_EMAIL];
};
const signInThroughPhoneFormDataSelector = (state) => {
  return state.auth.forms[FORM_NAMES.SIGN_IN_THROUGH_PHONE];
};
const userEmailForForgotPasswordFormSelector = (state) => state.auth.userEmailForForgotPasswordForm;
const reCaptchaLoadingStatusSelector = (state) => state.auth.isReCaptchaLoading;

export {
  isUserHasAccessSelector,
  preferredAuthTypeSelector,
  reCaptchaLoadingStatusSelector,
  signInThroughEmailFormDataSelector,
  signInThroughPhoneFormDataSelector,
  signUpThroughEmailFormDataSelector,
  signUpThroughPhoneFormDataSelector,
  userEmailForForgotPasswordFormSelector,
};
