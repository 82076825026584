import successImg from 'assets/images/subscription/success.png';
import colors from 'assets/styles/variables.scss';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';

import { userEmailSelector } from '../../../../../pages/lms/my-account/my-account.selectors';
import modalSelector from '../../../modal-container.selectors';
import CancelSubscriptionHeader from '../cancel-subscription-header';

import './styles.scss';

const CancelSubscriptionStep3 = () => {
  const dispatch = useDispatch();

  const { additionalData } = useSelector(modalSelector);

  const myEmail = useSelector(userEmailSelector);

  const handleBackToSubscriptionButtonClicked = () => {
    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  const date = dayjs(additionalData.endDate).format('MMMM D, YYYY');

  const descrWithEmail = myEmail && (
    <>
      Your email confirmation will be sent to <span>{myEmail}</span>
    </>
  );

  return (
    <div className="cancel-subscription-step-3">
      <CancelSubscriptionHeader title="Cancel subscription" />
      <img src={successImg} alt="success" className="cancel-subscription-step-3__image" />
      <p className="cancel-subscription-step-3__primary-content">
        Your subscription is <span>cancelled</span>
      </p>
      <p className="cancel-subscription-step-3__secondary-content">
        {descrWithEmail} You may continue to be the member of community until <span>{date}</span>
      </p>
      <Button
        content="Back to subscription"
        className="cancel-subscription-step-3__button"
        contentColor={colors.white}
        backgroundColor={colors['linear-2']}
        size={BUTTON_SIZES.MD}
        onClick={handleBackToSubscriptionButtonClicked}
      />
    </div>
  );
};

export default CancelSubscriptionStep3;
