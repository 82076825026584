import { get } from 'services/api';

const getBlockStreamByIdRequest = async (requestData) => {
  const url = `/block/streams/${requestData.streamID}?lang=${requestData.language}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export default getBlockStreamByIdRequest;
