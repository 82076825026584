import PropTypes from 'prop-types';
import React from 'react';
import { Link as ReactRouterDomLink } from 'react-router-dom';

import LINK_TYPES from './link.constants';

import './styles.scss';

const Link = ({ href, content, type, isNeedToOpenInNewTab, handleClick }) => {
  return (
    <ReactRouterDomLink
      className={`link--${type}`}
      to={href}
      target={isNeedToOpenInNewTab ? '_blank' : '_self'}
      onClick={handleClick}
    >
      {content}
    </ReactRouterDomLink>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  content: PropTypes.oneOfType(
    [PropTypes.arrayOf(PropTypes.node), PropTypes.node],
    PropTypes.string,
  ).isRequired,
  isNeedToOpenInNewTab: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(LINK_TYPES)),
  handleClick: PropTypes.func,
};

Link.defaultProps = {
  type: LINK_TYPES.PRIMARY,
  isNeedToOpenInNewTab: false,
  handleClick: () => {},
};

export default Link;
