/* eslint-disable no-alert */
import 'dayjs/locale/uk';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import LANGUAGES_LIST from '../../constants/localization/languages.list';
import { useOnClickOutside } from '../../hooks';
import { setInterfaceLanguage } from '../../store/settings/settings.slice';

import './styles.scss';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const [isOpened, setIsOpened] = useState(false);
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const handleClickOutside = () => {
    if (isOpened) {
      setIsOpened(false);
    }
  };

  useOnClickOutside(wrapperRef, handleClickOutside);

  const handleLanguageChange = (language) => {
    i18n.changeLanguage(language.toLowerCase());
    dispatch(setInterfaceLanguage(language.toLowerCase()));
    localStorage.setItem('lang', language.toLowerCase());
    setIsOpened(!isOpened);
  };

  const handleSelectedLanguageClick = () => {
    setIsOpened(!isOpened);
  };

  return (
    <div className={`language-switcher${isOpened ? '--opened' : ''}`} ref={wrapperRef}>
      <Button
        onClick={handleSelectedLanguageClick}
        size={BUTTON_SIZES.ICON}
        leftIcon={<SvgIcon type={`${i18n.language}-square`} />}
      />
      <ul className="language-switcher__list">
        {Object.values(LANGUAGES_LIST)
          .filter((item) => item.toLowerCase() !== i18n.language)
          .map((language) => {
            return (
              <li key={language} className="language-switcher__list__item">
                <Button
                  onClick={() => handleLanguageChange(language)}
                  size={BUTTON_SIZES.ICON}
                  leftIcon={<SvgIcon type={`${language.toLowerCase()}-square`} />}
                />
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default LanguageSwitcher;
