import { useContext } from 'react';

import { RecorderAudioContext } from './recorder-audio-context';

export const useRecorderAudioContext = () => {
  const ctx = useContext(RecorderAudioContext);

  if (!ctx) {
    throw new Error('RecorderAudioContext must be inside a Provider with a value');
  }

  return ctx;
};
