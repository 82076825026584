const ATTACHMENT_ITEM_TYPE = {
  PREVIEW: 'preview',
  EDITABLE: 'editable',
};

const ATTACHMENT_CONTENT_TYPE = {
  MEDIA_SERVER: 'media-server',
  LOCAL: 'local',
};

const ACCEPTABLE_IMAGE_FORMATS = ['.gif', '.jpeg', '.jpg', '.png', '.webp'];
const ACCEPTABLE_FILE_FORMATS = ['.pdf'];
const ACCEPTABLE_VIDEO_FORMATS = ['.avi', '.mp4', '.3gp', '.mpeg', '.mov', '.flv'];

export {
  ACCEPTABLE_FILE_FORMATS,
  ACCEPTABLE_IMAGE_FORMATS,
  ACCEPTABLE_VIDEO_FORMATS,
  ATTACHMENT_CONTENT_TYPE,
  ATTACHMENT_ITEM_TYPE,
};
