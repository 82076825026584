import React from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createPaymentIntent, enableCourseTrial, getPaymentConfig } from 'store/actions';

import { PRODUCT_TYPES } from '../../../../../constants/payment';
import { selectPaymentLoading } from '../../../../../store/selectors';
import Button from '../../../../ui-core/button';
import { BUTTON_SIZES } from '../../../../ui-core/button/button.constants';
import SvgIcon from '../../../../ui-core/svg-icon';
import MODAL_TYPES from '../../../modal-container.constants';
import { setModal } from '../../../modal-container.slice';

import colors from '../../../../../assets/styles/variables.scss';
import './styles.scss';


export const Footer = (props) => {
  const {
    price,
    durationName,
    id,
    orderType,
    subscriptionName,
    isTrialEnabled,
    isSubscription,
    isTrial,
    ...restProps
  } = props;
  const dispatch = useDispatch();

  const isLoadingPayment = useSelector(selectPaymentLoading);

  const { t } = useTranslation();

  const getStripe = async () => {
    try {
      const key = await dispatch(getPaymentConfig()).unwrap();
      const stripe = await loadStripe(key);
      return stripe;
    } catch (e) {
      throw e;
    }
  };

  const secretCommunityFlow = async () => {
    if (!isSubscription) {
      const data = await dispatch(createPaymentIntent({ priceCommunityId: id })).unwrap();
      return data.clientSecret;
    }

    return null;
  };
  const showModalResult = (result) => {
    return dispatch(
      setModal({
        isModalVisible: true,
        modalType: MODAL_TYPES.PAYMENT_RESULT,
        additionalData: {
          price,
          durationName,
          id,
          orderType,
          subscriptionName,
          isTrialEnabled,
          isSubscription,
          isTrial,
          resultType: result,
          ...restProps,
        },
      }),
    );
  };
  const secretCourseFlow = async () => {
    if (isTrial) {
      try {
        await dispatch(enableCourseTrial({ id: restProps?.courseId, priceCourseId: id })).unwrap();
        showModalResult('success');
      } catch (e) {
        showModalResult('failure');
      }

      return null;
    }

    const data = await dispatch(createPaymentIntent({ priceCourseId: id })).unwrap();
    return data.clientSecret;
  };

  const getSecret = async () => {
    try {
      if (orderType === PRODUCT_TYPES.COMMUNITY) {
        return await secretCommunityFlow();
      }

      if (orderType === PRODUCT_TYPES.COURSE) {
        return await secretCourseFlow();
      }

      return null;
    } catch (e) {
      throw e;
    }
  };

  const handleClickPayment = async () => {
    try {
      const stripe = await getStripe();
      const clientSecret = await getSecret();

      if (orderType === PRODUCT_TYPES.COURSE && isTrial) return;

      dispatch(
        setModal({
          isModalVisible: true,
          modalType: MODAL_TYPES['STRIPE-MODAL'],
          additionalData: {
            stripe,
            clientSecret,
            subscriptionName,
            price,
            id,
            orderType,
            isTrialEnabled,
            isTrial,
            isSubscription,
            ...restProps,
          },
        }),
      );
    } catch (e) {
      throw e;
    }
  };

  const handleClickCancel = () => {
    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  return (
    <div className="payment-modal-footer">
      {!isTrial && (
        <div className="payment-modal-footer-amount-wrapper">
          <h4 className="payment-modal-footer-amount-title">{t('modals.payments.total-amount')}</h4>
          <span className="payment-modal-footer-amount-price">
            {price}${durationName && `/ ${durationName}`}
          </span>
        </div>
      )}

      <div className="payment-modal-footer-btn-wrapper">
        {!isTrial && (
          <button
            disabled={isLoadingPayment}
            className="payment-modal-footer-btn"
            onClick={handleClickPayment}
          >
            <span className="payment-modal-footer-btn-label">
              {t('modals.payments.methods.payment-card')}
            </span>
            <span className="payment-modal-footer-btn-icons">
              <SvgIcon type="mastercard-gray" />
              <SvgIcon type="visa-gray" />
            </span>
          </button>
        )}

        {isTrial && (
          <>
            <Button
              content="Try it free"
              size={BUTTON_SIZES.MD}
              contentColor={colors.white}
              backgroundColor={colors['linear-3']}
              onClick={handleClickPayment}
            />
            <Button
              content="No thanks"
              onClick={handleClickCancel}
              size={BUTTON_SIZES.MD}
              contentColor={colors.white}
            />
          </>
        )}
      </div>
    </div>
  );
};
