import { getInterestsCategoriesOperation, setUserNameAndPhoneOperation } from './onboarding.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRequiredStepsCompleted: false,
  currentStep: 1,
  interestsCategories: [],
  forms: {
    firstStepFormData: {
      phoneNumber: '',
      otp: '',
      isOTPFieldVisible: false,
    },
  },
  error: '',
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setIsRequiredStepCompleted(state, { payload }) {
      state.isRequiredStepsCompleted = payload;
    },
    setCurrentStep(state, { payload }) {
      state.currentStep = payload;
    },
    setIsOTPFieldVisible(state, { payload }) {
      state.forms.firstStepFormData.isOTPFieldVisible = payload;
    },
    saveFormData(state, { payload }) {
      state.forms.firstStepFormData = {
        ...state.forms.firstStepFormData,
        ...payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getInterestsCategoriesOperation.fulfilled, (state, { payload }) => {
        state.interestsCategories = payload;
        state.error = '';
      })
      .addCase(getInterestsCategoriesOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(setUserNameAndPhoneOperation.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export const { setIsRequiredStepCompleted, setCurrentStep, setIsOTPFieldVisible, saveFormData } =
  onboardingSlice.actions;

export default onboardingSlice.reducer;
