import React from 'react';

import { Actions } from './Actions';
import { Header } from './Header';

import './styles.scss';

export const SuccessPaymentModal = () => {
  return (
    <div className="success-payment-modal">
      <Header />
      <Actions />
    </div>
  );
};
