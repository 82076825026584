import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { asHtml } from 'utils';

import { For } from '../../../../ui-core';

import './styles.scss';

const getTrialDuration = (durations) => {
  if (durations.trialDayDuration && durations.trialDayDuration > 0) {
    return `${durations.trialDayDuration} ${
      durations.trialDayDuration > 1 ? 'days' : 'day'
    } free trial`;
  }
  return '';
};

const getStartDateAfterTrial = (durations, t) => {
  if (durations.trialDayDuration && durations.trialDayDuration > 0) {
    const date = dayjs(new Date()).add(durations.trialDayDuration, 'day').format('MMMM DD, YYYY');
    return `${t('shared-content.starts-on')} ${date}`;
  }
  return '';
};

export const Body = (props) => {
  const { subscriptionName, badge, benefits, trialDayDuration, durationName, price, isTrial } =
    props;

  const { t } = useTranslation();

  const renderBenefits = (item) => {
    return (
      <li key={item} className="payment-modal-body-benefits-item">
        <span className="payment-modal-body-benefits-item-label">{item}</span>
      </li>
    );
  };

  return (
    <div className="payment-modal-body">
      <div className="payment-modal-body-section-about">
        <div className="payment-modal-body-about-wrapper">
          <h5 className="payment-modal-body-about-title">{asHtml(subscriptionName)}</h5>
          {badge && <span className="payment-modal-body-about-badge">{badge}</span>}
        </div>
      </div>
      <div className="payment-modal-body-section-benefits">
        <h5 className="payment-modal-body-benefits-title">{t('modals.payments.you-will-get')}:</h5>
        <ul className="payment-modal-body-benefits-list">
          <For data={benefits} render={renderBenefits} />
        </ul>
      </div>
      {isTrial && (
        <>
          <div className="payment-modal-body-section-trial">
            <div className="payment-modal-body-trial-info">
              <span className="payment-modal-body-trial-title">
                {getTrialDuration({ trialDayDuration })}
              </span>
              <span className="payment-modal-body-trial-subtitle">Starting today</span>
            </div>
            <div className="payment-modal-body-trial-info">
              <span className="payment-modal-body-trial-title">
                {price}$ {durationName && `/ ${durationName}`}
              </span>
              <span className="payment-modal-body-trial-subtitle">
                {getStartDateAfterTrial({ trialDayDuration }, t)}
              </span>
            </div>
          </div>

          <p className="payment-modal-body-description">
            No commitment. Cancel anytime in Profile at least a day before eeach renewal date. Plan
            automatically renews until canceled
          </p>
        </>
      )}
    </div>
  );
};
