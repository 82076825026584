import colors from 'assets/styles/variables.scss';
import { useDispatch, useSelector } from 'react-redux';

import MODAL_TYPES from 'components/modal-container/modal-container.constants';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';

import modalSelector from '../../../modal-container.selectors';
import CancelSubscriptionHeader from '../cancel-subscription-header';

import './styles.scss';

const CancelSubscriptionStep1 = () => {
  const dispatch = useDispatch();

  const { additionalData } = useSelector(modalSelector);

  const handleBackToSubscriptionButtonClicked = () => {
    dispatch(
      setModal({
        modalType: MODAL_TYPES['CANCEL-SUBSCRIPTION-STEP-2'],
        isModalVisible: true,
        additionalData,
      }),
    );
  };

  return (
    <div className="cancel-subscription-step-1">
      <CancelSubscriptionHeader title="Cancel subscription" />

      <p className="cancel-subscription-step-1__primary-content">
        It’s easy to cancel your subscription
      </p>
      <p className="cancel-subscription-step-1__secondary-content">
        We liked having you as a member and hope you`ll be back soon. Here you can you can cancel
        your subscription.
      </p>
      <ul>
        <li className="cancel-subscription-step-1__list-item">
          Cancellation will be effective at the period of current billing period on November 14,
          2023.
        </li>
        <li className="cancel-subscription-step-1__list-item">
          Come back at any time. If you restart your plan within 10 months, your profile, favourites
          and account details will still be waiting for you.
        </li>
        <li className="cancel-subscription-step-1__list-item">
          You`re always welcome back. Finish your cancellation below.
        </li>
      </ul>
      <Button
        content="Continue"
        className="cancel-subscription-step-1__button"
        contentColor={colors.white}
        backgroundColor={colors['linear-2']}
        size={BUTTON_SIZES.MD}
        onClick={handleBackToSubscriptionButtonClicked}
      />
    </div>
  );
};

export default CancelSubscriptionStep1;
