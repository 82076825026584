import { CHANNEL_STATUSES } from '../communication-items.constants';

const adaptPayloadForSocketOnDeleteChannel = (
  { title, description, type, id },
  activeCategoryID,
) => {
  return {
    categoryId: activeCategoryID,
    status: CHANNEL_STATUSES.CLOSED.toUpperCase(),
    communicationItemId: id,
    title,
    description,
    type,
  };
};

export default adaptPayloadForSocketOnDeleteChannel;
