import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import React from 'react';

import { Radio } from '../../../../../ui-core';
import { PAYMENT_VARIANT } from '../../_constants';
import { StripeField } from '../stripe-field';

import './styles.scss';

export const FormVariant = (props) => {
  const { name, onChange, checked, isSubscription } = props;

  const handleChange = () => {
    onChange({
      id: PAYMENT_VARIANT.NEW_CARD,
      type: PAYMENT_VARIANT.NEW_CARD,
    });
  };
  return (
    <div className="stripe-modal-form-variant">
      {isSubscription && (
        <div className="stripe-modal-form-variant-radio-wrapper">
          <Radio checked={checked} id="credit-card-variant" name={name} onChange={handleChange}>
            Credit card
          </Radio>
        </div>
      )}

      <div className="stripe-modal-form-variant-card-number-wrapper">
        <StripeField>
          <CardNumberElement />
        </StripeField>
      </div>
      <div className="stripe-modal-form-variant-other-field-wrapper">
        <StripeField>
          <CardExpiryElement />
        </StripeField>
        <StripeField>
          <CardCvcElement />
        </StripeField>
      </div>
    </div>
  );
};
