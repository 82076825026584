// import { BulletList } from '@tiptap/extension-bullet-list';
import { Link } from '@tiptap/extension-link';
// import { ListItem } from '@tiptap/extension-list-item';
import Mention from '@tiptap/extension-mention';
// import { OrderedList } from '@tiptap/extension-ordered-list';
import { Placeholder } from '@tiptap/extension-placeholder';
import { Underline } from '@tiptap/extension-underline';
import { EditorContent, useEditor } from '@tiptap/react';
import { StarterKit } from '@tiptap/starter-kit';
import cl from 'classnames';
import React, { useEffect } from 'react';

import { useClassName } from '../../../../hooks/use-class-name';
import suggestion from '../mentions/suggestion';

import { MenuBar } from './menu-bar';

import './styles.scss';

export const RichTextEditor = (props) => {
  const {
    editorOptions,
    slotAfter,
    slotBefore,
    innerRef,
    onChange,
    content,
    editorRef,
    placeholder = 'Your message...',
    className,
  } = props;

  const { rootClassName, appendClass } = useClassName('rich-text-editor', 'atom');

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link.configure({
        openOnClick: false,
      }),
      // BulletList.configure({
      //   keepMarks: true,
      // }),
      // OrderedList.configure({
      //   keepMarks: true,
      // }),
      // ListItem.configure(),
      Placeholder.configure({
        placeholder,
      }),
      Mention.configure({
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
        },
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
      }),
    ],
    content: content || '',
    onUpdate: (params) => {
      const html = params.editor.getHTML();
      onChange(html);
    },
    ...editorOptions,
  });

  useEffect(() => {
    if (editor) {
      editorRef.current = editor;
    }
  }, [editor]);

  const rootClasses = cl(rootClassName, className);

  return (
    <div className={rootClasses}>
      {editor && <MenuBar editor={editor} />}
      {slotBefore}
      <EditorContent className={appendClass('-content')} ref={innerRef} editor={editor} />
      {slotAfter}
    </div>
  );
};
