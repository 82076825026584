import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { getDashboardBlocksRequest } from 'services/requests/blocks';

import { setLoaderVisibility } from 'components/loader/loader.slice';

const getDashboardBlocksOperation = createAsyncThunk(
  'dashboard/GET_DASHBOARD_BLOCKS',
  async (requestData, { rejectWithValue }) => {
    try {
      const dashboardBlocks = await getDashboardBlocksRequest(requestData);

      return dashboardBlocks.block;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getDashboardPageOperation = createAsyncThunk(
  'dashboard/GET_DASHBOARD_PAGE',
  async (requestData, { dispatch, getState }) => {
    const isGlobalLoaderVisible = getState().loader.isVisible;

    if (!isGlobalLoaderVisible) {
      dispatch(setLoaderVisibility(true));
    }

    await Promise.all([dispatch(getDashboardBlocksOperation(requestData))]);

    await dispatch(setLoaderVisibility(false));
  },
);

export { getDashboardBlocksOperation, getDashboardPageOperation };
