import { createSlice } from '@reduxjs/toolkit';

import {
  readAllNotifications,
  readNotificationById,
  receiveNewNotification,
  receiveUnreadNotifications,
} from '../actions';

const initialState = {
  notifications: [],
  pagination: null,
  isLoading: false,
  isLoadingReadAll: false,
};

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(receiveUnreadNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(receiveUnreadNotifications.fulfilled, (state, { payload }) => {
        state.notifications = payload.notifications;
        state.pagination = payload.pagination;
        state.isLoading = false;
      })
      .addCase(receiveUnreadNotifications.rejected, (state) => {
        state.isLoading = false;
      });

    builder
      .addCase(receiveNewNotification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(receiveNewNotification.fulfilled, (state, { payload }) => {
        state.notifications = [payload.notification, ...state.notifications];
        state.isLoading = false;
      })
      .addCase(receiveNewNotification.rejected, (state) => {
        state.isLoading = false;
      });

    builder.addCase(readNotificationById.fulfilled, (state, { payload }) => {
      state.notifications = state.notifications.filter((n) => n.id !== payload.id);
    });

    builder
      .addCase(readAllNotifications.pending, (state) => {
        state.isLoadingReadAll = true;
      })
      .addCase(readAllNotifications.fulfilled, (state) => {
        state.notifications = [];
        state.isLoadingReadAll = false;
      })
      .addCase(readAllNotifications.rejected, (state) => {
        state.isLoadingReadAll = false;
      });
  },
});

export const notificationReducer = notificationSlice.reducer;
