import cl from 'classnames';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import SvgIcon from '../../../ui-core/svg-icon';

import './styles.scss';

export const CircleVideoRecording = forwardRef((props, ref) => {
  const { isRecording, onStop, className, maxRecordTime, onComplete } = props;

  const resizer = useRef(null);
  const videoContainerRef = useRef(null);

  const [, setSqSize] = useState(0);

  const rootClasses = cl(
    'circle-video-recording',
    {
      'circle-video-recording--active': isRecording,
      'circle-video-recording--inactive': !isRecording,
    },
    className,
  );

  useEffect(() => {
    if (!resizer.current) return;

    resizer.current = new ResizeObserver((entries) => {
      const el = entries[0].contentRect.width;

      setSqSize(() => el);
    });

    if (videoContainerRef.current) {
      resizer.current.observe(videoContainerRef.current);
    }

    return () => {
      if (resizer.current) {
        resizer.current.destroy?.();
      }
    };
  }, []);

  const handleComplete = () => {
    onComplete();
  };

  return (
    <div className={rootClasses}>
      <div className="circle-video-recording-wrapper">
        <div ref={videoContainerRef} className="circle-video-recording-player-container">
          <video ref={ref} className="circle-video-recording-player" autoPlay />
          <div className="circle-video-recording-countdown">
            {isRecording && (
              <CountdownCircleTimer
                className="circle-video-recording-countdown-component"
                isPlaying={isRecording}
                duration={maxRecordTime}
                colors="#ffffff"
                stroke="transparent"
                strokeWidth={5}
                trailColor="transparent"
                onComplete={handleComplete}
              />
            )}
          </div>
        </div>
        <button onClick={() => onStop(true)} className="circle-video-recording-close-btn">
          <SvgIcon type="send-inner" />
        </button>
      </div>
    </div>
  );
});
