import { createSlice } from '@reduxjs/toolkit';

import {
  connectedToCommunitySocket,
  connectedToGlobalSocket,
  connectedToPrivateChatSocket,
  disconnectCommunitySocket,
  disconnectGlobalSocket,
  disconnectPrivateChatSocket,
  downCommunitySocket,
  downGlobalSocket,
  downPrivateChatSocket,
} from '../actions';

const initialState = {
  globalSocketConnected: false,
  globalSocketError: null,

  communitySocketConnected: false,
  communityError: null,

  privateChatConnected: false,
  privateChatError: null,
};

const socketConnectionSlice = createSlice({
  name: 'socket-connect',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectedToGlobalSocket, (state) => {
        state.globalSocketConnected = true;
        state.globalSocketError = null;
      })
      .addCase(disconnectGlobalSocket, (state) => {
        state.globalSocketConnected = false;
        state.globalSocketError = null;
      })
      .addCase(downGlobalSocket, (state, { payload }) => {
        state.globalSocketConnected = false;
        if (payload.error) {
          state.globalSocketError = payload.error;
        }
      });

    builder
      .addCase(connectedToCommunitySocket, (state) => {
        state.communitySocketConnected = true;
        state.communityError = null;
      })
      .addCase(disconnectCommunitySocket, (state) => {
        state.communitySocketConnected = false;
        state.communityError = null;
      })
      .addCase(downCommunitySocket, (state, { payload }) => {
        state.communitySocketConnected = false;
        if (payload.error) {
          state.communityError = payload.error;
        }
      });

    builder
      .addCase(connectedToPrivateChatSocket, (state) => {
        state.privateChatConnected = true;
        state.privateChatError = null;
      })
      .addCase(disconnectPrivateChatSocket, (state) => {
        state.privateChatConnected = false;
        state.privateChatError = null;
      })
      .addCase(downPrivateChatSocket, (state, { payload }) => {
        state.privateChatConnected = false;
        if (payload.error) {
          state.privateChatError = payload.error;
        }
      });
  },
});

export const socketConnectionReducer = socketConnectionSlice.reducer;
