import cl from 'classnames';
import React, { useRef } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';

import { useRecorderAudioContext } from '../../../context';
import SvgIcon from '../../ui-core/svg-icon';

import './styles.scss';

export const AudioAction = (props) => {
  const { maxRecordTime = 10 } = props;

  const wrapperRef = useRef(null);

  const { startRecording, stopRecording, clearBlobUrl, isRecording } = useRecorderAudioContext();

  const rootClasses = cl('audio-action', {
    'audio-action--active': isRecording,
    'audio-action--inactive': !isRecording,
  });

  const handleClickAction = (event) => {
    event.stopPropagation();
    startRecording();
  };

  const handleComplete = () => {
    stopRecording(true);
    clearBlobUrl();
  };

  return (
    <div ref={wrapperRef} className={rootClasses}>
      <button type="button" className="audio-action-btn" onClick={handleClickAction}>
        <span>
          <SvgIcon type="micro" />
        </span>
      </button>

      <div className="audio-action-timer">
        {isRecording && (
          <CountdownCircleTimer
            isPlaying
            duration={maxRecordTime}
            colors="#ffffff"
            stroke="transparent"
            strokeWidth={5}
            trailColor="transparent"
            onComplete={handleComplete}
            size={0}
          />
        )}
      </div>

      {isRecording && (
        <button onClick={handleComplete} type="button" className="audio-action-send-btn">
          <SvgIcon type="send-inner" />
        </button>
      )}
    </div>
  );
};
