import PropTypes from 'prop-types';

import SvgIcon from 'components/ui-core/svg-icon';

import './styles.scss';

const EmptyContent = ({ iconType, title, description }) => {
  const generateIconLayout = () => {
    if (typeof iconType === 'string') {
      return <SvgIcon type={iconType} />;
    }

    return iconType;
  };

  return (
    <div className="empty-content">
      {iconType && generateIconLayout()}
      <p className="empty-content__title">{title}</p>
      <p className="empty-content__description">{description}</p>
    </div>
  );
};

EmptyContent.propTypes = {
  iconType: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

EmptyContent.defaultProps = {
  iconType: '',
};

export default EmptyContent;
