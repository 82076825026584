import { toast } from 'react-toastify';

import i18n from 'localization/localization.config';
import { setAccountInfoData } from 'pages/lms/my-account/my-account.slice';
import { setCurrentStep, setIsRequiredStepCompleted } from 'pages/onboarding/onboarding.slice';
import {
  changeUserPasswordRequest,
  getReCaptchaTokenRequest,
  sendOTPCodeForForgotPasswordFormRequest,
  signInRequest,
  signUpRequest,
  verifyOTPRequest,
} from 'services/requests/auth';

import { createAsyncThunk } from '@reduxjs/toolkit';

const sendSignUpThroughEmailOperation = createAsyncThunk(
  'auth/SEND_SIGN_UP_THROUGH_EMAIL_REQUEST',
  async (requestData, { rejectWithValue }) => {
    try {
      const captcha = await getReCaptchaTokenRequest();
      const response = await signUpRequest({ ...requestData, captcha });

      return response;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });
      return rejectWithValue(error);
    }
  },
);

const sendSignUpThroughPhoneOperation = createAsyncThunk(
  'auth/SEND_SIGN_UP_THROUGH_PHONE_REQUEST',
  async (requestData, { rejectWithValue }) => {
    try {
      const captcha = await getReCaptchaTokenRequest();
      const response = await signUpRequest({ ...requestData, captcha });

      return response;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });
      return rejectWithValue(error);
    }
  },
);

const sendSignInThroughEmailOperation = createAsyncThunk(
  'auth/SEND_SIGN_IN_THROUGH_EMAIL_REQUEST',
  async (requestData, { rejectWithValue }) => {
    try {
      const captcha = await getReCaptchaTokenRequest();
      const response = await signInRequest({ ...requestData, captcha });

      return response;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });
      return rejectWithValue(error);
    }
  },
);

const sendSignInThroughPhoneOperation = createAsyncThunk(
  'auth/SEND_SIGN_IN_THROUGH_PHONE_REQUEST',
  async (requestData, { rejectWithValue }) => {
    try {
      const captcha = await getReCaptchaTokenRequest();
      const response = await signInRequest({ ...requestData, captcha });

      return response;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });
      return rejectWithValue(error);
    }
  },
);

const sendOTPCodeForForgotPasswordFormOperation = createAsyncThunk(
  'auth/SEND_OTP_CODE_FOR_FORGOT_PASSWORD_FORM',
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await sendOTPCodeForForgotPasswordFormRequest(requestData);

      return response;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });
      return rejectWithValue(error);
    }
  },
);

const changeUserPasswordOperation = createAsyncThunk(
  'auth/CHANGE_USER_PASSWORD',
  async (requestData, { rejectWithValue }) => {
    try {
      await changeUserPasswordRequest(requestData);
      toast.success(i18n.t('new-password.successfully-changed'));
      return true;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });
      return rejectWithValue(error);
    }
  },
);

const verifyOTPOperation = createAsyncThunk(
  'auth/VERIFY_OTP',
  async (requestData, { rejectWithValue, dispatch }) => {
    try {
      const response = await verifyOTPRequest(requestData);

      // checking if user has name and we should show him onboarding
      if (response.user.name) {
        dispatch(setIsRequiredStepCompleted(true));
        dispatch(setCurrentStep(2));
      }

      dispatch(setAccountInfoData(response.user));

      return response;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });
      return rejectWithValue(error);
    }
  },
);

export {
  changeUserPasswordOperation,
  sendOTPCodeForForgotPasswordFormOperation,
  sendSignInThroughEmailOperation,
  sendSignInThroughPhoneOperation,
  sendSignUpThroughEmailOperation,
  sendSignUpThroughPhoneOperation,
  verifyOTPOperation,
};
