import React, { useCallback, useMemo } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ROUTER_BOOK from '../../../../../constants/routes/routes-book';
import Button from '../../../../ui-core/button';
import { BUTTON_SIZES } from '../../../../ui-core/button/button.constants';
import MODAL_TYPES from '../../../modal-container.constants';
import { setModal } from '../../../modal-container.slice';

import './styles.scss';
import colors from 'assets/styles/variables.scss';

export const Actions = (props) => {
  const { resultType, isSubscription, courseGroupId, ...restProps } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const handleClickBack = () => {
    dispatch(setModal({ isModalVisible: false }));
  };

  const handleClickTryAgain = () => {
    dispatch(
      setModal({
        isModalVisible: true,
        modalType: MODAL_TYPES.PAYMENTS,
        additionalData: restProps,
      }),
    );
  };

  const btnLabel = useMemo(() => {
    if (resultType === 'success') {
      if (isSubscription) {
        return 'To Community';
      }

      return 'Start course';
    }

    return 'Try again';
  }, [isSubscription, resultType]);

  const navigateToCommunity = () => {
    navigate(`${pathname}/community`);

    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  const navigateToCourse = () => {
    const path = courseGroupId
      ? `${ROUTER_BOOK.MY_WORKSPACE.GENERAL}/${ROUTER_BOOK.MY_WORKSPACE.COURSES}/${courseGroupId}`
      : `${ROUTER_BOOK.MY_WORKSPACE.GENERAL}/${ROUTER_BOOK.MY_WORKSPACE.COURSES}`;

    navigate(path, {
      replace: true,
    });
    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  const btnOnClick = useCallback(() => {
    if (resultType === 'success') {
      if (isSubscription) {
        return navigateToCommunity();
      }

      return navigateToCourse();
    }

    return handleClickTryAgain();
  }, [resultType, isSubscription]);

  return (
    <div className="payment-result-modal-actions">
      <Button
        content="Back to main"
        size={BUTTON_SIZES.MD}
        contentColor={colors.white}
        backgroundColor={colors['neutral-gray-700']}
        onClick={handleClickBack}
      />
      <a className="payment-result-modal-actions__support-button" href="mailto: support@comms.fun">
        Contact support
      </a>
      <Button
        content={btnLabel}
        size={BUTTON_SIZES.MD}
        contentColor={colors.white}
        backgroundColor={colors['linear-2']}
        onClick={btnOnClick}
      />
    </div>
  );
};
