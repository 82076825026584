import { memo } from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import LanguageSwitcher from 'components/language-switcher';
import Button from 'components/ui-core/button/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';
import UserControlsPanel from 'components/user-controls-panel';

import ROUTER_BOOK from 'constants/routes/routes-book';

import { isUserHasAccessSelector } from '../../pages/auth/auth.selectors';

import BurgerMenu from './components/burger-menu/burger-menu';
import { Notifications } from './components/notifications';
import HEADER_TYPES from './header.constants';

import './styles.scss';


const Header = memo((props) => {
  const { type } = props;

  const navigate = useNavigate();

  const isUserHasAccess = useSelector(isUserHasAccessSelector);

  switch (type) {
    case HEADER_TYPES.LANGUAGE_SWITCHER:
      return (
        <div className="header--language-switcher">
          <LanguageSwitcher />
        </div>
      );

    default:
      return (
        <div className="header--default">
          <Button
            leftIcon={<SvgIcon type="logo" />}
            size={BUTTON_SIZES.ICON}
            className="fit-content"
            onClick={() => navigate(ROUTER_BOOK.DASHBOARD)}
          />
          <div className="header--default__controls">
            {isUserHasAccess && <Notifications />}
            <BurgerMenu />
            <LanguageSwitcher />
            {isUserHasAccess && <UserControlsPanel />}
          </div>
        </div>
      );
  }
});

Header.propTypes = {
  type: PropTypes.oneOf(Object.values(HEADER_TYPES)),
};

Header.defaultProps = {
  type: HEADER_TYPES.DEFAULT,
};

export default Header;
