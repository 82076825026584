import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import SettingsModalContentSwitcher from 'components/settings-modal-content-switcher';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import { COMMUNICATION_ITEM_TYPE } from '../communication-items.constants';
import {
  CATEGORY_SETTINGS_OPTIONS_LIST,
  CHANNEL_SETTINGS_OPTIONS_LIST,
} from '../communication-items.forms';

import './styles.scss';

const CommunicationItemSettings = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    additionalData: { communicationItemType },
  } = useSelector(modalSelector);

  const generateConfigForSettingsModalContentSwitcher = () => {
    switch (communicationItemType) {
      case COMMUNICATION_ITEM_TYPE.CATEGORY:
        return CATEGORY_SETTINGS_OPTIONS_LIST;

      default:
        return CHANNEL_SETTINGS_OPTIONS_LIST;
    }
  };

  return (
    <div className="communication-item-settings">
      <div className="communication-item-settings__header">
        <p className="communication-item-settings__title">
          {t(`modals.communication-item-settings.${communicationItemType}.title`)}
        </p>
        <Button
          onClick={() => {
            dispatch(
              setModal({
                isModalVisible: false,
              }),
            );
          }}
          leftIcon={<SvgIcon type="cross-2" />}
          size={BUTTON_SIZES.ICON}
          className="fit-content"
        />
      </div>
      <SettingsModalContentSwitcher config={generateConfigForSettingsModalContentSwitcher()} />
    </div>
  );
};

export default CommunicationItemSettings;
