import { createSlice } from '@reduxjs/toolkit';

import { complexUpdateUnreadFlags } from '../actions/common.action';

const initialState = {
  hasUnreadPrivateChat: false,
  hasUnreadCommunity: false,
  unreadCommunityInfo: {},
};

const commonSlice = createSlice({
  name: 'common-socket',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(complexUpdateUnreadFlags.fulfilled, (state, { payload }) => {
      state.hasUnreadPrivateChat = payload.userHasUnreadPrivateChat;
      state.hasUnreadCommunity = payload.hasUnreadCommunity;
      state.unreadCommunityInfo = payload.userCommunityUnreadInfo;
    });
  },
});

export const commonReducer = commonSlice.reducer;
