import { useIsMobile } from 'hooks/index';
import { useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import Button from 'components/ui-core/button/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon/svg-icon';

import MobileSidebar from './components/mobile-sidebar/mobile-sidebar';

import './styles.scss';

const BurgerMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const nodeRef = useRef(null);
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsMenuVisible(false);
  }, [window.location.pathname]);

  if (!isMobile) {
    return null;
  }

  return (
    <>
      <Button
        leftIcon={<SvgIcon type="burger-menu" />}
        size={BUTTON_SIZES.ICON}
        onClick={() => setIsMenuVisible(!isMenuVisible)}
      />

      <CSSTransition
        in={isMenuVisible}
        nodeRef={nodeRef}
        timeout={100}
        classNames="menu-status"
        unmountOnExit
      >
        <div className="mobile-sidebar" ref={nodeRef}>
          <MobileSidebar handleCloseButtonClicked={() => setIsMenuVisible(false)} ref={nodeRef} />
        </div>
      </CSSTransition>
    </>
  );
};

export default BurgerMenu;
