import PropTypes from 'prop-types';

const TextArea = ({ label, name, placeholder, error, register, rows }) => {
  return (
    <div className="field-container">
      {label && (
        <label htmlFor={name} className="field__label">
          {label}
        </label>
      )}
      {error && <span className={`field__error${label ? '' : '--without-label'}`}>{error}</span>}
      <textarea
        id={name}
        rows={rows}
        className="field--text-area"
        style={{ resize: 'none' }}
        placeholder={placeholder}
        {...register(name)}
      />
    </div>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.func.isRequired,
  rows: PropTypes.number,
};

TextArea.defaultProps = {
  placeholder: '',
  label: '',
  error: '',
  rows: 5,
};

export default TextArea;
