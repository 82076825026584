import { memo } from 'react';

import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useTransitionStyles,
} from '@floating-ui/react';
import cl from 'classnames';
import { useToggle } from 'hooks/use-toggle';
import { useSelector } from 'react-redux';
import { selectHasNotifications } from 'store/selectors';

import SvgIcon from '../../../ui-core/svg-icon';

import { NotificationsPopup } from './notification-popup';

import s from './styles.module.scss';

export const Notifications = memo(() => {
  const [isOpen, toggleIsOpen] = useToggle();

  const hasNotifications = useSelector(selectHasNotifications);

  const { refs, context, floatingStyles } = useFloating({
    placement: 'bottom',
    open: isOpen,
    onOpenChange: toggleIsOpen,
    middleware: [offset(10), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const transitionStyles = useTransitionStyles(context, {
    initial: {
      opacity: 0,
      transform: 'translateX(200px)',
    },
    open: {
      opacity: 1,
      transform: `translateX(0)`,
    },
    close: {
      opacity: 0,
      transform: 'translateX(200px)',
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    ancestorScroll: true,
    bubbles: false,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);

  const referencesProps = getReferenceProps({
    ref: refs.setReference,
  });

  const floatingProps = getFloatingProps({
    ref: refs.setFloating,
    style: floatingStyles,
    className: s.notificationsPopoverContainer,
  });

  const triggerClasses = cl(s.notificationsTrigger, {
    [s.notificationsTriggerHasNotifications]: hasNotifications,
  });

  return (
    <>
      <div className={s.notifications}>
        <button className={triggerClasses} {...referencesProps}>
          <SvgIcon type="bell" />
        </button>
      </div>
      {transitionStyles.isMounted && (
        <FloatingPortal>
          <div {...floatingProps}>
            <NotificationsPopup toggleIsOpen={toggleIsOpen} style={transitionStyles.styles} />
          </div>
        </FloatingPortal>
      )}
    </>
  );
});
