/* eslint-disable jsx-a11y/no-static-element-interactions */

/* eslint-disable jsx-a11y/click-events-have-key-events */
import logoSquareImage from 'assets/images/vector/logo/logo.svg';
import PropTypes from 'prop-types';
import { LazyLoadImage as LazyLoad } from 'react-lazy-load-image-component';

import './styles.scss';

const LazyLoadImage = ({ imageSource, handleClick, className }) => {
  return (
    <div className={`lazy-load-image ${className}`} onClick={handleClick}>
      <LazyLoad
        alt={imageSource}
        effect="black-and-white"
        src={imageSource}
        placeholderSrc={logoSquareImage}
      />
    </div>
  );
};

LazyLoadImage.propTypes = {
  imageSource: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  className: PropTypes.string,
};

LazyLoadImage.defaultProps = {
  handleClick: () => {},
  className: '',
};

export default LazyLoadImage;
