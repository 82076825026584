const getCountryCode = async () => {
  const url = `https://coreapi.tech/api/geo/user?token=${process.env.REACT_APP_CORE_API_TOKEN}`;

  try {
    const response = await fetch(url);

    return response.json();
  } catch (err) {
    throw err;
  }
};

export { getCountryCode };
