import React, { Suspense, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import ErrorBoundary from 'components/error-boundary';
import Loader from 'components/loader/loader';
import ModalContainer from 'components/modal-container';
import ProtectedRoute from 'components/protected-route/protected-route';

import ROUTER_BOOK from 'constants/routes/routes-book';
import routesList from 'constants/routes/routes-list';

import CookiesBar from './components/cookies-bar';
import { useSocketManager } from './hooks/use-socket-manager';
import { isUserHasAccessSelector } from './pages/auth/auth.selectors';
import { socketService } from './services/socket';
import { selectGlobalSocketState } from './store/selectors';
import parseCookies from './utils/cookies';


const App = () => {
  const isUserHasAccess = useSelector(isUserHasAccessSelector);

  const { onMessage, onClose, onError, onOpen, onErrorAfterAllAttempt } = useSocketManager();
  const globalSocket = useSelector(selectGlobalSocketState);

  useEffect(() => {
    if (isUserHasAccess && !globalSocket.isConnected && !socketService.isConnected) {
      socketService.connect({
        onMessage,
        onClose,
        onError,
        onOpen,
        onErrorAfterAllAttempt,
        path: process.env.REACT_APP_SOCKET_URL,
        token: parseCookies().access || process.env.REACT_APP_COOCKIE_LOCAL_TOKEN,
      });
    }

    return () => {
      socketService.close();
    };
  }, [isUserHasAccess]);

  const generateRoutes = routesList.map((route) => {
    const { page: Page, path, isProtected, isAllowAccess = null } = route;
    return (
      <Route
        key={path}
        path={path}
        element={
          <>
            <ProtectedRoute
              isProtectedRoute={isProtected}
              path={path}
              isAllowAccess={isAllowAccess}
            >
              <ErrorBoundary>
                <Page />
              </ErrorBoundary>
              <ModalContainer />
            </ProtectedRoute>
          </>
        }
      />
    );
  });

  return (
    <BrowserRouter>
      <ToastContainer position="bottom-right" />
      <Loader />
      <Suspense fallback={<Loader isSuspense />}>
        {/*<SocketProvider>*/}
        <Routes>
          {generateRoutes}
          <Route path="/" element={<Navigate to={`${ROUTER_BOOK.DASHBOARD}`} replace />} />
          <Route path="*" element={<Navigate to={`${ROUTER_BOOK.NOT_FOUND}`} replace />} />
        </Routes>
        {/*</SocketProvider>*/}
      </Suspense>
      <CookiesBar />
      <span className="app-version">{process.env.REACT_APP_VERSION}</span>
    </BrowserRouter>
  );
};

export default App;
