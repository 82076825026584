import colors from 'assets/styles/variables.scss';
import { useOnClickOutside } from 'hooks';
import { myAccountSelector } from 'pages/lms/my-account/my-account.selectors';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { destroySession, persistor } from 'store/store';

import LazyLoadImage from 'components/lazy-load-image';
import SpinnerCircle from 'components/spinner-circle';
import Button from 'components/ui-core/button';
import BUTTON_TYPES, {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/ui-core/button/button.constants';

import ROUTER_BOOK from '../../constants/routes/routes-book';
import { getUserInfoOperation } from '../../pages/lms/my-account/my-account.thunks';
import { generateImagePathWithoutCache } from '../../utils/images';

import './styles.scss';

const UserControlsPanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [isSlideMenuVisible, setIsSlideMenuVisible] = useState(false);

  const { accountInfo, isLoading } = useSelector(myAccountSelector);
  const { i18n } = useTranslation();

  const ref = useRef();

  useOnClickOutside(ref, () => {
    if (isSlideMenuVisible) {
      setIsSlideMenuVisible(false);
    }
  });

  useEffect(() => {
    dispatch(getUserInfoOperation({ language: i18n.language }));
  }, [i18n.language]);

  if (!accountInfo) {
    return null;
  }

  const handleLogoutButtonClick = () => {
    dispatch(destroySession());
    localStorage.removeItem('persist:root');
    persistor.persist();
    navigate(ROUTER_BOOK.AUTH.LOGIN, { replace: true });
  };

  const profileBtnContent = useMemo(() => {
    if (isLoading) return <SpinnerCircle />;

    return (
      <LazyLoadImage
        imageSource={generateImagePathWithoutCache(accountInfo.image)}
        className="user-controls-panel__profile__avatar"
      />
    );
  }, [isLoading, accountInfo.image]);

  return (
    <div className="user-controls-panel" ref={ref}>
      <button
        className="user-controls-panel__profile-container"
        type="button"
        onClick={() => setIsSlideMenuVisible(!isSlideMenuVisible)}
      >
        {profileBtnContent}
      </button>

      <div className={`user-controls-panel__slide-menu${!isSlideMenuVisible ? '--hidden' : ''}`}>
        <div className="user-controls-panel__slide-menu__avatar">
          <LazyLoadImage
            imageSource={generateImagePathWithoutCache(accountInfo.image)}
            className="user-controls-panel__profile__avatar"
          />
        </div>
        <p className="user-controls-panel__slide-menu__name">{accountInfo.name}</p>

        <Button
          type={BUTTON_TYPES.BUTTON}
          content={t('shared-content.exit')}
          onClick={handleLogoutButtonClick}
          size={BUTTON_SIZES.SM}
          contentColor={colors.white}
          variant={BUTTON_VARIANTS.OUTLINE}
          backgroundColor={colors['secondary-purple-300']}
        />
      </div>
    </div>
  );
};

export default UserControlsPanel;
