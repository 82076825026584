import { createSlice } from '@reduxjs/toolkit';

import {
  connectToCommunityCategory,
  connectToCommunityCommunication,
  connectToCommunitySocket,
  receiveConnectToCommunity,
  receiveConnectToCommunityCategory,
  receiveConnectToCommunityCommunication,
} from '../actions/community-new.action';

const initialState = {
  isProcessingConnect: false,
  isConnectedFromNotification: false,
  connectedCommunityId: null,
  communityCategories: [],
  connectedCategory: {
    categoryId: null,
    categoryInfo: null,
    channels: [],
  },
  connectedChannel: {
    channelId: null,
    isLastMessagePresent: false,
    isFirstMessagePresent: false,
    isPinnedMessagesVisible: false,
    pinnedMessages: [],
    messages: [],
    channelInfo: null,
    mentions: {
      users: [
        { id: 'everyone', name: 'everyone', image: '' },
        { id: 'here', name: 'here', image: '' },
      ],
    },
  },

  isLoading: false,
  isLoadingCategory: false,
  isLoadingChannel: false,
  isLoadingMentions: false,
};

const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectToCommunitySocket, (state, { payload }) => {
        state.isLoadingCommunity = true;
        state.connectedCommunityId = payload.communityId;
        state.isProcessingConnect = true;
      })
      .addCase(receiveConnectToCommunity, (state, { payload }) => {
        state.isLoadingCommunity = false;
        state.communityCategories = payload;
        state.isProcessingConnect = false;
      });

    builder
      .addCase(connectToCommunityCategory, (state, { payload }) => {
        state.isLoadingCategory = true;

        const connectedCategory = state.communityCategories.find(
          (c) => c.id === payload.categoryId,
        );
        if (connectedCategory) {
          state.connectedCategory = {
            ...state.connectedCategory,
            categoryId: state.connectedCategory.id,
            categoryInfo: state.connectedCategory,
          };
        }
      })
      .addCase(receiveConnectToCommunityCategory, (state, { payload }) => {
        state.connectedCategory.channels = payload;
        state.isLoadingCategory = false;
      });

    builder
      .addCase(connectToCommunityCommunication, (state, { payload }) => {
        state.isLoadingChannel = true;

        // clear prev values for connected channel
        state.connectedChannel.pinnedMessages = [];
        state.connectedChannel.messages = [];
        state.connectedChannel.isPinnedMessagesVisible = false;

        state.connectedChannel.channelId = payload.communicationItemId;
      })
      .addCase(receiveConnectToCommunityCommunication, (state, { payload }) => {
        const { communicationItemId, messages, lastMessagePresent, firstMessagePresent } = payload;

        state.connectedChannel.messages = messages;
        if (messages.length === 0) {
          state.connectedChannel.isLastMessagePresent = true;
          state.connectedChannel.isFirstMessagePresent = true;
        } else {
          state.connectedChannel.isLastMessagePresent = lastMessagePresent;
          state.connectedChannel.isFirstMessagePresent = firstMessagePresent;
        }
        // set channel info from connected category channels
        state.connectedCategory.channels.forEach((channel) => {
          if (communicationItemId === channel.id) {
            state.connectedChannel.channelInfo = channel;
          }
        });

        state.isLoadingChannel = false;
      });
  },
});

export const communityReducer = communitySlice.reducer;
