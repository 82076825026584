import colors from 'assets/styles/variables.scss';
import cl from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const ExpandedTextArea = (props) => {
  const { label, name, placeholder, error, register, className } = props;

  const fieldRef = useRef();

  const autoResize = ({ target }) => {
    target.style.height = 'auto';
    target.style.height = `${target.scrollHeight}px`;
  };

  useEffect(() => {
    const textarea = document.getElementById(name);

    textarea.addEventListener('focus', autoResize, false);
    textarea.addEventListener('input', autoResize, false);

    return () => {
      textarea.removeEventListener('focus', autoResize, false);
      textarea.removeEventListener('input', autoResize, false);
    };
  }, [name]);

  const rootClasses = cl('field-container', className);

  return (
    <div className={rootClasses} ref={fieldRef}>
      {label && (
        <label htmlFor={name} className="field__label">
          {label}
        </label>
      )}
      {error && <span className={`field__error${label ? '' : '--without-label'}`}>{error}</span>}
      <textarea
        id={name}
        rows={1}
        className="field--expanded-text-area"
        placeholder={placeholder}
        {...register(name)}
        onFocus={() => {
          fieldRef.current.parentElement.style.borderColor = colors['secondary-purple-300'];
        }}
        onBlur={() => {
          fieldRef.current.parentElement.style.borderColor = colors['neutral-gray-600'];
        }}
        style={{ resize: 'none' }}
      />
    </div>
  );
};

ExpandedTextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  register: PropTypes.func.isRequired,
};

ExpandedTextArea.defaultProps = {
  placeholder: '',
  label: '',
  error: '',
};

export default ExpandedTextArea;
