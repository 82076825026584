import paymentSuccess from 'assets/lottie-animations/payment_success.json';
import Lottie from 'lottie-react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import './styles.scss';

export const Header = () => {
  const { t } = useTranslation();
  const animation = paymentSuccess;

  const title = t('modals.success-payment-modal.header.title');

  return (
    <div className="success-payment-modal-header">
      <div className="success-payment-modal-header-animation">
        <Lottie animationData={animation} loop={false} />
      </div>
      <div className="success-payment-modal-header-info-wrapper">
        <h4 className="success-payment-modal-header-info-title">{title.toUpperCase()}</h4>
      </div>
    </div>
  );
};
