import { lazy } from 'react';

const Auth = lazy(() => import('./auth/auth'));

const Dashboard = lazy(() => import('./lms/dashboard'));
const MyAccount = lazy(() => import('./lms/my-account'));
const MyWorkspace = lazy(() => import('./lms/my-workspace'));
const NotFound = lazy(() => import('./not-found'));
const Onboarding = lazy(() => import('./onboarding'));
const PaymentStatus = lazy(() => import('./lms/payment-status'));
const CommunityGroupChatsDashboard = lazy(() => import('./community/group-chatting/dashboard'));
const CommunityGroupChatsCategories = lazy(() => import('./community/group-chatting/categories'));
const CommunityMessages = lazy(() => import('./community/messages'));
const Calendar = lazy(() => import('./lms/calendar'));
const CommunityBrands = lazy(() => import('./community/brands'));
const CommunityBrand = lazy(() => import('./community/brand'));
const CommunityCourseLanding = lazy(() => import('./community/course-landing'));
const ReferralProgram = lazy(() => import('./lms/referral-program'));
const PrivacyPolicy = lazy(() => import('./access-rules/privacy-policy'));

const TermsAndConditionals = lazy(() => import('./access-rules/terms-and-conditionals'));

export {
  Auth,
  Calendar,
  CommunityBrand,
  CommunityBrands,
  CommunityCourseLanding,
  CommunityGroupChatsCategories,
  CommunityGroupChatsDashboard,
  CommunityMessages,
  Dashboard,
  MyAccount,
  MyWorkspace,
  NotFound,
  Onboarding,
  PaymentStatus,
  PrivacyPolicy,
  ReferralProgram,
  TermsAndConditionals,
};
