import React from 'react';
import { useSelector } from 'react-redux';

import modalSelector from '../../modal-container.selectors';

import { Actions } from './Actions';
import { Body } from './Body';
import { Header } from './Header';

import './styles.scss';

export const PaymentResultModal = () => {
  const { additionalData } = useSelector(modalSelector);

  const { resultType, subscriptionName, price } = additionalData;

  return (
    <div className="payment-result-modal">
      <Header resultType={resultType} />
      <Body subscriptionName={subscriptionName} price={price} />
      <Actions {...additionalData} />
    </div>
  );
};
