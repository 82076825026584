import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import getBrandRequest from 'services/requests/brand';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getBrandOperation = createAsyncThunk('brand/GET_BRAND', async (id, { rejectWithValue }) => {
  try {
    const brand = await getBrandRequest(id);

    return brand.block;
  } catch (error) {
    toast.error(error, {
      toastId: error,
    });

    return rejectWithValue(error);
  }
});

const getBrandPageOperation = createAsyncThunk('brand/GET_BRAND_PAGE', async (id, { dispatch }) => {
  dispatch(setLoaderVisibility(true));

  await dispatch(getBrandOperation(id));

  await dispatch(setLoaderVisibility(false));
});

export { getBrandOperation, getBrandPageOperation };
