export const selectSocketConnectionState = (state) => state.socketConnection;

export const selectPrivateChatSocketState = (state) => ({
  error: selectSocketConnectionState(state).privateChatError,
  isConnected: selectSocketConnectionState(state).privateChatConnected,
});

export const selectCommunitySocketState = (state) => ({
  error: selectSocketConnectionState(state).communityError,
  isConnected: selectSocketConnectionState(state).communitySocketConnected,
});

export const selectGlobalSocketState = (state) => ({
  error: selectSocketConnectionState(state).globalSocketError,
  isConnected: selectSocketConnectionState(state).globalSocketConnected,
});
