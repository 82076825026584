import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import getBrandsRequest from 'services/requests/brands';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getBrandsOperation = createAsyncThunk(
  'brands/GET_BRANDS',
  async (requestData, { rejectWithValue }) => {
    try {
      const brands = await getBrandsRequest(requestData);

      return brands.block;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getBrandsPageOperation = createAsyncThunk(
  'brands/GET_BRANDS_PAGE',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(getBrandsOperation(requestData));

    await dispatch(setLoaderVisibility(false));
  },
);

export { getBrandsOperation, getBrandsPageOperation };
