import liveChatSlice from './components/live-chat/live-chat.slice';
import streamSlice from './components/stream/stream.slice';

import { combineReducers } from '@reduxjs/toolkit';

const streamsReducer = combineReducers({
  liveChat: liveChatSlice,
  stream: streamSlice,
});

export default streamsReducer;
