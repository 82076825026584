import cl from 'classnames';
import React from 'react';

import SvgIcon from '../../ui-core/svg-icon';

import s from './styles.module.scss';

export const FilesDropArea = (props) => {
  const { isDragOver = false, onDrop, onDrag } = props;

  const rootClasses = cl(s.filesDropArea, {
    [s.filesDropAreaIsDragOver]: isDragOver,
  });

  return (
    <div
      className={rootClasses}
      onDrop={onDrop}
      onDragEnter={onDrag}
      onDragLeave={onDrag}
      onDragOver={onDrag}
    >
      <div className={s.filesDropAreaDescription}>
        <SvgIcon type="inbox" />
        <p className={s.filesDropAreaDescriptionText}>Drop file here to send in a quick way</p>
      </div>
    </div>
  );
};
