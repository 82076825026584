import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import HEADER_TYPES from 'components/header/header.constants';
import ROUTER_BOOK from 'constants/routes/routes-book';
import { isUserHasAccessSelector } from 'pages/auth/auth.selectors';
import onboardingSelector from 'pages/onboarding/onboarding.selectors';
import PropTypes from 'prop-types';

import './styles.scss';

const PageContainerLayout = ({ children }) => {
  const navigate = useNavigate();

  const { isRequiredStepsCompleted } = useSelector(onboardingSelector);
  const isUserHasAccess = useSelector(isUserHasAccessSelector);

  useEffect(() => {
    if (!isRequiredStepsCompleted && isUserHasAccess) {
      navigate(ROUTER_BOOK.ONBOARDING);
    }
  }, [isRequiredStepsCompleted, isUserHasAccess]);

  return children;
};

PageContainerLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  headerType: PropTypes.oneOf(Object.values(HEADER_TYPES)),
};

PageContainerLayout.defaultProps = {
  headerType: HEADER_TYPES.DEFAULT,
};

export default PageContainerLayout;
