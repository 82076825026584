import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { socketActionCollection } from 'constants';
import { socketService } from 'services/socket';

import { setLoaderVisibility } from 'components/loader/loader.slice';

const { community } = socketActionCollection;

export const connectToCommunitySocket = createAsyncThunk(
  'community/connectToCommunitySocket',
  async (communityId) => {
    socketService.send(community.send.communityConnect, { communityId });
    return communityId;
  },
);

export const connectToCommunityCategory = createAsyncThunk(
  'community/connectToCommunityCategory',
  async (categoryId) => {
    socketService.send(community.send.communityCategoryConnect, { categoryId });
    return categoryId;
  },
);

export const sendCommunityMessage = createAsyncThunk(
  'community/sendCommunityMessage',
  async (payload) => {
    socketService.send(community.send.communityMessageSend, payload);
  },
);

export const toggleCommunicationEmoji = createAsyncThunk(
  'community/toggleCommunicationEmoji',
  async (payload) => {
    socketService.send(community.send.communityEmojiToggle, payload);
  },
);

export const markAsViewedAllCategoryMessages = createAsyncThunk(
  'community/markAsViewedAllCategoryMessages',
  async (communicationItemId, { getState }) => {
    const { channelId } = getState().groupChatting.activeChannel;
    socketService.send(community.send.communityAllMessageView, {
      communicationItemId: channelId,
    });
  },
);

export const receiveViewAllCategoryMessages = createAsyncThunk(
  'community/receiveViewAllCategoryMessages',
  async (payload) => {
    return payload;
  },
);

export const connectToCommunication = createAsyncThunk(
  'community/connectToCommunication',
  async (communicationItemId) => {
    socketService.send(community.send.communityCommunicationConnect, {
      communicationItemId,
    });
    return communicationItemId;
  },
);

export const sendEditCommunityMessage = createAsyncThunk(
  'community/sendEditCommunityMessage',
  async (payload) => {
    socketService.send(community.send.communityMessageEdit, payload);
  },
);

export const getMessagesCommunication = createAsyncThunk(
  'community/getMessagesCommunication',
  async (payload) => {
    const { communicationItemId, direction, paginationMessageId } = payload;

    socketService.send(community.send.communityMessagesGet, {
      communicationItemId,
      skip: 0,
      limit: 50,
      direction,
      paginationMessageId,
    });
  },
);

export const receiveConnectToCommunication = createAsyncThunk(
  'community/receiveConnectToCommunication',
  async (payload, { dispatch }) => {
    dispatch(setLoaderVisibility(false));

    return payload;
  },
);

export const receiveMessagesCommunication = createAsyncThunk(
  'community/receiveMessagesCommunication',
  async (payload) => {
    return {
      messages: payload.messages,
      isLastMessagePresent: payload.pagination.lastMessagePresent,
      isFirstMessagePresent: payload.pagination.firstMessagePresent,
      direction: payload.pagination.direction,
    };
  },
);

export const receiveMessageCommunication = createAsyncThunk(
  'community/receiveMessageCommunication',
  async (payload, { getState }) => {
    const { id } = getState().myAccount.accountInfo;

    return { message: payload, userId: id };
  },
);

export const viewCommunicationMessages = createAsyncThunk(
  'community/viewCommunicationMessages',
  async (messageIds) => {
    socketService.send(community.send.communityMessageView, {
      messageIds,
    });
  },
);
export const receiveViewCommunicationMessages = createAsyncThunk(
  'community/receiveViewCommunicationMessages',
  async (payload) => {
    return payload;
  },
);

export const receiveConnectToCommunityCategory = createAsyncThunk(
  'community/receiveConnectToCommunityCategory',
  async (payload, { getState, dispatch }) => {
    const { activeChannel, isMessageListLoading } = getState().groupChatting;
    const { channelId } = activeChannel;

    if (!isMessageListLoading) {
      if (channelId) {
        dispatch(connectToCommunication(channelId));
      } else if (payload.length) {
        const firstChannelId = payload[0].id;
        dispatch(connectToCommunication(firstChannelId));
      }
    }

    return payload;
  },
);

export const receiveConnectToCommunity = createAsyncThunk(
  'community/receiveConnectToCommunity',
  async (payload, { getState, dispatch, rejectWithValue }) => {
    try {
      const { activeCategory } = getState().groupChatting;
      const { categoryId } = activeCategory;

      if (categoryId) {
        dispatch(connectToCommunityCategory(categoryId));
      }

      return payload;
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const viewMessagesCommunityLocal = createAsyncThunk(
  'community/viewMessagesCommunityLocal',
  async (payload) => {
    return payload;
  },
);

export const receiveCommunicationEmoji = createAsyncThunk(
  'community/receiveCommunicationEmoji',
  async (payload) => {
    return payload;
  },
);

export const receiveUnreadCommunication = createAsyncThunk(
  'community/receiveUnreadCommunication',
  async (payload) => {
    return payload.communicationItemId;
  },
);

export const createCategoryChannel = createAsyncThunk(
  'community/createCategoryChannel',
  async (payload) => {
    socketService.send(community.send.communityCommunicationCreate, payload);
  },
);

export const receiveCreateCategoryChannel = createAsyncThunk(
  'community/receiveCreateCategoryChannel',
  async (payload) => {
    return payload;
  },
);

export const updateCategoryChannel = createAsyncThunk(
  'community/updateCategoryChannel',
  async (payload) => {
    socketService.send(community.send.communityCommunicationUpdate, payload);
  },
);

export const receiveUpdateCategoryChannel = createAsyncThunk(
  'community/receiveUpdateCategoryChannel',
  async (payload) => {
    return payload;
  },
);

export const deleteChannelMessage = createAsyncThunk(
  'community/deleteChannelMessage',
  async (payload, { getState }) => {
    const { channelId } = getState().groupChatting.activeChannel;

    socketService.send(community.send.communityMessageRemove, {
      messageId: payload,
      communicationItemId: channelId,
    });

    return payload;
  },
);

export const pinChannelMessage = createAsyncThunk(
  'community/pinChannelMessage',
  async (payload, { getState }) => {
    const { channelId } = getState().groupChatting.activeChannel;

    socketService.send(community.send.communityMessageTogglePin, {
      communicationItemId: channelId,
      messageId: payload,
    });
  },
);

export const receivePinChannelMessage = createAsyncThunk(
  'community/receivePinChannelMessage',
  async (payload) => {
    return payload;
  },
);

export const receiveCommunityEditedMessage = createAsyncThunk(
  'community/receiveCommunityEditedMessage',
  async (payload) => {
    return payload;
  },
);

export const sendTogglePinedCategoryCommunity = createAsyncThunk(
  'community/sendTogglePinedCategoryCommunity',
  async (payload) => {
    socketService.send(community.send.communityCategoryTogglePin, payload);

    return payload;
  },
);

export const receiveTogglePinnedMessageCommunity = createAsyncThunk(
  'community/receiveTogglePinnedMessageCommunity',
  async (payload) => {
    return payload;
  },
);

export const toggleIsVisiblePinnedCommunityMessages = createAction(
  'community/toggleIsVisiblePinnedCommunityMessages',
  () => {
    return {
      payload: null,
    };
  },
);

export const getPinnedCommunityMessages = createAsyncThunk(
  'community/getPinnedCommunityMessages',
  async (communicationItemId) => {
    socketService.send(community.send.communityPinnedMessagesGet, { communicationItemId });
  },
);

export const receivePinnedCommunityMessages = createAsyncThunk(
  'community/receivePinnedCommunityMessages',
  async (payload) => {
    return payload;
  },
);

export const changeCategoryPosition = createAsyncThunk(
  'community/changeCategoryPosition',
  async (items) => {
    const categories = items.map((item, idx) => ({
      id: item.id,
      position: idx,
    }));

    socketService.send(community.send.communityCategoryUpdatePosition, { categories });
  },
);
export const changeChannelPosition = createAsyncThunk(
  'community/changeChannelPosition',
  async ({ channels, categoryId }) => {
    const communicationItems = channels.map((item, idx) => ({
      id: item.id,
      position: idx,
    }));

    socketService.send(community.send.communityCommunicationUpdatePosition, {
      communicationItems,
      categoryId,
    });
  },
);

export const closeCommunityCategory = createAsyncThunk(
  'community/closeCommunityCategory',
  async (categoryId) => {
    socketService.send(community.send.communityCategoryRemove, {
      categoryId,
    });
  },
);

export const receiveClosedCommunityCategory = createAsyncThunk(
  'community/receiveClosedCommunityCategory',
  async (data) => {
    return data.categoryId;
  },
);

export const sendUpdateCommunityCategory = createAsyncThunk(
  'community/sendUpdateCommunityCategory',
  async (payload) => {
    socketService.send(community.send.communityCategoryUpdate, payload);
  },
);

export const updateActiveCategoryInfo = createAsyncThunk(
  'community/updateActiveCategoryInfo',
  async (data) => {
    return data;
  },
);

export const searchUserInCommunication = createAsyncThunk(
  'community/searchUserInCommunication',
  async (payload) => {
    socketService.send(community.send.communityCommunicationUsersSearch, payload);
  },
);

export const getCommunicationMessageById = createAsyncThunk(
  'community/getCommunicationMessageById',
  async ({ messageId, limitUp = 10, limitDown = 5 }, { rejectWithValue }) => {
    try {
      socketService.send(community.send.communityGetMessageById, {
        messageId,
        limitUp,
        limitDown,
      });
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const receiveCommunicationMessagesById = createAsyncThunk(
  'community/getCommunicationMessageById',
  async (payload, { rejectWithValue }) => {
    try {
      return payload;
    } catch (e) {
      rejectWithValue(e);
    }
  },
);

export const receiveMentionsForAdmin = createAsyncThunk(
  'community/receiveMentionsForAdmin',
  async (payload) => {
    return payload;
  },
);

export const receiveMentions = createAsyncThunk('community/receiveMentions', async (payload) => {
  return payload;
});

export const resetActiveChannel = createAction('community/resetActiveChannel');
