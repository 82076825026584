import colors from 'assets/styles/variables.scss';
import { Fragment, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';

import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import { getRemoteImageUrl } from '../../../../utils/get-remote-image-url';

import './styles.scss';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PAGINATION_DIRECTIONS = {
  NEXT: 'next',
  PREV: 'prev',
};

const PDFPreviewer = () => {
  const dispatch = useDispatch();

  const {
    additionalData: { attachmentUrl },
  } = useSelector(modalSelector);

  const fileURL = getRemoteImageUrl(attachmentUrl);

  const [countOfPages, setCountOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setCountOfPages(numPages);
  };

  const isPaginationAvailable = (direction) => {
    if (countOfPages > pageNumber && direction === PAGINATION_DIRECTIONS.NEXT) {
      return true;
    }

    if (pageNumber > 1 && direction === PAGINATION_DIRECTIONS.PREV) {
      return true;
    }

    return false;
  };

  const handlePrevClick = () => {
    if (isPaginationAvailable(PAGINATION_DIRECTIONS.PREV)) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextClick = () => {
    if (isPaginationAvailable(PAGINATION_DIRECTIONS.NEXT)) {
      setPageNumber(pageNumber + 1);
    }
  };

  return (
    <div className="pdf-previewer">
      <div className="pdf-previewer__close-btn">
        <Button
          onClick={() => {
            dispatch(
              setModal({
                isModalVisible: false,
              }),
            );
          }}
          leftIcon={<SvgIcon type="cross-2" />}
          size={BUTTON_SIZES.ICON}
        />
      </div>

      <Fragment>
        <Document
          options={{
            withCredentials: true,
          }}
          file={fileURL}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            height={window.innerHeight - 100}
          />
        </Document>
        {countOfPages && (
          <Fragment>
            <div className="pdf-previewer__controls">
              <Button
                content="<"
                onClick={handlePrevClick}
                disabled={!isPaginationAvailable(PAGINATION_DIRECTIONS.PREV)}
                size={BUTTON_SIZES.SM}
                backgroundColor={colors['linear-2']}
                contentColor={colors.white}
              />
              <Button
                content=">"
                onClick={handleNextClick}
                disabled={!isPaginationAvailable(PAGINATION_DIRECTIONS.NEXT)}
                size={BUTTON_SIZES.SM}
                backgroundColor={colors['linear-2']}
                contentColor={colors.white}
              />
            </div>
            <div className="pdf-previewer__download">
              <Button
                content={<SvgIcon type="download" />}
                onClick={() => window.open(fileURL)}
                size={BUTTON_SIZES.ICON}
              />
            </div>
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default PDFPreviewer;
