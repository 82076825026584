import { initialSupportData } from 'pages/community/messages/messages.slice';
import { createTransform } from 'redux-persist';

const setTransformForReCaptcha = createTransform(
  (inboundState) => Object.assign({}, inboundState),
  (outboundState) => {
    return Object.assign({}, outboundState, { isReCaptchaLoading: true });
  },
  { whitelist: ['auth'] },
);

const setTransformForCommunityGroupChatting = createTransform(
  (inboundState) => inboundState,
  (outboundState) => {
    return {
      ...outboundState,
      activeCommunityId: null,
      categoriesList: [],
      isMessageListLoading: false,
      isPinnedMessagesVisible: false,
      isCategoryLoading: false,
      isConnectedToCommunity: false,
    };
  },

  { whitelist: ['groupChatting'] },
);

const setTransformForPrivateMessages = createTransform(
  (inboundState) => Object.assign({}, inboundState),
  (outboundState) => {
    return Object.assign({}, outboundState, {
      supportChatData: { ...initialSupportData },
      chatsList: [],
      filteredChatList: [],
      activeChatID: null,
      activeChat: {
        ...outboundState.activeChat,
        owner: null,
        companion: null,
        messages: [],
        isLastMessagePresent: false,
        isFirstMessagePresent: false,
        loadedHashMap: {},
      },
    });
  },

  { whitelist: ['privateMessages'] },
);

const setTransformForMyHomeworks = createTransform(
  (inboundState) => Object.assign({}, inboundState),
  (outboundState) => {
    return Object.assign({}, outboundState, {
      homework: {
        toBeSent: [],
        unverified: [],
        verified: [],
      },
      error: '',
      isLoading: false,
    });
  },

  { whitelist: ['myHomework'] },
);

export {
  setTransformForCommunityGroupChatting,
  setTransformForMyHomeworks,
  setTransformForPrivateMessages,
};

export default setTransformForReCaptcha;
