/* eslint-disable */
import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";



import { notificationRepository } from "../../services/requests";


// ------------- Helper Functions

const normalizeNotifications = (notifications = []) => {
  return notifications.map((notification) => {
    return {
      ...notification,
      redirectInfo: JSON.parse(notification.redirectInfo),
    };
  });
};

export const receiveUnreadNotifications = createAsyncThunk(
  'notification/receiveUnreadNotifications',
  async (data, { rejectWithValue }) => {
    try {
      const { pagination, notifications } = data.userUnreadNotifications;

      const normalizedNotifications = normalizeNotifications(notifications);

      return {
        notifications: normalizedNotifications,
        pagination,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const receiveNewNotification = createAsyncThunk(
  'notification/receiveNewNotification',
  async (data, { rejectWithValue }) => {
    try {
      const { notification } = data;

      return {
        notification: {
          ...notification,
          redirectInfo: JSON.parse(notification.redirectInfo),
        },
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);

export const readNotificationById = createAsyncThunk(
  'notification/readNotificationById',
  async (id, { rejectWithValue }) => {
    try {
      const data = await notificationRepository.readNotificationById(id);

      return {
        id,
        data,
      };
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const readAllNotifications = createAsyncThunk(
  'notification/readAllNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const data = await notificationRepository.readAllNotifications();
      return data;
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);
