import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalVisible: false,
  modalType: null,
  additionalData: undefined,
  withAppearance: true,
  fullScreen: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal(state, { payload }) {
      const {
        modalType,
        additionalData,
        isModalVisible,
        withAppearance = true,
        fullScreen = false,
      } = payload;

      state.isModalVisible = isModalVisible;
      state.modalType = modalType;
      state.additionalData = additionalData;
      state.withAppearance = withAppearance;
      state.fullScreen = fullScreen;
    },
  },
});

export const { setModal } = modalSlice.actions;

export default modalSlice.reducer;
