import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react';
import cl from 'classnames';
import React, { useEffect, useRef } from 'react';

import { useRecorderContext } from '../../../context';
import SvgIcon from '../../ui-core/svg-icon';

import { CircleVideoRecording } from './circle-video-recording';

import './styles.scss';

export const CircleVideoAction = (props) => {
  const { maxRecordTime = 34.2, className, classNameCircle } = props;

  const wrapperRef = useRef(null);
  const videoRef = useRef(null);

  const { startRecording, stopRecording, status, previewStream, clearBlobUrl, isRecording } =
    useRecorderContext();

  // -----------------------------
  const { refs, context, floatingStyles, strategy } = useFloating({
    placement: 'top',
    open: isRecording,
    onOpenChange: (open) => {
      if (!open) {
        stopRecording(false);
        clearBlobUrl();
      }
    },
    middleware: [offset(10), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    ancestorScroll: true,
    bubbles: false,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, click]);

  // -----------------------------

  useEffect(() => {
    if (videoRef.current && previewStream) {
      videoRef.current.srcObject = previewStream;
    }
  }, [previewStream]);

  const rootClasses = cl(
    'circle-video-action',
    {
      'circle-video-action--active': status === 'recording',
      'circle-video-action--inactive': status !== 'recording',
    },
    className,
  );

  // useOnClickOutside(wrapperRef, () => {
  //   stopRecording(false);
  //   clearBlobUrl();
  // });

  const handleClickAction = (event) => {
    event.stopPropagation();
    startRecording();
  };
  const handleCloseModal = (withSaving) => {
    stopRecording(withSaving);
    clearBlobUrl();
  };

  const handleComplete = () => {
    handleCloseModal(true);
  };

  // {...getReferenceProps({
  //   className: rootClasses,
  //   ref: (node) => {
  //     wrapperRef.current = node;
  //     refs.setReference(node);
  //   },
  // })}

  return (
    <>
      <div className={rootClasses} ref={wrapperRef}>
        <button
          // type="button"
          // className="circle-video-action-btn"
          // onClick={handleClickAction}
          {...getReferenceProps({
            className: 'circle-video-action-btn',
            ref: refs.setReference,
            onClick: handleClickAction,
            type: 'button',
          })}
        >
          <span>
            <SvgIcon type="recording-camera" />
          </span>
        </button>
      </div>
      <FloatingPortal>
        <div
          {...getFloatingProps({
            style: { ...floatingStyles, position: strategy },
            ref: refs.setFloating,
            className: 'circle-video-action-float-container',
          })}
        >
          <CircleVideoRecording
            ref={videoRef}
            className={classNameCircle}
            isRecording={status === 'recording'}
            onStop={handleCloseModal}
            maxRecordTime={maxRecordTime}
            onComplete={handleComplete}
          />
        </div>
      </FloatingPortal>
    </>
  );
};
