import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import Menu from 'components/sidebar/components/menu/menu';
import Button from 'components/ui-core/button/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon/svg-icon';

import { isUserHasAccessSelector } from '../../../../../../pages/auth/auth.selectors';
import { UnavailablePlug } from '../../../../../sidebar/components';

import './styles.scss';

const MobileSidebar = ({ handleCloseButtonClicked }) => {
  const isUserHasAccess = useSelector(isUserHasAccessSelector);

  return (
    <>
      <div className="mobile-sidebar__wrapper">
        {!isUserHasAccess && <UnavailablePlug />}
        <div className="mobile-sidebar__header">
          <Button
            leftIcon={<SvgIcon type="logo" />}
            size={BUTTON_SIZES.ICON}
            className="fit-content"
          />
          <Button
            leftIcon={<SvgIcon type="cross-2" />}
            size={BUTTON_SIZES.ICON}
            className="fit-content"
            onClick={handleCloseButtonClicked}
          />
        </div>
        <Menu />
      </div>
      <button className="mobile-sidebar__blur" onClick={handleCloseButtonClicked} />
    </>
  );
};

MobileSidebar.propTypes = {
  handleCloseButtonClicked: PropTypes.func.isRequired,
};

export default MobileSidebar;
