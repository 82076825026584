import { useEffect, useState } from 'react';

// Function to check if third-party cookies are enabled
function areThirdPartyCookiesEnabled(callback) {
  const cookieName = 'testThirdPartyCookie';
  const cookieValue = 'testValue';

  // Try to set a third-party cookie
  document.cookie = `${cookieName}=${cookieValue}; SameSite=None; Secure`;

  // Check if the cookie was set successfully
  if (document.cookie.indexOf(cookieName) !== -1) {
    // Third-party cookies are enabled
    callback(true);
    // Clean up by removing the test cookie
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  } else {
    // Third-party cookies are disabled
    callback(false);
  }
}
const useCookieEnabled = () => {
  const [isCookieEnabled, setIsCookieEnabled] = useState(true);
  const [is3pCookieEnabled, setIs3pCookieEnabled] = useState(true);

  useEffect(() => {
    // Check if cookies are enabled whenever the component mounts
    setIsCookieEnabled(navigator.cookieEnabled);
    areThirdPartyCookiesEnabled((isEnabled) => {
      setIs3pCookieEnabled(isEnabled);
    });
  }, []);

  return isCookieEnabled && is3pCookieEnabled;
};

export default useCookieEnabled;
