import { createContext, useEffect } from 'react';

import amplitude from 'amplitude-js';
import store from 'store/store';

const AnalyticsContext = createContext(null);

const AnalyticsProvider = ({ children }) => {
  const { accountInfo } = store.getState()?.myAccount;

  const sendEvent = ({ event, eventPayload }) => {
    amplitude.getInstance().logEvent(event, {
      ...eventPayload,
      user_id: accountInfo?.id,
      user_creation_time: accountInfo?.creationDate,
    });
  };

  useEffect(() => {
    amplitude.getInstance().init(`${process.env.REACT_APP_AMPLITUDE_API_KEY}`, '', {
      includeUtm: true,
    });

    return () => {
      amplitude.getInstance().destroy();
    };
  }, []);

  return (
    <AnalyticsContext.Provider
      value={{
        sendEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;
export { AnalyticsContext };
