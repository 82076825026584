import { createSelector } from '@reduxjs/toolkit';

export const selectCommunityState = (state) => state.community;

export const selectCommunityLoaders = (state) => ({
  isLoading: selectCommunityState(state).isLoading,
  isLoadingCategory: selectCommunityState(state).isLoadingCategory,
  isLoadingChannel: selectCommunityState(state).isLoadingChannel,
  isLoadingMentions: selectCommunityState(state).isLoadingMentions,
});

export const selectCommunityIsLoading = (state) => selectCommunityLoaders(state).isLoading;
export const selectCommunityIsLoadingCategory = (state) =>
  selectCommunityLoaders(state).isLoadingCategory;
export const selectCommunityIsLoadingChannel = (state) =>
  selectCommunityLoaders(state).isLoadingChannel;
export const selectCommunityIsLoadingMentions = (state) =>
  selectCommunityLoaders(state).isLoadingMentions;

export const selectConnectedCommunityId = (state) =>
  selectCommunityState(state).connectedCommunityId;
export const selectCommunityCategories = (state) => selectCommunityState(state).communityCategories;

export const selectCommunityConnectedCategory = (state) =>
  selectCommunityState(state).connectedCategory;
export const selectCommunityConnectedChannel = (state) =>
  selectCommunityState(state).connectedChannel;

export const selectCommunityPinnedCategories = createSelector(
  selectCommunityCategories,
  (categories) => {
    return categories.filter((c) => c.isPinned);
  },
);
