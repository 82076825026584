import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  connectToCommunityCategory,
  connectToCommunitySocket,
  connectedToCommunitySocket,
  getCommunicationMessageById,
  resetActiveChannel,
} from 'store/actions';

import { notificationType } from '../../../../../../../../constants';
import { setLoaderVisibility } from '../../../../../../../loader/loader.slice';

export const useRedirectToMention = (redirectInfo) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const startMentionFlow = useCallback(
    async (path) => {
      if (redirectInfo.type === notificationType.communityMention) {
        dispatch(setLoaderVisibility(true));
        try {
          await new Promise((resolve) => {
            setTimeout(() => {
              dispatch(connectToCommunitySocket(redirectInfo.communityId));
              resolve();
            }, 0);
          });
          dispatch(connectedToCommunitySocket());
          dispatch(resetActiveChannel());
          dispatch(connectToCommunityCategory(redirectInfo.categoryId));
          // dispatch(connectToCommunication(redirectInfo.communicationItemId));
          await new Promise((resolve) => {
            setTimeout(() => {
              dispatch(getCommunicationMessageById({ messageId: redirectInfo.messageId }));
              resolve();
            }, 100);
          });
          navigate(path);
        } finally {
          dispatch(setLoaderVisibility(false));
        }
      }
    },
    [redirectInfo],
  );

  return startMentionFlow;
};
