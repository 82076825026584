import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { isDiscordAttachment } from 'utils/attachments';

import MODAL_TYPES from 'components/modal-container/modal-container.constants';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import './styles.scss';

const FileAttachment = ({ file }) => {
  const dispatch = useDispatch();

  const handleAttachmentClick = () => {
    if (isDiscordAttachment(file)) {
      // open and download discord file attachment
      window.open(file);
    } else {
      // open own pdf attachment in pdf previewer
      dispatch(
        setModal({
          isModalVisible: true,
          modalType: MODAL_TYPES['PDF-PREVIEWER'],
          additionalData: {
            attachmentUrl: file,
          },
          withAppearance: false,
        }),
      );
    }
  };

  return (
    <Button
      onClick={handleAttachmentClick}
      leftIcon={<SvgIcon type="doc" />}
      size={BUTTON_SIZES.ICON}
      className="file-attachment"
    />
  );
};

FileAttachment.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      preview: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      uploading: PropTypes.bool.isRequired,
      file: PropTypes.object.isRequired,
    }).isRequired,
  ]).isRequired,
};

export default FileAttachment;
