import { CONTENT_TYPES, patch, post } from 'services/api';

import API_LIST from 'constants/api/api-list';
import COMMUNITY_CATEGORIES_TYPES from 'constants/community';

const generateFormDataAndStartUploadingCategoryImage = async (
  title,
  categoryImage,
  isPro = false,
) => {
  const formData = new FormData();

  formData.append(
    'payload',
    JSON.stringify({
      title,
      communityLang: 'en',
      type: isPro ? COMMUNITY_CATEGORIES_TYPES.PAID : COMMUNITY_CATEGORIES_TYPES.FREE,
    }),
  );

  formData.append('image', categoryImage);

  try {
    await post(
      `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}${API_LIST.MEDIA_SERVER.UPLOAD_CATEGORY_IMAGE}`,
      formData,
      {
        ...CONTENT_TYPES.FORM_DATA,
      },
    );
  } catch (error) {
    throw error.message;
  }
};

const generateFormDataAndStartUpdatingCategoryImage = async ({
  title,
  categoryImage,
  id,
  isPro = false,
  status,
}) => {
  const formData = new FormData();

  formData.append(
    'payload',
    JSON.stringify({
      title,
      status,
      communityLang: 'en',
      type: isPro ? COMMUNITY_CATEGORIES_TYPES.PAID : COMMUNITY_CATEGORIES_TYPES.FREE,
    }),
  );

  if (typeof categoryImage !== 'string') {
    formData.append('image', categoryImage);
  }

  try {
    await patch(
      `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}${API_LIST.MEDIA_SERVER.UPLOAD_CATEGORY_IMAGE}/${id}`,
      formData,
      {
        ...CONTENT_TYPES.FORM_DATA,
      },
    );
  } catch (error) {
    throw error.message;
  }
};

const adaptDataFromSocketForDefaultValues = (categoryData) => {
  if (!categoryData) {
    return {};
  }

  const { title, isProOnly } = categoryData;

  return {
    title,
    isPro: isProOnly,
  };
};

export {
  adaptDataFromSocketForDefaultValues,
  generateFormDataAndStartUpdatingCategoryImage,
  generateFormDataAndStartUploadingCategoryImage,
};
