import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

const createName = (name = '') => `common/${name}`;

export const updateHasUnreadPrivateChat = createAction(
  createName('updateHasUnreadPrivateChat'),
  (payload) => {
    return {
      payload: {
        hasUnreadPrivateChat: payload,
      },
    };
  },
);

export const updateHasUnreadCommunity = createAction(
  createName('updateHasUnreadCommunity'),
  (payload) => {
    return {
      payload: {
        hasUnreadCommunity: payload,
      },
    };
  },
);

export const complexUpdateUnreadFlags = createAsyncThunk(
  createName('complexUpdateUnreadFlags'),
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const { userHasUnreadPrivateChat, userCommunityUnreadInfo } = data;
      dispatch(updateHasUnreadPrivateChat(userHasUnreadPrivateChat));

      let hasUnreadCommunity = false;
      Object.values(userCommunityUnreadInfo).forEach((v) => {
        if (!v.isRead) {
          hasUnreadCommunity = true;
        }
      });
      dispatch(updateHasUnreadCommunity(hasUnreadCommunity));

      return {
        userCommunityUnreadInfo,
        userHasUnreadPrivateChat,
        hasUnreadCommunity,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  },
);
