import cl from 'classnames';
import React from 'react';

import './styles.scss';

export const Radio = (props) => {
  const { id, children, checked, className, ...restProps } = props;

  const rootClasses = cl(
    'ui-radio',
    {
      'ui-radio--checked': checked,
      'ui-radio--unchecked': !checked,
    },
    className,
  );

  return (
    <div className={rootClasses}>
      <input className="ui-radio-field" id={id} type="radio" checked={checked} {...restProps} />

      <label htmlFor={id} className="ui-radio-label">
        {children}
      </label>
    </div>
  );
};
