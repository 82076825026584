import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import { COMMUNICATION_ITEM_TYPE } from '../communication-items.constants';
import { ManagementCategoryForm, ManagementChannelForm } from '../forms';

import './styles.scss';

const CreateCommunicationItem = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { additionalData: communicationItemType } = useSelector(modalSelector);

  const generateCommunicationItemForm = () => {
    switch (communicationItemType) {
      case COMMUNICATION_ITEM_TYPE.CATEGORY:
        return <ManagementCategoryForm />;

      default:
        return <ManagementChannelForm />;
    }
  };

  return (
    <div className="create-communication-item">
      <p className="create-communication-item__title">
        {t(`modals.create-communication-item.${communicationItemType}.title`)}
      </p>
      <Button
        onClick={() => {
          dispatch(
            setModal({
              isModalVisible: false,
            }),
          );
        }}
        leftIcon={<SvgIcon type="cross-2" />}
        size={BUTTON_SIZES.ICON}
        className="fit-content"
      />

      <p className="create-communication-item__description">
        {t(`modals.create-communication-item.${communicationItemType}.description`)}
      </p>
      {generateCommunicationItemForm()}
    </div>
  );
};

export default CreateCommunicationItem;
