/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectPaymentLoading } from 'store/selectors';

import { SpinnerLoader } from '../../../ui-core';
import modalSelector from '../../modal-container.selectors';

import { Body } from './body';
import { Footer } from './footer';
import { Header } from './header';

import './styles.scss';

export const PaymentModal = () => {
  const { t } = useTranslation();
  const { additionalData } = useSelector(modalSelector);
  const isLoadingPayment = useSelector(selectPaymentLoading);

  const {
    title,
    priceList,
    subscriptionName,
    type,
    groupSubscriptionType = null,
    isTrialEnabled,
    isTrial,
    trialDayDuration,
    price,
    isSubscription,
  } = additionalData;

  const isPermanent = type === 'diamond';
  const isOneTime = Boolean(groupSubscriptionType);

  const durationName = useMemo(() => {
    if (isPermanent && isSubscription) {
      return '';
    }
    if (!isPermanent && isSubscription) {
      return t('common.month');
    }
    if (isOneTime) {
      return '';
    }
  }, [isPermanent, isSubscription, t, isOneTime]);

  return (
    <div className="payment-modal">
      {isLoadingPayment && <SpinnerLoader />}
      <div className="payment-modal-wrapper">
        <Header />
        <Body
          badge={title}
          benefits={priceList}
          subscriptionName={subscriptionName}
          isTrialEnabled={isTrialEnabled}
          isTrial={isTrial}
          trialDayDuration={trialDayDuration}
          durationName={durationName}
          price={price}
        />
        <Footer
          durationName={durationName}
          isSubscription={isSubscription}
          isTrialEnabled={isTrialEnabled}
          isTrial={isTrial}
          price={price}
          {...additionalData}
        />
      </div>
    </div>
  );
};
