import { getBrandsOperation } from './brands.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  purchased: {},
  categories: [],
  error: '',
  activeBrandName: '',
};

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {
    setActiveBrandName(state, { payload }) {
      state.activeBrandName = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBrandsOperation.fulfilled, (state, { payload }) => {
        state.purchased = payload.purchased;
        state.categories = payload.categories;
        state.error = '';
      })
      .addCase(getBrandsOperation.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export const { setActiveBrandName } = brandsSlice.actions;

export default brandsSlice.reducer;
