/* eslint-disable jsx-a11y/label-has-associated-control */
import PropTypes from 'prop-types';

const IosToggle = ({ name, register }) => {
  return (
    <div className="field-container--ios-toggle">
      <input type="checkbox" className="field" id={name} {...register(name)} />
      <label htmlFor={name} />
    </div>
  );
};

IosToggle.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
};

export default IosToggle;
