import { createAction } from '@reduxjs/toolkit';

export const connectedToCommunitySocket = createAction('socket-connect/connectedToCommunitySocket');
export const downCommunitySocket = createAction('socket-connect/downCommunitySocket', (error) => ({
  payload: {
    error,
  },
}));
export const disconnectCommunitySocket = createAction('socket-connect/disconnectCommunitySocket');

export const connectedToPrivateChatSocket = createAction(
  'socket-connect/connectedToPrivateChatSocket',
);
export const downPrivateChatSocket = createAction(
  'socket-connect/downPrivateChatSocket',
  (error) => ({
    payload: {
      error,
    },
  }),
);
export const disconnectPrivateChatSocket = createAction(
  'socket-connect/disconnectPrivateChatSocket',
);

export const connectedToGlobalSocket = createAction('socket-connect/connectedToGlobalSocket');

export const disconnectGlobalSocket = createAction('socket-connect/disconnectGlobalSocket');
export const downGlobalSocket = createAction('socket-connect/setCommonErrorSocket', (error) => ({
  payload: {
    error,
  },
}));
