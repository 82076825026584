import { stopWordsSectionSelector } from 'pages/lms/my-workspace/nested-routes/streams/components/live-chat/live-chat.selectors';
import { setStopWordsSectionLoader } from 'pages/lms/my-workspace/nested-routes/streams/components/live-chat/live-chat.slice';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setModal } from 'components/modal-container/modal-container.slice';
import SpinnerCircle from 'components/spinner-circle';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import StopWordsForm from './components/stop-words-form';

import './styles.scss';

const ConfigStopWords = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { list, isLoading } = useSelector(stopWordsSectionSelector);

  useEffect(() => {
    return () => {
      dispatch(setStopWordsSectionLoader(true));
    };
  }, []);

  return (
    <div className="config-stop-words">
      <p className="config-stop-words__title">{t('modals.configure-stop-words.title')}</p>
      <Button
        onClick={() => {
          dispatch(
            setModal({
              isModalVisible: false,
            }),
          );
        }}
        leftIcon={<SvgIcon type="cross-2" />}
        size={BUTTON_SIZES.ICON}
        className="fit-content"
      />
      {!isLoading ? <StopWordsForm stopWordsList={list} /> : <SpinnerCircle />}
    </div>
  );
};

export default ConfigStopWords;
