import { useState } from 'react';
import { useSelector } from 'react-redux';

import Attachment from 'components/attachment';
import {
  ATTACHMENT_CONTENT_TYPE,
  ATTACHMENT_ITEM_TYPE,
} from 'components/attachment/attachment.constants';
import AttachmentsList from 'components/attachments-list';
import modalSelector from 'components/modal-container/modal-container.selectors';
import PRIVATE_MESSAGE_ITEM_TYPES from 'components/private-message-item/private-message-item.constants';

import EditMessageForm from '../forms/edit-message-form';

import './styles.scss';

const EditMessage = () => {
  const {
    additionalData: { message, attachments, id, messageType },
  } = useSelector(modalSelector);
  const [copiedAttachments, setCopiedAttachments] = useState(attachments);

  return (
    <div className="edit-message">
      {messageType === PRIVATE_MESSAGE_ITEM_TYPES.PRIVATE && (
        <AttachmentsList attachmentsLength={attachments.length}>
          {copiedAttachments.map((attachment) => {
            return (
              <Attachment
                file={attachment}
                type={ATTACHMENT_ITEM_TYPE.EDITABLE}
                key={attachment}
                contentType={ATTACHMENT_CONTENT_TYPE.MEDIA_SERVER}
                handleRemove={(file) => {
                  setCopiedAttachments(
                    copiedAttachments.filter((copiedAttachment) => copiedAttachment !== file),
                  );
                }}
              />
            );
          })}
        </AttachmentsList>
      )}
      <EditMessageForm
        attachments={copiedAttachments}
        messageData={{ id, message }}
        messageType={messageType}
      />
    </div>
  );
};

export default EditMessage;
