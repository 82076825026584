import PropTypes from 'prop-types';
import { useState } from 'react';

import SvgIcon from 'components/ui-core/svg-icon';

const Search = ({ placeholder, handleSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = ({ value }) => {
    setSearchTerm(value);
    handleSearch(value);
  };

  return (
    <div className="field-container">
      <input
        className="field--search"
        placeholder={placeholder}
        type="text"
        value={searchTerm}
        onChange={({ target }) => handleChange(target)}
      />
      <SvgIcon type="search" />
    </div>
  );
};

Search.propTypes = {
  placeholder: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
};

Search.defaultProps = {
  placeholder: '',
};

export default Search;
