/* eslint-disable consistent-return */
const extractDataForSpecificSection = (landingBlocks, blockType) => {
  if (!landingBlocks.length) {
    return;
  }

  return landingBlocks.find((block) => block.blockType === blockType)?.content;
};

export default extractDataForSpecificSection;
