import { useEffect, useState } from 'react';

import DEFAULT_SCREEN_SIZE_FOR_MOBILE from 'constants/mobile';

const useOnScreen = (ref, rootMargin = '0px') => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIntersecting(entry.isIntersecting);

        if (entry.isIntersecting) {
          if (ref.current) {
            observer.unobserve(ref.current);
          }
        }
      },
      {
        rootMargin,
      },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return isIntersecting;
};

const useIsMobile = (widthForMobile = DEFAULT_SCREEN_SIZE_FOR_MOBILE) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMobile = windowWidth <= widthForMobile;

  const handleWindowSizeChange = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return isMobile;
};

const useMount = (isVisible) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isVisible && !isMounted) {
      setIsMounted(true);
    } else if (!isVisible && isMounted) {
      setTimeout(() => {
        setIsMounted(false);
      }, 300);
    }
  }, [isVisible]);

  return isMounted;
};

const useIsMounted = (isVisible) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isVisible && !isMounted) {
      setIsMounted(true);
    }
  }, [isVisible]);

  return isMounted;
};

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handler(event);
    };

    const handleKeyUpClick = (event) => {
      if (event.key === 'Escape') {
        handler(event);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('keyup', handleKeyUpClick);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('keyup', handleKeyUpClick);
    };
  }, [ref, handler]);
};
export { useIsMobile, useIsMounted, useMount, useOnClickOutside, useOnScreen };
