const chatSectionSelector = (state) => state.streams.liveChat.chatSection;
const liveChatStreamChatIDSelector = (state) => state.streams.liveChat.streamChatId;
const onApproveSectionSelector = (state) => state.streams.liveChat.onApproveSection;
const mutedAndMarkedSectionSelector = (state) => state.streams.liveChat.mutedAndMarkedSection;
const isUserMutedSelector = (state) => state.streams.liveChat.isUserMuted;
const stopWordsSectionSelector = (state) => state.streams.liveChat.stopWordsSection;

export {
  chatSectionSelector,
  isUserMutedSelector,
  liveChatStreamChatIDSelector,
  mutedAndMarkedSectionSelector,
  onApproveSectionSelector,
  stopWordsSectionSelector,
};
