/* eslint-disable no-unused-vars */
import React from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

import modalSelector from '../../modal-container.selectors';

import './styles.scss';

export const VideoModal = () => {
  const { additionalData } = useSelector(modalSelector);

  const { videoUrl } = additionalData;

  return (
    <div className="video-player-modal-wrapper">
      <ReactPlayer
        width="100%"
        height="100%"
        className="video-player-modal"
        url={videoUrl}
        controls
      />
    </div>
  );
};
