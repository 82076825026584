const MODAL_TYPES = {
  PAYMENTS: 'payments',
  PAYMENT_RESULT: 'payment-result',
  'STRIPE-MODAL': 'stripe-modal',
  TRIAL_PRESENT: 'trial-present',
  'CREATE-COMMUNICATION-ITEM': 'create-communication-item',
  'DELETE-COMMUNICATION-ITEM': 'delete-communication-item',
  'COMMUNICATION-ITEM-SETTINGS': 'communication-item-settings',
  'EDIT-MESSAGE': 'edit-message',
  'FULL-SIZE-IMAGE-WITH-ZOOM': 'full-size-image-with-zoom',
  'PDF-PREVIEWER': 'pdf-previewer',
  'CONFIG-STOP-WORDS': 'config-stop-words',
  'CANCEL-SUBSCRIPTION-STEP-1': 'cancel-subscription-step-1',
  'CANCEL-SUBSCRIPTION-STEP-2': 'cancel-subscription-step-2',
  'CANCEL-SUBSCRIPTION-STEP-3': 'cancel-subscription-step-3',
  'SUBSCRIPTION-INFO': 'subscription-info',
  'VIDEO-PLAYER': 'video-player',
  'SUCCESS-PAYMENT-MODAL': 'success-payment-modal',
  'MEDIA-GALLERY': 'media-gallery',
};

export default MODAL_TYPES;
