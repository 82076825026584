import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { selectBusinessAccount } from '../pages/lms/my-account/my-account.selectors';

export const useCheckBusinessIdByParam = (paramName = 'brandId') => {
  const businessAccount = useSelector(selectBusinessAccount);

  const params = useParams();

  const isEqual = useMemo(() => {
    if (!businessAccount || !params || !(paramName in params)) return false;

    return params[paramName] === businessAccount.id.toString();
  }, [businessAccount, paramName, params]);

  return isEqual;
};
