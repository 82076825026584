import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isVisible: false,
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoaderVisibility(state, { payload }) {
      state.isVisible = payload;
    },
  },
});

export const { setLoaderVisibility } = loaderSlice.actions;

export default loaderSlice.reducer;
