import PropTypes from 'prop-types';

import SvgIcon from 'components/ui-core/svg-icon';

import '../styles.scss';

const File = ({ name, handleClick }) => {
  return (
    <button
      type="button"
      className="field--file"
      onClick={(e) => {
        e.currentTarget.lastChild.click();
      }}
    >
      <SvgIcon type="paperclip" />

      <input
        type="file"
        name={name}
        multiple
        className="field--file__input"
        onChange={(e) => {
          handleClick(e.target.files);
        }}
        onClick={(e) => {
          e.target.value = null;
        }}
      />
    </button>
  );
};

File.propTypes = {
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
};

File.defaultProps = {};

export default File;
