import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import cl from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useToggle } from '../../../hooks/use-toggle';
import SvgIcon from '../svg-icon';

import './styles.scss';

export const EmojiSelection = (props) => {
  const {
    iconType = 'smile',
    placement = 'right-start',
    onEmojiSelect,
    className,
    ...restProps
  } = props;

  const [isOpen, toggleOpen] = useToggle();

  const { i18n } = useTranslation();

  const { refs, context, floatingStyles } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: toggleOpen,
    middleware: [offset(10), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, {
    ancestorScroll: true,
    bubbles: false,
  });
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss, role, click]);

  const handleEmojiSelect = (emojiData) => {
    onEmojiSelect?.(emojiData.native);

    toggleOpen(false);
  };

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps({
          className: cl('emoji-selection', className),
          type: 'button',
          ...restProps,
        })}
      >
        <SvgIcon type={iconType} />
      </button>
      {isOpen && (
        <FloatingPortal>
          <div
            {...getFloatingProps({
              className: 'emoji-selection-popup ',
              ref: refs.setFloating,
              style: floatingStyles,
            })}
          >
            <Picker
              className="emoji-selection-popup-inner"
              emojiSize={16}
              data={data}
              onEmojiSelect={handleEmojiSelect}
              navPosition="none"
              previewPosition="none"
              dynamicWidth
              locale={i18n.language === 'ua' ? 'uk' : 'en'}
            />
          </div>
        </FloatingPortal>
      )}
    </>
  );
};
