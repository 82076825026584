// eslint-disable-next-line import/prefer-default-export
export const generateImagePath = (originalPath) => {
  const url = process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH;

  return `${url}${originalPath}`;
};

export const generateImagePathWithoutCache = (originalPath) => {
  if (!originalPath) return '';

  const url = process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH;

  return `${url}${originalPath.trim()}?cache=${Date.now()}`;
};

export const getDataFromImageURL = async (url) => {
  const imageBlob = await fetch(url).then((res) => res.blob());

  return URL.createObjectURL(imageBlob);
};

export const getAccountImageOrDefault = (image) => {
  const defaultImage = `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}/attachment/lms/images/user/default.png`;

  if (image) {
    return `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}/attachment/lms/${image}`;
  }

  return defaultImage;
};
