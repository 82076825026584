import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

import ROUTER_BOOK from '../../../../constants/routes/routes-book';
import SvgIcon from '../../../ui-core/svg-icon';

import './styles.scss';

export const UnavailablePlug = () => {
  const { t } = useTranslation();

  return (
    <div className="unavailable-plug">
      <div className="unavailable-plug-wrapper">
        <div className="unavailable-plug-top">
          <span className="unavailable-plug-top-logo">
            <SvgIcon type="minimal-logo" />
          </span>
          <p className="unavailable-plug-top-label">
            {t('shared-content.sidebar-access-content-title')}
          </p>
        </div>
        <div className="unavailable-plug-bottom">
          <p className="unavailable-plug-bottom-descr">
            {t('shared-content.sidebar-access-content-description')}
          </p>
        </div>
        <div className="unavailable-plug-bottom-link-wrapper">
          <NavLink
            to={ROUTER_BOOK.AUTH.LOGIN}
            target="_blank"
            className="unavailable-plug-bottom-link"
          >
            {t('shared-content.sign-in')}
          </NavLink>
        </div>
      </div>
    </div>
  );
};
