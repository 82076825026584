import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import SvgIcon from '../../../../ui-core/svg-icon';
import { setModal } from '../../../modal-container.slice';

import './styles.scss';

export const Header = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleClickCross = () => {
    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  return (
    <div className="payment-modal-header">
      <h3 className="payment-modal-header-title">{t('modals.payments.you-selected')}:</h3>
      <button className="payment-modal-header-btn" onClick={handleClickCross}>
        <SvgIcon type="cross-2" />
      </button>
    </div>
  );
};
