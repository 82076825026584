import { createSlice } from '@reduxjs/toolkit';
import {
  connectToCommunication,
  connectToCommunityCategory,
  connectToCommunitySocket,
  deleteChannelMessage,
  getCommunicationMessageById,
  getPinnedCommunityMessages,
  receiveClosedCommunityCategory,
  receiveCommunicationEmoji,
  receiveCommunicationMessagesById,
  receiveCommunityEditedMessage,
  receiveConnectToCommunication,
  receiveConnectToCommunity,
  receiveConnectToCommunityCategory,
  receiveCreateCategoryChannel,
  receiveMentions,
  receiveMentionsForAdmin,
  receiveMessageCommunication,
  receiveMessagesCommunication,
  receivePinnedCommunityMessages,
  receiveTogglePinnedMessageCommunity,
  receiveUnreadCommunication,
  receiveUpdateCategoryChannel,
  receiveViewCommunicationMessages,
  resetActiveChannel,
  sendTogglePinedCategoryCommunity,
  toggleIsVisiblePinnedCommunityMessages,
  updateActiveCategoryInfo,
  viewMessagesCommunityLocal,
} from 'store/actions/community.action';

import { CHANNEL_STATUSES } from 'components/modal-container/components/communication-items/communication-items.constants';

const initialState = {
  activeCommunityId: null,
  categoriesList: [],
  isConnectedToCategory: false,
  isConnectedToCommunity: false,
  isLoadingCommunity: false,
  activeCategory: {
    categoryId: null,
    category: null,
    channels: [],
  },
  activeChannel: {
    channelId: null,
    isLastMessagePresent: false,
    isFirstMessagePresent: false,
    isPinnedMessagesVisible: false,
    pinnedMessagesList: [],
    messages: [],
    channelInfo: null,
    mentions: {
      users: [],
    },
  },

  isMessageListLoading: false,
  isCategoryLoading: false,
  isLoadingMessagesById: false,
  replyMessage: null,
};

const groupChattingSlice = createSlice({
  name: 'groupChatting',
  initialState,
  reducers: {
    setActiveCategoryID(state, { payload }) {
      state.activeChannel = initialState.activeChannel;
      state.activeCategory.categoryId = payload;
    },
    increaseCountOfUnreadMessages(state, { payload }) {
      state.channelsList.forEach((channel) => {
        if (channel.id === payload) {
          channel.unreadCount += 1;
        }
      });
    },
    decreaseCountOfUnreadMessages(state, { payload }) {
      state.channelsList.forEach((channel) => {
        if (channel.id === state.activeChannelID && channel.unreadCount > 0) {
          channel.unreadCount -= payload;
        }
      });
    },

    setMessageEmojis(state, { payload }) {
      state.messagesList.forEach((message) => {
        if (message.id === payload.messageId) {
          message.reactions.reactions = payload.reactions;
        }
      });
    },

    updateMessageData(state, { payload }) {
      const indexOfMessageToReplace = state.messagesList.findIndex(
        (message) => message.id === payload.id,
      );

      state.messagesList[indexOfMessageToReplace] = payload;
    },

    setPinnedMessages(state, { payload }) {
      state.pinnedMessagesList = payload;
      state.isPinnedMessagesVisible = true;
    },

    setIsPinnedMessagesVisible(state, { payload }) {
      state.activeChannel.isPinnedMessagesVisible = payload;
    },

    removePinnedMessage(state, { payload }) {
      state.pinnedMessagesList = state.pinnedMessagesList.filter(
        (pinnedMessage) => pinnedMessage.id !== payload,
      );
    },

    updatePinnedMessage(state, { payload }) {
      const indexOfPinnedMessageToReplace = state.pinnedMessagesList.findIndex(
        (pinnedMessage) => pinnedMessage.id === payload.id,
      );

      state.pinnedMessagesList[indexOfPinnedMessageToReplace] = payload;
    },

    updatePinnedMessageEmojis(state, { payload }) {
      const indexOfPinnedMessageToReplace = state.pinnedMessagesList.findIndex(
        (pinnedMessage) => pinnedMessage.id === payload.messageId,
      );

      state.pinnedMessagesList[indexOfPinnedMessageToReplace].reactions = payload;
    },
    deleteMessage(state, { payload }) {
      state.messagesList = state.messagesList.filter((message) => message.id !== payload.messageId);

      if (state.isPinnedMessagesVisible) {
        state.pinnedMessagesList = state.pinnedMessagesList.filter(
          (pinnedMessage) => pinnedMessage.id !== payload.messageId,
        );
      }
    },

    addNewChannel(state, { payload }) {
      state.channelsList.push(payload);
    },

    addNewCategory(state, { payload }) {
      state.categoriesList.unshift(payload);
    },

    updateChannel(state, { payload }) {
      state.channelsList = state.channelsList.map((channel) => {
        if (channel.id === payload.id) {
          return payload;
        }

        return channel;
      });
    },
    updateCategory(state, { payload }) {
      state.categoriesList = state.categoriesList.map((category) => {
        if (category.id === payload.id) {
          category.title = payload.title;
          category.imageUrl = payload.imageUrl;
        }

        return category;
      });
    },
    markMessagesAsViewed(state, { payload }) {
      state.messagesList.forEach((message) => {
        if (payload.includes(message.id)) {
          message.isViewed = true;
          message.viewCount += 1;
        }
      });
    },

    updateViewCount(state, { payload }) {
      state.messagesList.forEach((message) => {
        const updatedMessage = payload.find(
          (messageWithUpdatedViewCount) => message.id === messageWithUpdatedViewCount.messageId,
        );

        if (updatedMessage) {
          message.viewCount = updatedMessage.viewedCount;
        }
      });
    },

    handleDeleteCategory(state, { payload }) {
      state.categoriesList.forEach((category) => {
        if (category.id === payload.categoryId) {
          category.status = CHANNEL_STATUSES.CLOSED.toUpperCase();
        }
      });

      state.activeCategoryID = state.categoriesList[0].id;
    },

    handleMarkAllAsRead(state) {
      state.channelsList.forEach((channel) => {
        if (channel.id === state.activeChannelID) {
          channel.unreadCount = 0;
        }
      });

      state.messagesList.forEach((message) => {
        if (!message.isViewed) {
          message.isViewed = true;
        }
      });
    },

    setReplyMessage(state, { payload }) {
      state.replyMessage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(connectToCommunitySocket.fulfilled, (state, { payload }) => {
      state.isLoadingCommunity = true;
      state.activeCommunityId = payload;
      state.isConnectedToCommunity = false;
    });

    builder.addCase(receiveConnectToCommunity.fulfilled, (state, { payload }) => {
      state.categoriesList = payload;
      state.isLoadingCommunity = false;
      state.isConnectedToCommunity = true;
      const activeCategory = state.activeCategory;

      if (activeCategory && activeCategory?.categoryId) {
        payload.forEach((category) => {
          if (category.id === activeCategory.categoryId) {
            state.activeCategory.category = category;
          }
        });
      }
    });

    builder.addCase(receiveConnectToCommunity.rejected, (state) => {
      state.isLoadingCommunity = false;
    });

    builder.addCase(connectToCommunityCategory.fulfilled, (state, { payload }) => {
      state.isCategoryLoading = true;
      state.activeCategory.categoryId = payload;

      const foundCategory = state.categoriesList.find((category) => category.id === payload);
      if (foundCategory) {
        state.activeCategory.category = foundCategory;
      }
    });
    builder.addCase(receiveConnectToCommunityCategory.fulfilled, (state, { payload }) => {
      state.activeCategory.channels = payload;
      state.isCategoryLoading = false;
      state.isConnectedToCategory = true;
    });

    builder.addCase(connectToCommunication.fulfilled, (state, { payload }) => {
      state.isMessageListLoading = true;
      state.activeChannel.pinnedMessagesList = [];
      state.activeChannel.isPinnedMessagesVisible = false;
      state.activeChannel.messages = [];
      state.activeChannel.channelId = payload;

      state.activeCategory.channels.forEach((channel) => {
        if (channel.id === state.activeChannel.channelId) {
          state.activeChannel.channelInfo = channel;
        }
      });
    });
    builder.addCase(receiveConnectToCommunication.pending, (state) => {
      state.isMessageListLoading = false;
    });
    builder.addCase(receiveConnectToCommunication.fulfilled, (state, { payload }) => {
      const { channelId } = state.activeChannel;
      if (payload.communicationItemId === channelId) {
        if (!state.isLoadingMessagesById) {
          state.activeChannel.messages = payload.messages;
          state.activeChannel.isLastMessagePresent = payload.pagination.lastMessagePresent;
          state.activeChannel.isFirstMessagePresent = payload.pagination.firstMessagePresent;

          if (payload.messages.length === 0) {
            state.activeChannel.isLastMessagePresent = true;
            state.activeChannel.isFirstMessagePresent = true;
          }
        }

        state.activeCategory.channels.forEach((channel) => {
          if (channel.id === state.activeChannel.channelId) {
            state.activeChannel.channelInfo = channel;
            state.activeChannel.unreadCount = channel.unreadCount;
          }
        });
      }

      state.isMessageListLoading = false;
    });

    builder.addCase(viewMessagesCommunityLocal.fulfilled, (state, { payload }) => {
      const { channelId, messages, channelInfo } = state.activeChannel;
      const { channels } = state.activeCategory;

      if (channelInfo.unreadCount > 0) {
        channelInfo.unreadCount -= 1;
      }

      messages.forEach((message) => {
        if (message.id === payload) {
          message.isViewed = true;
          message.viewCount += 1;
        }
      });

      channels.forEach((channel) => {
        if (channel.id === channelId && channel.unreadCount > 0) {
          channel.unreadCount -= 1;
        }
      });
    });

    builder.addCase(receiveMessagesCommunication.fulfilled, (state, { payload }) => {
      const { messages, isFirstMessagePresent, isLastMessagePresent, direction } = payload;
      const { activeChannel } = state;

      if (direction === 'up') {
        const reversedMessages = messages.slice().reverse();

        activeChannel.messages = [...reversedMessages, ...activeChannel.messages];
        activeChannel.isFirstMessagePresent = isFirstMessagePresent;
      }

      if (direction === 'down') {
        activeChannel.messages = [...activeChannel.messages, ...messages];
        activeChannel.isLastMessagePresent = isLastMessagePresent;
      }
    });
    builder.addCase(receiveMessageCommunication.fulfilled, (state, { payload }) => {
      const { message, userId } = payload;
      const { channelId, messages, isLastMessagePresent, channelInfo } = state.activeChannel;
      const { channels } = state.activeCategory;

      channels.forEach((cn) => {
        if (cn.id === channelId && message.authorId !== userId) {
          cn.unreadCount += 1;
        }
      });

      if (message.authorId !== userId) {
        channelInfo.unreadCount += 1;
      }

      if (isLastMessagePresent || messages.length === 0) {
        messages.push(message);
      }
    });
    builder.addCase(receiveCommunicationEmoji.fulfilled, (state, { payload }) => {
      const { messages } = state.activeChannel;

      messages.forEach((message) => {
        if (message.id === payload.messageId) {
          message.reactions.reactions = payload.reactions;
        }
      });
    });
    builder.addCase(receiveUnreadCommunication.fulfilled, (state, { payload }) => {
      const { channels } = state.activeCategory;

      channels.forEach((channel) => {
        if (channel.id === payload) {
          channel.unreadCount += 1;
        }
      });
    });
    builder.addCase(receiveViewCommunicationMessages.fulfilled, (state, { payload }) => {
      state.activeCategory.channels.forEach((channel) => {
        if (channel.id === payload.communicationItemId) {
          channel.unreadCount = 0;
        }
        state.activeChannel.channelInfo.unreadCount = 0;
        state.activeChannel.messages.forEach((message) => {
          if (!message.isViewed) {
            message.isViewed = true;
            message.viewCount += 1;
          }
        });
      });
    });
    builder.addCase(receiveCreateCategoryChannel.fulfilled, (state, { payload }) => {
      state.activeCategory.channels.push(payload);
    });
    builder.addCase(receiveUpdateCategoryChannel.fulfilled, (state, { payload }) => {
      state.activeCategory.channels.forEach((channel, idx) => {
        if (channel.id === payload.id) {
          state.activeCategory.channels[idx] = payload;
        }
      });
      if (state.activeChannel.channelId === payload.id) {
        state.activeChannel.channelInfo = payload;
      }
    });
    builder.addCase(deleteChannelMessage.fulfilled, (state, { payload }) => {
      const { messages } = state.activeChannel;

      messages.forEach((message, idx) => {
        if (message.id === payload) {
          messages.splice(idx, 1);
        }
      });
    });

    builder.addCase(receiveCommunityEditedMessage.fulfilled, (state, { payload }) => {
      const { messages } = state.activeChannel;

      messages.forEach((message, idx) => {
        if (message.id === payload.id) {
          messages[idx] = payload;
        }
      });
    });

    builder.addCase(sendTogglePinedCategoryCommunity.fulfilled, (state, { payload }) => {
      const { categoryId } = payload;

      state.categoriesList.forEach((category) => {
        if (category.id === categoryId) {
          category.isPinned = !category.isPinned;
        }
      });
    });

    builder.addCase(receiveTogglePinnedMessageCommunity.fulfilled, (state, { payload }) => {
      const { activeChannel } = state;

      activeChannel.messages.forEach((message, idx) => {
        if (message.id === payload.id) {
          activeChannel.messages[idx] = payload;
        }
      });
    });

    builder.addCase(toggleIsVisiblePinnedCommunityMessages, (state) => {
      state.activeChannel.isPinnedMessagesVisible = !state.activeChannel.isPinnedMessagesVisible;
    });

    builder.addCase(getPinnedCommunityMessages.fulfilled, (state) => {
      state.isMessageListLoading = true;
    });

    builder.addCase(receivePinnedCommunityMessages.fulfilled, (state, { payload }) => {
      state.activeChannel.pinnedMessagesList = payload;
      state.isMessageListLoading = false;
    });

    builder.addCase(receiveClosedCommunityCategory.fulfilled, (state, { payload }) => {
      state.categoriesList.forEach((category) => {
        if (category.id === payload) {
          category.status = 'CLOSED';
        }
      });

      if (state.activeCategory.categoryId === payload) {
        state.activeCategory.category.status = 'CLOSED';
      }
    });

    builder.addCase(updateActiveCategoryInfo.fulfilled, (state, { payload }) => {
      state.categoriesList.forEach((category) => {
        if (category.id === payload.id) {
          category.title = payload.title;
          category.imageUrl = payload.imageUrl;
          category.status = payload.status;
        }
      });
      if (state.activeCategory.categoryId === payload.id) {
        state.activeCategory.category = {
          ...state.activeCategory.category,
          title: payload.title,
          imageUrl: payload.imageUrl,
          status: payload.status,
        };
      }
    });

    builder.addCase(receiveMentions.fulfilled, (state, { payload }) => {
      state.activeChannel.mentions = payload;
    });

    builder.addCase(receiveMentionsForAdmin.fulfilled, (state, { payload }) => {
      state.activeChannel.mentions = {
        ...payload,
        users: [
          ...payload.users,
          { id: 'everyone', name: 'everyone', image: '' },
          { id: 'here', name: 'here', image: '' },
        ],
      };
    });

    builder.addCase(resetActiveChannel, (state) => {
      state.activeChannel = initialState.activeChannel;
    });

    builder.addCase(getCommunicationMessageById.pending, (state) => {
      state.isLoadingCommunity = true;
      state.isMessageListLoading = true;
      state.isLoadingMessagesById = true;
    });
    builder.addCase(receiveCommunicationMessagesById.fulfilled, (state, { payload }) => {
      state.isLoadingCommunity = false;
      state.isMessageListLoading = false;
      state.isLoadingMessagesById = false;

      state.activeChannel.messages = payload.messages;
      state.activeChannel.isLastMessagePresent = payload.pagination.lastMessagePresent;
      state.activeChannel.isFirstMessagePresent = payload.pagination.firstMessagePresent;

      if (payload.messages.length === 0) {
        state.activeChannel.isLastMessagePresent = true;
        state.activeChannel.isFirstMessagePresent = true;
      }
    });
  },
});

export const {
  setActiveCategoryID,
  setCategoriesList,
  receiveMessage,
  increaseCountOfUnreadMessages,
  setMessageEmojis,
  updateMessageData,
  setPinnedMessages,
  setIsPinnedMessagesVisible,
  removePinnedMessage,
  updatePinnedMessage,
  updatePinnedMessageEmojis,
  deleteMessage,
  addNewChannel,
  addNewCategory,
  updateChannel,
  updateViewCount,
  updateCategory,
  handleDeleteCategory,
  handleMarkAllAsRead,
  setReplyMessage,
} = groupChattingSlice.actions;

export default groupChattingSlice.reducer;
