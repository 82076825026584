import Lottie from 'lottie-react';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import loaderSelector from '../loader/loader.selectors';

import animationJSON from './animation.json';

import './styles.scss';

const WelcomeScreen = () => {
  const lottieRef = useRef();
  const [isVisibleWelcomeScreen, setIsVisibleWelcomeScreen] = useState(false);
  const { isVisible } = useSelector(loaderSelector);

  useEffect(() => {
    const isWelcomeScreenShown = sessionStorage.getItem('isWelcomeScreenShown');

    if (!JSON.parse(isWelcomeScreenShown)) {
      setIsVisibleWelcomeScreen(true);
      lottieRef.current.play();
      sessionStorage.setItem('isWelcomeScreenShown', JSON.stringify(true));
    }
  }, []);

  const completedCallback = () => setIsVisibleWelcomeScreen(false);

  const handleEnterFrameCallback = (e) => {
    const targetFrame = 250;
    const currentFrame = Math.floor(e.currentTime);

    if (currentFrame === targetFrame && !isVisible) {
      setIsVisibleWelcomeScreen(false);
    }
  };

  return (
    <div className={`loader${isVisibleWelcomeScreen ? '' : '--hidden'}`}>
      <Lottie
        className="loader__animation"
        animationData={animationJSON}
        lottieRef={lottieRef}
        rendererSettings={{
          preserveAspectRatio: 'xMidYMid slice',
        }}
        onEnterFrame={handleEnterFrameCallback}
        onComplete={completedCallback}
        onLoopComplete={completedCallback}
      />
    </div>
  );
};

export default WelcomeScreen;
