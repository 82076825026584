import React from 'react';

import SpinnerCircle from '../../../spinner-circle';
import SvgIcon from '../../../ui-core/svg-icon';

export const SubmitBtn = (props) => {
  const { isSubmitting } = props;

  return isSubmitting ? (
    <SpinnerCircle
      style={{
        width: 24,
        height: 24,
        margin: 0,
      }}
    />
  ) : (
    <button type="submit">
      <SvgIcon type="send" />
    </button>
  );
};
