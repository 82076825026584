const generateAdaptedStopWordsFormDataForSocket = (stopWords) => {
  return stopWords.split('\n').filter(Boolean);
};

const generateAdaptedStopWordsFormDefaultValues = (stopWordsList) => {
  return {
    stopWords: stopWordsList.join('\n'),
  };
};
export { generateAdaptedStopWordsFormDataForSocket, generateAdaptedStopWordsFormDefaultValues };
