const LINK_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  PURPLE_FILLED: 'purple-filled',
  BLUE_FILLED: 'blue-filled',
  MENU: 'menu',
  ONBOARDING: 'onboarding',
};

export default LINK_TYPES;
