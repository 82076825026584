import AnalyticsProvider from 'analytics/analytics';
import App from 'app';
import LocalizationProvider from 'localization/localization';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'store/store';

import WelcomeScreen from './components/welcome-screen';
import { setIsUserHasAccess } from './pages/auth/auth.slice';
import { apiClient } from './services/api';
import './utils/activate-cross-domain-requests';

import './assets/styles/index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
const app = (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AnalyticsProvider>
        <LocalizationProvider>
          <App />
          <WelcomeScreen />
        </LocalizationProvider>
      </AnalyticsProvider>
    </PersistGate>
  </Provider>
);

/* Intercept any unauthorized request.
 * dispatch logout action accordingly */

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(setIsUserHasAccess(false));
    }
    return Promise.reject(error);
  },
);

root.render(app);
