import API_LIST from 'constants/api/api-list';
import { get, post } from 'services/api';

const checkWayForPayPaymentRequest = async ({ language, ...requestData }) => {
  const url = `${API_LIST.PAYMENTS.CHECK_WAY_FOR_PAY}`;

  try {
    const { data } = await post(`${url}?lang=${language}`, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const checkPayPalPaymentRequest = async ({ PayerID, token, queryParams }) => {
  const url = `${API_LIST.PAYMENTS.CHECK_PAY_PAL}?PayerID=${PayerID}&token=${token}${queryParams}`;

  try {
    const { data } = await get(url);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const checkBitPayPaymentRequest = async ({ language, ...requestData }) => {
  const url = `${API_LIST.PAYMENTS.CHECK_BIT_PAY}?lang=${language}`;

  try {
    const { data } = await post(url, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const payWithPayPalRequest = async (requestData) => {
  const url = `${API_LIST.PAYMENTS.PAY_PAY_PAL}`;

  try {
    const { data } = await post(url, requestData);

    window.location.href = data.forwardLink;

    return;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const payWithBitPayRequest = async (requestData) => {
  const url = `${API_LIST.PAYMENTS.PAY_BIT_PAY}`;

  try {
    const { data } = await post(url, requestData);

    window.location.href = data.forwardLink;

    return;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

const payWithWayForPayRequest = async (requestData) => {
  const url = `${API_LIST.PAYMENTS.PAY_WAY_FOR_PAY}`;

  try {
    const { data } = await post(`${url}`, requestData);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};

export {
  checkBitPayPaymentRequest,
  checkPayPalPaymentRequest,
  checkWayForPayPaymentRequest,
  payWithBitPayRequest,
  payWithPayPalRequest,
  payWithWayForPayRequest,
};
