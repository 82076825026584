import { httpNotification } from '../../http-notification';

class NotificationRepository {
  readNotificationById = async (id) => {
    try {
      const path = `socket/read/${id}`;
      const { data } = await httpNotification.patch(path);

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  readAllNotifications = async () => {
    try {
      const path = `socket/read/all`;
      const { data } = await httpNotification.patch(path);

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  catchError = (err) => {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  };
}

export const notificationRepository = new NotificationRepository();
