import { getDashboardBlocksOperation } from './dashboard.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  error: '',
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getDashboardBlocksOperation.fulfilled, (state, { payload }) => {
        state.list = payload;
        state.error = '';
      })
      .addCase(getDashboardBlocksOperation.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default dashboardSlice.reducer;
