import cl from 'classnames';
import { USER_PERMISSIONS_KEY } from 'constants/roles/index';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AdminComponentsRenderer from 'components/admin-components-renderer/admin-components-renderer';
import { COMMUNICATION_ITEM_TYPE } from 'components/modal-container/components/communication-items/communication-items.constants';
import modalSelector from 'components/modal-container/modal-container.selectors';

import COMMUNITY_CATEGORIES_TYPES from '../../constants/community';
import { useCheckBusinessIdByParam } from '../../hooks/use-check-business-id-by-param';
import { sendUpdateCommunityCategory, updateCategoryChannel } from '../../store/actions';
import { setModal } from '../modal-container/modal-container.slice';

import './styles.scss';

const SettingsModalContentSwitcher = ({ config }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    additionalData: { communicationItemType, itemData },
  } = useSelector(modalSelector);

  const [activeOption, setActiveOption] = useState(Object.keys(config)[0]);

  const permissionsByCommunicationItemType = {
    [COMMUNICATION_ITEM_TYPE.CATEGORY]: USER_PERMISSIONS_KEY.communityCategoryDelete,
    [COMMUNICATION_ITEM_TYPE.CHANNEL]: USER_PERMISSIONS_KEY.communicationItemDelete,
  };

  const isClosed = itemData?.status === 'CLOSED';

  const isEqual = useCheckBusinessIdByParam();

  const handleActionClick = () => {
    switch (communicationItemType) {
      case COMMUNICATION_ITEM_TYPE.CATEGORY:
        dispatch(
          sendUpdateCommunityCategory({
            status: isClosed ? 'ACTIVE' : 'CLOSED',
            categoryId: itemData.id,
            title: itemData.title,
            type: itemData.isProOnly
              ? COMMUNITY_CATEGORIES_TYPES.PAID
              : COMMUNITY_CATEGORIES_TYPES.FREE,
          }),
        );
        break;
      case COMMUNICATION_ITEM_TYPE.CHANNEL:
        dispatch(
          updateCategoryChannel({
            status: isClosed ? 'ACTIVE' : 'CLOSED',
            categoryId: itemData.categoryId,
            communicationItemId: itemData.id,
            title: itemData.title,
            type: itemData.type,
            description: itemData.description,
          }),
        );
        break;
      default:
        break;
    }

    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  return (
    <div className="settings-modal-content-switcher">
      <div className="settings-modal-content-switcher__options-list">
        {Object.keys(config).map((option) => {
          return (
            <button
              key={option}
              className={`settings-modal-content-switcher__option${
                activeOption === option ? '--active' : ''
              }`}
              onClick={() => setActiveOption(option)}
            >
              {t(`shared-content.${option.toLowerCase()}`)}
            </button>
          );
        })}
        <AdminComponentsRenderer
          permission={permissionsByCommunicationItemType[communicationItemType]}
          additionalCondition={isEqual}
        >
          <button
            className={cl('settings-modal-content-switcher__delete-button', {
              [`settings-modal-content-switcher__delete-button--closed`]: isClosed,
              [`settings-modal-content-switcher__delete-button--active`]: !isClosed,
            })}
            onClick={handleActionClick}
          >
            {isClosed
              ? t(`modals.communication-item-settings.${communicationItemType}.restore-button`)
              : t(`modals.communication-item-settings.${communicationItemType}.delete-button`)}
          </button>
        </AdminComponentsRenderer>
      </div>
      <div className="settings-modal-content-switcher__forms-list">{config[activeOption]}</div>
    </div>
  );
};

SettingsModalContentSwitcher.propTypes = {
  config: PropTypes.object.isRequired,
};

export default SettingsModalContentSwitcher;
