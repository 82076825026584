import React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../../ui-core/button';
import { BUTTON_SIZES } from '../../../../ui-core/button/button.constants';
import { setModal } from '../../../modal-container.slice';

import './styles.scss';
import colors from 'assets/styles/variables.scss';


export const Actions = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickBack = () => {
    dispatch(setModal({ isModalVisible: false }));
  };

  const navigateToCommunity = () => {
    navigate(`${pathname}/community`);

    dispatch(
      setModal({
        isModalVisible: false,
      }),
    );
  };

  return (
    <div className="success-payment-modal-actions">
      <Button
        content={t('modals.success-payment-modal.actions.back')}
        size={BUTTON_SIZES.MD}
        contentColor={colors.white}
        backgroundColor={colors['neutral-gray-700']}
        onClick={handleClickBack}
      />
      <Button
        content="To Community"
        size={BUTTON_SIZES.MD}
        contentColor={colors.white}
        backgroundColor={colors['linear-2']}
        onClick={navigateToCommunity}
      />
    </div>
  );
};
