const updateEventById = (events, id, updatedEvent) => {
  return Object.fromEntries(
    Object.entries(events).map(([key, listOfEvents]) => [
      key,
      listOfEvents.map((event) => (event.id === id ? updatedEvent : event)),
    ]),
  );
};

export const checkOnEventCategoriesEmpty = (eventsObj) => {
  return Object.values(eventsObj).every((arr) => arr.length === 0);
};

export default updateEventById;
