import * as yup from 'yup';

const cancelSubscriptionStep2Schema = yup.object({
  selectedFields: yup
    .array()
    .test('at-least-one-selected', 'At least one field is required', (value) => {
      return value.some((val) => val === true);
    })
    .required('At least one field is required'),
  ownReason: yup.string().when('selectedFields', {
    is: (selectedFields) => selectedFields[selectedFields.length - 1],
    then: yup.string().required('Please provide your own reason'),
  }),
});

export default cancelSubscriptionStep2Schema;
