export const selectNotificationState = (state) => state.notification;

export const selectNotificationList = (state) => selectNotificationState(state).notifications;

export const selectHasNotifications = (state) => selectNotificationList(state).length > 0;

export const selectNotificationPagination = (state) => selectNotificationState(state).pagination;

export const selectNotificationLoading = (state) => selectNotificationState(state).isLoading;

export const selectIsLoadingReadAllNotifications = (state) =>
  selectNotificationState(state).isLoadingReadAll;

export const selectNotificationLoaders = (state) => ({
  isLoadingReadAll: selectIsLoadingReadAllNotifications(state),
  isLoadingNotifications: selectNotificationLoading(state),
});
