import { Elements } from '@stripe/react-stripe-js';
import paymentSuccess from 'assets/lottie-animations/payment_success.json';
import Lottie from 'lottie-react';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useToggle } from '../../../../hooks/use-toggle';
import { SpinnerLoader } from '../../../ui-core';
import SvgIcon from '../../../ui-core/svg-icon';
import modalSelector from '../../modal-container.selectors';

import { CheckoutForm } from './checkout-form';

import './styles.scss';

const getOptions = (clientSecret) => {
  const defaultOptions = {
    appearance: {
      theme: 'night',
    },
    loader: 'always',
  };

  if (!clientSecret) return defaultOptions;

  return {
    clientSecret,
    ...defaultOptions,
  };
};

export const StripeModal = () => {
  const { additionalData } = useSelector(modalSelector);

  const { stripe, clientSecret, subscriptionName, price, ...restProps } = additionalData;

  const [isProcessing, toggleIsProcessing] = useToggle();

  const options = useMemo(() => getOptions(clientSecret), [clientSecret]);

  return (
    <div className="stripe-modal">
      {isProcessing && <SpinnerLoader />}
      <div className="stripe-modal-intro">
        <div className="stripe-modal-intro-animation">
          <Lottie animationData={paymentSuccess} loop={false} />
        </div>
        <h4 className="stripe-modal-intro-title">PAYMENT</h4>
        <p className="stripe-modal-intro-descr">All transactions are secure and encrypted</p>
      </div>
      <div className="stripe-modal-elements">
        <Elements stripe={stripe} options={options}>
          <CheckoutForm
            clientSecret={clientSecret}
            toggleIsProcessing={toggleIsProcessing}
            subscriptionName={subscriptionName}
            price={price}
            {...restProps}
          />
        </Elements>
      </div>
      <div className="stripe-modal-power-by">
        <SvgIcon type="power-by-stripe" />
      </div>
    </div>
  );
};
