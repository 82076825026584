import dayjs from 'dayjs';

const groupMessagesByDays = (messages) => {
  return messages.reduce((acc, message, idx, array) => {
    const messageDay = dayjs(message.createDate).format('MMMM DD YYYY');

    const updMessage = {
      ...message,
      isLastMessage: idx === array.length - 5,
      isFirstMessage: idx === 10,
    };

    if (acc[messageDay]) {
      return { ...acc, [messageDay]: [...acc[messageDay], updMessage] };
    }

    return { ...acc, [messageDay]: [updMessage] };
  }, {});
};

const mentionRegex = /@([\p{L}\p{N}\s]+)/gu;

const replaceMentionWithId = (message) => {
  if (message.includes('data-id')) {
    const replacedMessage = message.replace(mentionRegex, (match, name) => {
      const dataId = document.querySelector(`[data-label="${name}"]`).getAttribute('data-id');
      return `<@${dataId}>`;
    });

    return replacedMessage;
  }

  return message;
};

const mentionIdRegex = /<@(\d+|everyone|here)>/g;

const replaceIdWithMention = (message) => {
  if (message.includes('data-id')) {
    const replacedMessage = message.replace(mentionIdRegex, (match, number) => {
      const dataLabel = document.querySelector(`[data-id="${number}"]`).getAttribute('data-label');
      return `@${dataLabel}`;
    });

    return replacedMessage;
  }

  return message;
};

const parserOptions = {
  replace: (domNode) => {
    if (
      domNode &&
      domNode.name === 'p' &&
      domNode.children?.some((item) => item.attribs?.['data-type'] === 'mention')
    ) {
      return (
        <p>
          {!!domNode.children?.length &&
            domNode.children.map((item) => {
              if (item.attribs && item.attribs['data-type'] === 'mention') {
                return (
                  <span
                    data-type="mention"
                    className="mention"
                    data-id={`${item.attribs['data-id']}`}
                    data-label={`${item.attribs['data-label']}`}
                  >
                    {`@${item.attribs['data-label']}`}
                  </span>
                );
              }

              if (item.name === 'strong') {
                return <strong>{item.data || item.children[0].data}</strong>;
              }

              if (item.name === 'em') {
                return <em>{item.data || item.children[0].data}</em>;
              }

              if (item.name === 'u') {
                return <u>{item.data || item.children[0].data}</u>;
              }

              if (item.name === 's') {
                return <s>{item.data || item.children[0].data}</s>;
              }

              if (item.name === 'a') {
                return (
                  <a href={item.attribs?.href} rel="noopener noreferrer nofollow" target="_blank">
                    {item.data || item.children[0].data}
                  </a>
                );
              }

              return item?.data || item?.children[0]?.data;
            })}
        </p>
      );
    }
  },
};

export { groupMessagesByDays, replaceMentionWithId, replaceIdWithMention, parserOptions };
