/* eslint-disable no-underscore-dangle */
import {
  getCalendarEventByIdOperation,
  getCalendarListOperation,
  postCalendarEventSubscribeByIdOperation,
  postCalendarEventUnsubscribeByIdOperation,
} from './calendar.thunk';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  events: {
    thisMonthEvents: [],
    todayEvents: [],
    upcomingEvents: [],
  },
  activeCalendarID: null,
  error: null,
};

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCalendarListOperation.fulfilled, (state, { payload }) => {
        state.events = payload.calendar;
      })
      .addCase(getCalendarListOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(getCalendarEventByIdOperation.fulfilled, () => {})
      .addCase(getCalendarEventByIdOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(postCalendarEventSubscribeByIdOperation.fulfilled, (state, { payload }) => {
        state.events = payload;
      })
      .addCase(postCalendarEventSubscribeByIdOperation.rejected, () => {})
      .addCase(postCalendarEventUnsubscribeByIdOperation.fulfilled, (state, { payload }) => {
        state.events = payload;
      })
      .addCase(postCalendarEventUnsubscribeByIdOperation.rejected, () => {});
  },
});

export default calendarSlice.reducer;
