import { useEffect, useRef } from 'react';

import cl from 'classnames';
import { useMount } from 'hooks';
import { useDispatch, useSelector } from 'react-redux';

import MODALS from './components';
import modalSelector from './modal-container.selectors';
import { setModal } from './modal-container.slice';

import './styles.scss';

const ModalContainer = () => {
  const wrapperRef = useRef(null);
  const dispatch = useDispatch();

  const { isModalVisible, modalType, withAppearance, fullScreen } = useSelector(modalSelector);
  const isMounted = useMount(isModalVisible);

  const handleKeyUpClick = (event) => {
    if (event.key === 'Escape' && isModalVisible) {
      dispatch(setModal({ isModalVisible: false }));
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        if (isModalVisible) {
          dispatch(setModal({ isModalVisible: false }));
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUpClick);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUpClick);
    };
  }, [wrapperRef, isModalVisible]);

  const contentClasses = cl('modal__content', {
    'modal-fullscreen__content': fullScreen,
    'modal__content--with-appearance': withAppearance,
  });

  return (
    <div
      className={`modal${!isModalVisible ? '--hidden' : ''} ${fullScreen && 'modal-fullscreen'}`}
    >
      {isMounted ? (
        <div className={contentClasses} ref={wrapperRef}>
          {MODALS[modalType]}
        </div>
      ) : null}
    </div>
  );
};

export default ModalContainer;
