export const PAYMENT_SYSTEMS = {
  WAY_FOR_PAY: 'WAY_FOR_PAY',
  PAY_PAL: 'PAY_PAL',
  BIT_PAY: 'BIT_PAY',
};

export const PRODUCT_TYPES = {
  COURSE: 'course',
  COMMUNITY: 'community',
};
