import { getBrandOperation } from './brand.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  benefits: [],
  businessAccount: {},
  communities: [],
  courses: [],
  events: [],
  reviews: [],
  error: '',
};

const brandSlice = createSlice({
  name: 'brand',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getBrandOperation.fulfilled, (state, { payload }) => {
        state.benefits = payload.benefits;
        state.businessAccount = payload.businessAccount;
        state.communities = payload.communities;
        state.courses = payload.courses;
        state.events = payload.events;
        state.reviews = payload.reviews;
        state.error = '';
      })
      .addCase(getBrandOperation.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default brandSlice.reducer;
