import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const Number = ({ label, name, register, placeholder, error, mask }) => {
  return (
    <div className="field-container">
      {label && (
        <label htmlFor={name || register.name} className="field__label">
          {label}
        </label>
      )}
      {error && <span className={`field__error${label ? '' : '--without-label'}`}>{error}</span>}
      {mask ? (
        <InputMask
          mask={mask}
          maskChar=""
          placeholder={placeholder}
          className="field"
          {...register}
        />
      ) : (
        <input
          id={name}
          className="field"
          type="number"
          placeholder={placeholder}
          {...register(name)}
        />
      )}
    </div>
  );
};

Number.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  mask: PropTypes.string,
};

Number.defaultProps = {
  label: '',
  placeholder: '',
  error: '',
  name: '',
  mask: '',
};
export default Number;
