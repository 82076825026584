import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import {
  getCalendarEventByID,
  getCalendarList,
  postCalendarEventSubscribeByID,
  postCalendarEventUnsubscribeByID,
} from 'services/requests/calendar';

import { getCalendarSelector } from './calendar.selectors';
import updateEventById from './calendar.utils';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCalendarListOperation = createAsyncThunk(
  'calendar/GET_CALENDAR_LIST',
  async ({ requestData }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoaderVisibility(true));

      const { block } = await getCalendarList(requestData);

      dispatch(setLoaderVisibility(false));

      return block;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      await dispatch(setLoaderVisibility(false));

      return rejectWithValue(error);
    }
  },
);

export const getCalendarEventByIdOperation = createAsyncThunk(
  'calendar-view/GET_CALENDAR_EVENT_BY_ID',
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const { block } = await getCalendarEventByID(id);

      return block;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      await dispatch(setLoaderVisibility(false));

      return rejectWithValue(error);
    }
  },
);

export const postCalendarEventSubscribeByIdOperation = createAsyncThunk(
  'calendar-view/POST_CALENDAR_EVENT_SUBSCRIBE_BY_ID',
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const { calendar } = await postCalendarEventSubscribeByID({ id, ...params });

      const calendarState = getCalendarSelector(getState());

      return updateEventById(calendarState, id, calendar);
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

export const postCalendarEventUnsubscribeByIdOperation = createAsyncThunk(
  'calendar-view/POST_CALENDAR_EVENT_UNSUBSCRIBE_BY_ID',
  async ({ id, params }, { getState, rejectWithValue }) => {
    try {
      const { calendar } = await postCalendarEventUnsubscribeByID({ id, ...params });

      const calendarState = getCalendarSelector(getState());

      return updateEventById(calendarState, id, calendar);
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);
