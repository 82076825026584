/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */

function activateCrossDomainRequest() {
  const corsApiHost = 'HttpQueryParamywhere.herokuapp.com';
  const corsApiUrl = `https://${corsApiHost}/`;

  const slice = [].slice;
  const origin = `${window.location.protocol}//${window.location.host}`;
  const open = XMLHttpRequest.prototype.open;

  XMLHttpRequest.prototype.open = function () {
    const args = slice.call(arguments);

    const targetOrigin = /^https?:\/\/([^/]+)/i.exec(args[1]);
    // brat, sorry :D sprosi u Ruslana about that shit
    if (
      targetOrigin &&
      targetOrigin[0].toLowerCase() !== origin &&
      targetOrigin[1] !== corsApiHost
    ) {
      if (
        !(args[1].includes('backend') || args[1].includes('comms') || args[1].includes('amplitude'))
      ) {
        args[1] = corsApiUrl + args[1];
      }
    }

    return open.apply(this, args);
  };
}

activateCrossDomainRequest();
