import cl from 'classnames';
import React, { useEffect, useState } from 'react';

import './styles.scss';

export const Stopwatch = (props) => {
  const { isRunning, withHours = false, className, onChangeTime } = props;

  const [time, setTime] = useState(0);

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(
        () =>
          setTime((prevState) => {
            onChangeTime?.(prevState + 1);
            return prevState + 1;
          }),
        10,
      );
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  const hours = Math.floor(time / 360000);

  const minutes = Math.floor((time % 360000) / 6000);

  const seconds = Math.floor((time % 6000) / 100);

  const milliseconds = time % 100;

  const rootClasses = cl('stopwatch-container', className);

  if (!isRunning) return null;

  return (
    <div className={rootClasses}>
      <p className="stopwatch-time">
        {withHours ? `${hours}:` : null}
        {minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}:
        {milliseconds.toString().padStart(2, '0')}
      </p>
    </div>
  );
};
