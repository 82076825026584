import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import Button from '../ui-core/button';
import BUTTON_TYPES, { BUTTON_SIZES } from '../ui-core/button/button.constants';
import colors from '../../assets/styles/variables.scss';
import useCookieEnabled from '../../hooks/useCookieEnabled';

import './styles.scss';

const CookiesBar = () => {
  const storageValue = sessionStorage.getItem('isCookieInformationAgreed');
  const [isCookieInformationAgreed, setIsCookieInformationAgreed] = useState(storageValue);
  const { t } = useTranslation();

  const isCookieEnabled = useCookieEnabled();

  if (isCookieInformationAgreed || isCookieEnabled) return null;

  const handleButtonClick = () => {
    sessionStorage.setItem('isCookieInformationAgreed', true);
    setIsCookieInformationAgreed(true);
  };

  return (
    <div className="cookies-bar">
      <p className="cookies-bar__title">{t('cookies.browser-disable.title')}</p>
      <p className="cookies-bar__description">{t('cookies.browser-disable.description')}</p>
      <Button
        className="cookies-bar__button"
        type={BUTTON_TYPES.BUTTON}
        content={t('cookies.browser-disable.button')}
        backgroundColor={colors['linear-2']}
        contentColor={colors.white}
        size={BUTTON_SIZES.MD}
        onClick={handleButtonClick}
      />
    </div>
  );
};

export default CookiesBar;
