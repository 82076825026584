import PropTypes from 'prop-types';
import { useState } from 'react';

import SvgIcon from 'components/ui-core/svg-icon';

const Password = ({ label, name, register, placeholder, error, disabled }) => {
  const [isPasswordField, setIsPasswordField] = useState(true);

  return (
    <div className="field-container">
      <label htmlFor={name} className="field__label">
        {label}
      </label>
      {error && <span className="field__error">{error}</span>}
      <input
        id={name}
        className={`field${disabled ? '--disabled' : ''}`}
        placeholder={placeholder}
        {...register(name)}
        type={isPasswordField ? 'password' : 'text'}
        disabled={disabled}
        autoComplete="false"
      />
      <button
        className="field--password__icon"
        type="button"
        onClick={() => {
          setIsPasswordField(!isPasswordField);
        }}
      >
        <SvgIcon type={isPasswordField ? 'eye-crossed-out' : 'eye'} />
      </button>
    </div>
  );
};

Password.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

Password.defaultProps = {
  placeholder: '',
  error: '',
  disabled: false,
};

export default Password;
