import cl from 'classnames';
import React, { forwardRef } from 'react';

import { useClassName } from '../../../../../../hooks/use-class-name';
import SvgIcon from '../../../../../ui-core/svg-icon';

import './styles.scss';

export const MenuItem = forwardRef((props, ref) => {
  const { isActive, icon, action = () => {}, ...restProps } = props;

  const { rootClassName, appendClass } = useClassName('rich-text-editor-menu-item', 'atom');

  const rootClasses = cl(rootClassName, {
    [appendClass('--active')]: isActive(),
  });

  return (
    <button ref={ref} className={rootClasses} type="button" onClick={action} {...restProps}>
      <SvgIcon className={appendClass('-icon')} type={icon} />
    </button>
  );
});
