import { adaptOTPCodeForAPI } from 'utils';

const adaptAddUserEmailFormDataForAPIFirstStep = ({ email }) => {
  const adaptedFormValues = {
    email,
  };

  return adaptedFormValues;
};

const adaptAddUserEmailFormDataForAPISecondStep = ({ otp, password }) => {
  const adaptedFormValues = {
    firstOtp: adaptOTPCodeForAPI(otp),
    password,
  };

  return adaptedFormValues;
};

export { adaptAddUserEmailFormDataForAPIFirstStep, adaptAddUserEmailFormDataForAPISecondStep };
