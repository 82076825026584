const ROUTER_BOOK = Object.freeze({
  DASHBOARD: '/dashboard',
  COMMUNITY: {
    MESSAGES: '/messages',
    BRANDS: '/brands',
    BRAND: '/brands/:id',
    COURSE: '/brands/:brandId/course/:courseId',
    DASHBOARD: '/brands/:brandId/community',
    CATEGORIES: '/brands/:brandId/community/categories',
    CATEGORY: '/brands/:brandId/community/categories/:categoryId',
    COMMUNICATION: '/brands/:brandId/community/categories/:categoryId/:communicationId',
  },
  MY_WORKSPACE: {
    MAPPING_PATH: '/my-workspace/*',
    GENERAL: '/my-workspace',
    COURSES: 'courses',
    ID: ':id',
    HOMEWORK: 'homework',
    STREAMS: 'streams',
  },
  EVENTS: '/events',
  MY_ACCOUNT: '/my-account',
  AUTH: {
    LOGIN: '/login',
    REGISTRATION: '/registration',
    FORGOT_PASSWORD: '/forgot-password',
    NEW_PASSWORD: '/new-password',
    TERMS_OF_SERVICE: '/terms-of-service',
    PRIVACY_POLICY: '/privacy-policy',
  },
  ONBOARDING: '/onboarding',
  PAYMENT_STATUS: '/payment-status',
  NOT_FOUND: '/404',
  REFERRAL_PROGRAM: '/referral-program',
  ACCESS_RULES: {
    PRIVACY_POLICY: '/privacy_policy',
    TERMS_AND_CONDITIONALS: '/terms_and_conditions',
  },
});

export default ROUTER_BOOK;
