/* eslint-disable jsx-a11y/label-has-associated-control */

/* eslint-disable jsx-a11y/label-has-for */
import PropTypes from 'prop-types';
import React, { forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CONTENT_TYPES, post } from 'services/api';

import {
  ACCEPTABLE_FILE_FORMATS,
  ACCEPTABLE_IMAGE_FORMATS,
  ACCEPTABLE_VIDEO_FORMATS,
} from 'components/attachment/attachment.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import './styles.scss';

const AttachmentUploader = forwardRef((props, ref) => {
  const { mediaServerData, onChange, files } = props;

  const { t } = useTranslation();

  const handleInputChange = (event) => {
    const fileList = Array.from(event.target.files);

    if (fileList.length > 8) {
      return toast.error(t('errors.max-8-files'));
    }

    const fls = fileList.map((file) => ({
      file,
      uploading: false,
      progress: 0,
      preview: URL.createObjectURL(file),
    }));

    onChange(fls);
  };

  const startUploading = async (message) => {
    const formData = new FormData();

    // generate form data from attached files
    files.forEach((file, index) => {
      file.uploading = true;

      onChange([...files]);

      formData.append(index, file.file);
    });
    // append message and active channel id
    formData.append(
      'payload',
      JSON.stringify({
        message,
        ...mediaServerData.formDataInfo,
      }),
    );

    try {
      // trigger file uploading
      await post(mediaServerData.url, formData, {
        ...CONTENT_TYPES.FORM_DATA,
        onUploadProgress: (progressEvent) => {
          const totalProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100);

          // Update the progress of each file
          files.forEach((file) => {
            if (file.uploading) {
              file.progress = totalProgress;
            }
          });

          onChange([...files]);
        },
      });
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
    }

    // clear files state
    onChange([]);
  };

  const createAcceptableFormatsString = () => {
    return `${ACCEPTABLE_IMAGE_FORMATS.toString()}${ACCEPTABLE_VIDEO_FORMATS.toString()},${ACCEPTABLE_FILE_FORMATS.toString()}`;
  };

  useImperativeHandle(ref, () => ({ files, startUploading }));

  return (
    <div className="attachment-uploader">
      <label className="attachment-uploader__label">
        <input
          type="file"
          multiple
          onChange={handleInputChange}
          accept={createAcceptableFormatsString()}
        />
        <SvgIcon type="paperclip" />
      </label>
    </div>
  );
});

AttachmentUploader.propTypes = {
  mediaServerData: PropTypes.shape({
    url: PropTypes.string,
    formDataInfo: PropTypes.object,
  }).isRequired,
};

export default AttachmentUploader;
