/* eslint-disable consistent-return */
import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import { getPurchasedCoursesBlocksRequest } from 'services/requests/blocks';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getCoursesBlocksOperation = createAsyncThunk(
  'courses/GET_COURSES_BLOCKS',
  async (requestData, { rejectWithValue }) => {
    try {
      const purchasedCourses = await getPurchasedCoursesBlocksRequest(requestData);

      return purchasedCourses.block.courses;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getCoursesPageOperation = createAsyncThunk(
  'courses/GET_COURSES_PAGE',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(getCoursesBlocksOperation(requestData));

    await dispatch(setLoaderVisibility(false));
  },
);

export { getCoursesBlocksOperation, getCoursesPageOperation };
