import API_LIST from '../../../constants/api/api-list';
import { post } from '../../api';

export const postBuyFreePriceTariffById = async ({ id }) => {
  const url = `${API_LIST.PRICE.COMMUNITY}`;

  try {
    const { data } = await post(`${url}/${id}/buy`);

    return data;
  } catch (err) {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  }
};
