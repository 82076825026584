import cl from 'classnames';

import './styles.scss';

const SpinnerCircle = (props) => {
  const { className, ...restProps } = props;

  const rootClasses = cl('lds-ring', className);

  return (
    <div className={rootClasses} {...restProps}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

export default SpinnerCircle;
