import i18n from 'localization/localization.config';

import { getCoursesBlocksOperation } from './courses.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  coursesList: null,
  error: '',
  coursesListLanguage: i18n.language,
};

const coursesSlice = createSlice({
  name: 'purchasedCourses',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(getCoursesBlocksOperation.fulfilled, (state, { payload }) => {
        state.coursesList = payload;
        state.coursesListLanguage = i18n.language;
        state.error = '';
      })
      .addCase(getCoursesBlocksOperation.rejected, (state, { payload }) => {
        state.error = payload;
      });
  },
});

export default coursesSlice.reducer;
