import AUTH_TYPES from 'constants/auth/auth.types';

import FORM_NAMES from './auth.constants';
import {
  sendOTPCodeForForgotPasswordFormOperation,
  sendSignInThroughEmailOperation,
  sendSignInThroughPhoneOperation,
  sendSignUpThroughEmailOperation,
  sendSignUpThroughPhoneOperation,
  verifyOTPOperation,
} from './auth.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  preferredAuthType: AUTH_TYPES.EMAIL,
  isUserHasAccess: false,
  forms: {
    [FORM_NAMES.SIGN_UP_THROUGH_EMAIL]: {
      email: '',
      // password: '',
      otp: '',
      isOTPFieldVisible: false,
    },
    [FORM_NAMES.SIGN_IN_THROUGH_EMAIL]: {
      email: '',
      // password: '',
      otp: '',
      isOTPFieldVisible: false,
    },
    [FORM_NAMES.SIGN_IN_THROUGH_PHONE]: {
      phoneNumber: '',
      otp: '',
      isOTPFieldVisible: false,
    },
    [FORM_NAMES.SIGN_UP_THROUGH_PHONE]: {
      phoneNumber: '',
      // password: '',
      otp: '',
      isOTPFieldVisible: false,
    },
  },
  userEmailForForgotPasswordForm: '',
  isReCaptchaLoading: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setPreferredAuthType(state, { payload }) {
      state.preferredAuthType = payload;
    },
    setIsReCaptchaLoading(state, { payload }) {
      state.isReCaptchaLoading = payload;
    },
    setIsUserHasAccess(state, { payload }) {
      state.isUserHasAccess = payload;
    },
    setIsOTPFieldVisible(state, { payload: { formType, isVisible } }) {
      state.forms[formType] = {
        ...state.forms[formType],
        isOTPFieldVisible: isVisible,
      };
    },

    saveFormData(state, { payload }) {
      state.forms[payload.form] = {
        ...state.forms[payload.form],
        ...payload,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(sendSignUpThroughEmailOperation.fulfilled, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_UP_THROUGH_EMAIL] = {
          ...state.forms[FORM_NAMES.SIGN_UP_THROUGH_EMAIL],
          ...actionData.meta.arg,
          isOTPFieldVisible: true,
        };
      })
      .addCase(sendSignUpThroughEmailOperation.rejected, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_UP_THROUGH_EMAIL] = {
          ...state.forms[FORM_NAMES.SIGN_UP_THROUGH_EMAIL],
          ...actionData.meta.arg,
        };
      })
      .addCase(sendSignUpThroughPhoneOperation.fulfilled, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_UP_THROUGH_PHONE] = {
          ...state.forms[FORM_NAMES.SIGN_UP_THROUGH_PHONE],
          ...actionData.meta.arg,
          isOTPFieldVisible: true,
        };
      })
      .addCase(sendSignUpThroughPhoneOperation.rejected, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_UP_THROUGH_PHONE] = {
          ...state.forms[FORM_NAMES.SIGN_UP_THROUGH_PHONE],
          ...actionData.meta.arg,
        };
      })
      .addCase(sendSignInThroughEmailOperation.fulfilled, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_IN_THROUGH_EMAIL] = {
          ...state.forms[FORM_NAMES.SIGN_IN_THROUGH_EMAIL],
          ...actionData.meta.arg,
          isOTPFieldVisible: true,
        };
      })
      .addCase(sendSignInThroughEmailOperation.rejected, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_IN_THROUGH_EMAIL] = {
          ...state.forms[FORM_NAMES.SIGN_IN_THROUGH_EMAIL],
          ...actionData.meta.arg,
        };
      })
      .addCase(sendSignInThroughPhoneOperation.fulfilled, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_IN_THROUGH_PHONE] = {
          ...state.forms[FORM_NAMES.SIGN_IN_THROUGH_PHONE],
          ...actionData.meta.arg,
          isOTPFieldVisible: true,
        };
      })
      .addCase(sendSignInThroughPhoneOperation.rejected, (state, actionData) => {
        state.forms[FORM_NAMES.SIGN_IN_THROUGH_EMAIL] = {
          ...state.forms[FORM_NAMES.SIGN_IN_THROUGH_EMAIL],
          ...actionData.meta.arg,
        };
      })
      .addCase(verifyOTPOperation.fulfilled, (state) => {
        state.isUserHasAccess = true;
        state.forms = {
          ...initialState.forms,
        };
      })
      .addCase(sendOTPCodeForForgotPasswordFormOperation.fulfilled, (state, actionData) => {
        state.userEmailForForgotPasswordForm = actionData.meta.arg.email;
      });
  },
});

export const {
  setPreferredAuthType,
  setIsUserHasAccess,
  setIsOTPFieldVisible,
  saveFormData,
  setIsReCaptchaLoading,
} = authSlice.actions;

export default authSlice.reducer;
