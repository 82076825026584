/* eslint-disable operator-linebreak */
import {
  getHomeworkFromUserCourseOperation,
  getTestExercisesOperation,
  getUnverifiedHomeworkAnswersOperation,
  getVerifiedTestAnswersOperation,
  getVerifiedWritingAnswersOperation,
  getWritingExercisesOperation,
  sendWritingExercisesAnswerOperation,
} from './homework.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  homework: {
    toBeSent: [],
    unverified: [],
    verified: [],
  },
  selectedCourse: null,
  error: '',
  isLoading: false,
};

const homeworkSlice = createSlice({
  name: 'homework',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(
        getHomeworkFromUserCourseOperation.fulfilled,
        (state, { payload: { homeworkList, selectedCourse } }) => {
          // checking if the user already has uploaded exercises. If user have them, we just copy them to the updated homework array
          if (homeworkList.toBeSent.length) {
            state.homework.toBeSent.forEach((existedHomeworkItem) => {
              // checking if the user already has uploaded exercises
              if (existedHomeworkItem.exercises) {
                // if the user has uploaded exercises - remember the index of incoming homework
                const indexOfIncomingHomework = homeworkList.toBeSent.findIndex(
                  (incomingHomeworkItem) => incomingHomeworkItem.id === existedHomeworkItem.id,
                );

                // updating incoming homework
                if (homeworkList.toBeSent[indexOfIncomingHomework]) {
                  homeworkList.toBeSent[indexOfIncomingHomework].exercises =
                    existedHomeworkItem.exercises;
                }
              }
            });
          }

          // checking if the user already has uploaded answers or reviews. If user have them, we just copy them to the updated homework array
          if (homeworkList.verified.length) {
            state.homework.verified.forEach((existedHomeworkItem) => {
              // checking if the user already has uploaded answers
              if (existedHomeworkItem.answers) {
                // if the user has uploaded answers - remember the index of incoming homework
                const indexOfIncomingHomework = homeworkList.verified.findIndex(
                  (incomingHomeworkItem) => incomingHomeworkItem.id === existedHomeworkItem.id,
                );

                if (homeworkList.verified[indexOfIncomingHomework]) {
                  // updating incoming homework
                  homeworkList.verified[indexOfIncomingHomework].answers =
                    existedHomeworkItem.answers;

                  // checking if user has uploaded review
                  if (existedHomeworkItem.review) {
                    homeworkList.verified[indexOfIncomingHomework].review =
                      existedHomeworkItem.review;
                  }
                }
              }
            });
          }

          state.homework = homeworkList;
          state.selectedCourse = selectedCourse;
          state.error = '';
        },
      )
      .addCase(getHomeworkFromUserCourseOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(getTestExercisesOperation.fulfilled, (state, { payload, meta: { arg } }) => {
        state.homework.toBeSent.forEach((homework) => {
          if (homework.id === arg.homeworkID) {
            homework.exercises = payload;
          }
        });
      })
      .addCase(getTestExercisesOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(getWritingExercisesOperation.fulfilled, (state, { payload, meta: { arg } }) => {
        state.homework.toBeSent.forEach((homework) => {
          if (homework.id === arg.homeworkID) {
            homework.exercises = payload;
          }
        });
      })
      .addCase(getWritingExercisesOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(
        getUnverifiedHomeworkAnswersOperation.fulfilled,
        (state, { payload, meta: { arg } }) => {
          state.homework.unverified.forEach((homework) => {
            if (homework.id === arg.homeworkID) {
              homework.answers = payload;
            }
          });
        },
      )
      .addCase(getUnverifiedHomeworkAnswersOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(getVerifiedTestAnswersOperation.fulfilled, (state, { payload, meta: { arg } }) => {
        state.homework.verified.forEach((homework) => {
          if (homework.id === arg.homeworkID) {
            homework.answers = payload;
          }
        });
      })
      .addCase(getVerifiedTestAnswersOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(
        getVerifiedWritingAnswersOperation.fulfilled,
        (state, { payload, meta: { arg } }) => {
          state.homework.verified.forEach((homework) => {
            if (homework.id === arg.homeworkID) {
              homework.answers = payload.answers;
              homework.review = payload.review;
            }
          });
        },
      )
      .addCase(getVerifiedWritingAnswersOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(sendWritingExercisesAnswerOperation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendWritingExercisesAnswerOperation.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
      })
      .addCase(sendWritingExercisesAnswerOperation.fulfilled, (state) => {
        state.isLoading = false;
      });
  },
});

export default homeworkSlice.reducer;
