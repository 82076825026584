import { nanoid } from '@reduxjs/toolkit';
import PropTypes from 'prop-types';
import React from 'react';
import { getDataFromImageURL } from 'utils/images';

import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import './styles.scss';

const ZoomImageControls = ({ zoom, setZoom, image }) => {
  const handleZoomChange = (event) => {
    setZoom(parseFloat(event.target.value));
  };

  const decreaseZoom = () => {
    if (zoom > 0.5) {
      setZoom(zoom - 0.5);
    }
  };

  const increaseZoom = () => {
    if (zoom < 7) {
      setZoom(zoom + 0.5);
    }
  };

  const handleDownloadButtonClick = async () => {
    const fakeLink = document.createElement('a');
    fakeLink.href = await getDataFromImageURL(image);
    fakeLink.download = `${nanoid()}.png`;
    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);
  };

  return (
    <div className="zoom-image-controls">
      <Button
        onClick={handleDownloadButtonClick}
        leftIcon={<SvgIcon type="download" />}
        size={BUTTON_SIZES.ICON}
        className="fit-content"
      />
      <Button
        onClick={decreaseZoom}
        leftIcon={<SvgIcon type="minus" />}
        size={BUTTON_SIZES.ICON}
        className="fit-content"
      />
      <input type="range" min="0.5" max="7" step="0.5" value={zoom} onChange={handleZoomChange} />
      <Button
        onClick={increaseZoom}
        leftIcon={<SvgIcon type="plus" />}
        size={BUTTON_SIZES.ICON}
        className="fit-content"
      />
    </div>
  );
};

ZoomImageControls.propTypes = {
  zoom: PropTypes.number.isRequired,
  setZoom: PropTypes.func.isRequired,
  image: PropTypes.string.isRequired,
};

export default ZoomImageControls;
