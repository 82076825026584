/* eslint-disable consistent-return */
import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import { adaptUserPhoneNumberFormDataForAPIFirstStep } from 'pages/lms/my-account/components/add-phone/components/add-phone-form.utils';
import {
  sendOTPToUserPhoneOperation,
  updateUserPersonalInformationOperation,
} from 'pages/lms/my-account/my-account.thunks';
import { getOnboardingInterestsCategoriesRequest } from 'services/requests/blocks';
import sendSelectedInterestsCategoriesRequest from 'services/requests/onboarding';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getInterestsCategoriesOperation = createAsyncThunk(
  'onboarding/GET_INTERESTS_CATEGORIES_OPERATION',
  async (requestData, { rejectWithValue }) => {
    try {
      const interestsCategories = await getOnboardingInterestsCategoriesRequest(requestData);

      return interestsCategories.block;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const sendSelectedInterestsCategoriesOperation = createAsyncThunk(
  'onboarding/SEND_SELECTED_INTERESTS_CATEGORIES',
  async (requestData, { rejectWithValue, dispatch }) => {
    try {
      dispatch(setLoaderVisibility(true));

      await sendSelectedInterestsCategoriesRequest(requestData);

      return requestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      dispatch(setLoaderVisibility(false));

      return rejectWithValue(error);
    }
  },
);

const getOnboardingPageOperation = createAsyncThunk(
  'onboarding/GET_ONBOARDING_PAGE',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(getInterestsCategoriesOperation(requestData));

    await dispatch(setLoaderVisibility(false));
  },
);

const setUserNameAndPhoneOperation = createAsyncThunk(
  'onboarding/SET_USER_NAME_AND_PHONE',
  async (requestData, { dispatch, rejectWithValue }) => {
    try {
      await Promise.all([
        dispatch(
          sendOTPToUserPhoneOperation(adaptUserPhoneNumberFormDataForAPIFirstStep(requestData)),
        ).unwrap(),
        dispatch(
          updateUserPersonalInformationOperation({
            name: requestData.name,
            isNeedToHideToast: true,
          }),
        ).unwrap(),
      ]);
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export {
  getInterestsCategoriesOperation,
  getOnboardingPageOperation,
  sendSelectedInterestsCategoriesOperation,
  setUserNameAndPhoneOperation,
};
