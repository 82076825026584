import axios from 'axios';

const baseURL = `${process.env.REACT_APP_DEFAULT_API_URL_PATH}`;

const apiClient = axios.create({ baseURL, withCredentials: true });

apiClient.interceptors.request.use(
  (config) => {
    const contentType = config.ContentType ? config.ContentType : 'application/json';

    config.headers['Access-Control-Allow-Credentials'] = true;
    config.headers['Content-Type'] = contentType;
    config.headers['X-User-Timezone'] = new Date().getTimezoneOffset();

    return config;
  },
  (error) => Promise.reject(error),
);

apiClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  },
);

const { get, post, put, delete: destroy, patch } = apiClient;

export const CONTENT_TYPES = {
  FORM_DATA: { ContentType: 'multipart/form-data' },
};

export { apiClient, destroy, get, patch, post, put };
