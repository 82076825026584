export const getMenuItems = (editor) => {
  return [
    {
      icon: 'bold',
      action: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      icon: 'italic',
      action: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      icon: 'underline',
      action: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline'),
    },
    {
      icon: 'strikethrough',
      action: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike'),
    },
    // { type: 'divider' },
    // {
    //   icon: 'bullet',
    //   action: () => editor.chain().focus().toggleBulletList().run(),
    //   isActive: () => editor.isActive('bulletList'),
    // },
    // {
    //   icon: 'order',
    //   action: () => editor.chain().focus().toggleOrderedList().run(),
    //   isActive: () => editor.isActive('orderedList'),
    // },
  ];
};
