import { useIsMobile } from 'hooks';
import { useSelector } from 'react-redux';

import { isUserHasAccessSelector } from '../../pages/auth/auth.selectors';

import { UnavailablePlug } from './components';
import Menu from './components/menu';

import './styles.scss';

const Sidebar = () => {
  const isMobile = useIsMobile();

  const isUserHasAccess = useSelector(isUserHasAccessSelector);

  if (isMobile) {
    return null;
  }

  return (
    <div className="sidebar">
      {!isUserHasAccess && <UnavailablePlug />}
      <Menu />
    </div>
  );
};

export default Sidebar;
