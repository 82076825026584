/* eslint-disable consistent-return */
import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import { getCourseLandingBlocksRequest } from 'services/requests/blocks';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getCourseLandingBlocksOperation = createAsyncThunk(
  'course-landing/GET_COURSE_LANDING_BLOCKS',
  async (requestData, { rejectWithValue }) => {
    try {
      const courseLandingBlocks = await getCourseLandingBlocksRequest(requestData);

      return courseLandingBlocks.block;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getCourseLandingPageOperation = createAsyncThunk(
  'course-landing/GET_COURSE_LANDING_PAGE',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(getCourseLandingBlocksOperation(requestData));

    await dispatch(setLoaderVisibility(false));
  },
);

export { getCourseLandingBlocksOperation, getCourseLandingPageOperation };
