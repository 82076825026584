import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from 'pages/auth/auth.slice';
import brandSlice from 'pages/community/brand/brand.slice';
import brandsSlice from 'pages/community/brands/brands.slice';
import courseLandingSlice from 'pages/community/course-landing/course-landing.slice';
import groupChattingSlice from 'pages/community/group-chatting/group-chatting.slice';
import privateMessagesSlice from 'pages/community/messages/messages.slice';
import calendarSlice from 'pages/lms/calendar/calendar.slice';
import dashboardSlice from 'pages/lms/dashboard/dashboard.slice';
import myAccountSlice from 'pages/lms/my-account/my-account.slice';
import courseViewSlice from 'pages/lms/my-workspace/nested-routes/course/course.slice';
import coursesSlice from 'pages/lms/my-workspace/nested-routes/courses/courses.slice';
import homeworkSlice from 'pages/lms/my-workspace/nested-routes/homework/homework.slice';
import streamsReducer from 'pages/lms/my-workspace/nested-routes/streams/streams.slice';
import paymentStatusSlice from 'pages/lms/payment-status/payment-status.slice';
import onboardingSlice from 'pages/onboarding/onboarding.slice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import errorBoundarySlice from 'components/error-boundary/error-boundary.slice';
import loaderSlice from 'components/loader/loader.slice';
import modalSlice from 'components/modal-container/modal-container.slice';

import {
  commonReducer,
  communityReducer,
  notificationReducer,
  payment,
  socketConnectionReducer,
} from './reducers';
import settingsSlice from './settings/settings.slice';
import setTransformForReCaptcha, {
  setTransformForCommunityGroupChatting,
  setTransformForMyHomeworks,
  setTransformForPrivateMessages,
} from './store.transforms';

const reducers = combineReducers({
  auth: authSlice,
  dashboard: dashboardSlice,
  loader: loaderSlice,
  settings: settingsSlice,
  modal: modalSlice,
  courseLanding: courseLandingSlice,
  paymentStatus: paymentStatusSlice,
  courseView: courseViewSlice,
  myCourses: coursesSlice,
  myAccount: myAccountSlice,
  myHomework: homeworkSlice,
  onboarding: onboardingSlice,
  groupChatting: groupChattingSlice,
  streams: streamsReducer,
  privateMessages: privateMessagesSlice,
  calendar: calendarSlice,
  brands: brandsSlice,
  brand: brandSlice,
  paymentStore: payment,
  errorBoundary: errorBoundarySlice,
  socketConnection: socketConnectionReducer,
  notification: notificationReducer,
  community: communityReducer,
  common: commonReducer,
});

const persistConfig = {
  key: 'root',
  version: '3.6',
  storage,
  blacklist: [
    'loader',
    'modal',
    'paymentStatus',
    'courseView',
    'streams',
    'paymentStore',
    'brands',
    'socketConnection',
    'notification',
    'community',
  ],
  transforms: [
    setTransformForReCaptcha,
    setTransformForCommunityGroupChatting,
    setTransformForPrivateMessages,
    setTransformForMyHomeworks,
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const rootReducer = (state, action) => {
  if (action.type === 'DESTROY_SESSION') {
    state = {
      settings: state.settings,
    };
  }

  return persistedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const destroySession = () => {
  return { type: 'DESTROY_SESSION' };
};

export const persistor = persistStore(store);

export default store;
