import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import LazyLoadImage from 'components/lazy-load-image';
import MODAL_TYPES from 'components/modal-container/modal-container.constants';

import generateTargetURL from 'utils/attachments';

import { setModal } from '../../../modal-container/modal-container.slice';

import './styles.scss';

const ImageAttachment = ({ file, attachments, index }) => {
  const dispatch = useDispatch();

  const handleAttachmentClick = () => {
    if (attachments.length === 1) {
      dispatch(
        setModal({
          isModalVisible: true,
          modalType: MODAL_TYPES['FULL-SIZE-IMAGE-WITH-ZOOM'],
          additionalData: {
            attachmentUrl: file,
          },
          withAppearance: true,
          fullScreen: true,
        }),
      );
    } else {
      dispatch(
        setModal({
          isModalVisible: true,
          modalType: MODAL_TYPES['MEDIA-GALLERY'],
          additionalData: {
            attachmentUrl: file,
            attachments,
            attachmentIndex: index,
          },
          fullScreen: true,
          withAppearance: true,
        }),
      );
    }
  };

  return (
    <LazyLoadImage imageSource={generateTargetURL(file)} handleClick={handleAttachmentClick} />
  );
};

ImageAttachment.propTypes = {
  file: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.shape({
      preview: PropTypes.string.isRequired,
      progress: PropTypes.number.isRequired,
      uploading: PropTypes.bool.isRequired,
      file: PropTypes.object.isRequired,
    }).isRequired,
  ]).isRequired,
};

export default ImageAttachment;
