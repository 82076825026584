import {
  getUserInfoOperation,
  updateUserPersonalInformationOperation,
  uploadUserAvatarOperation,
  verifyOTPAndAddUserEmailOperation,
  verifyOTPAndAddUserPhoneOperation,
} from './my-account.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountInfo: null,
  error: '',
  isLoading: true,
  businessAccount: null,
};

const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    clearAccountInfo(state) {
      state.accountInfo = initialState.accountInfo;
      state.error = initialState.error;
      state.isLoading = initialState.isLoading;
    },
    setAccountInfoData(state, { payload }) {
      state.accountInfo = payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getUserInfoOperation.fulfilled, (state, { payload }) => {
        state.accountInfo = { ...payload.user };
        state.accountInfo.interests = payload.onboards;
        state.accountInfo.subscriptions = payload.communitySubscriptions;
        state.accountInfo.permissions = payload.permissions;
        state.error = '';
        state.isLoading = false;
        state.businessAccount = payload.businessAccount;
      })
      .addCase(getUserInfoOperation.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })
      .addCase(uploadUserAvatarOperation.fulfilled, (state) => {
        state.accountInfo.image = `${state.accountInfo.image} `; // it is very important query param which trigger rerender of user avatar image
      })
      .addCase(verifyOTPAndAddUserEmailOperation.fulfilled, (state, { payload }) => {
        state.accountInfo.email = payload;
      })
      .addCase(verifyOTPAndAddUserPhoneOperation.fulfilled, (state, { payload }) => {
        state.accountInfo.phone = payload;
      })
      .addCase(updateUserPersonalInformationOperation.fulfilled, (state, { payload }) => {
        Object.keys(payload).forEach((key) => {
          // state.accountInfo[key] = payload[key];
          state.accountInfo = {
            ...state.accountInfo,
            [key]: payload[key],
          };
        });
        state.error = '';
      });
  },
});

export const { clearAccountInfo, setAccountInfoData } = myAccountSlice.actions;

export default myAccountSlice.reducer;
