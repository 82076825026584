import { useRef } from 'react';

import { useEventListener } from './use-event-listener';

export const useOnClickOutside = (handler) => {
  const ref = useRef(null);

  const listener = (event) => {
    const el = ref?.current;
    if (!el || el.contains(event?.target || null)) {
      return;
    }

    handler(event);
  };

  useEventListener('mousedown', listener);
  useEventListener('touchstart', listener);

  return ref;
};
