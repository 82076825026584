import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { socketActionCollection } from 'constants';
import { socketService } from 'services/socket';

const { privateChat } = socketActionCollection;

export const connectToPrivateMessagesSocket = createAsyncThunk(
  'messages/connectToMessageChannel',
  () => {
    socketService.send(privateChat.send.privateChatConnect);

    return {
      payload: null,
    };
  },
);
export const joinToPrivateChatById = createAction('messages/joinToChatById', (payload) => {
  socketService.send(privateChat.send.privateChatByIdConnect, {
    privateChatId: payload,
  });

  return {
    payload,
  };
});
export const createPrivateChat = createAsyncThunk('messages/createPrivateChat', async (payload) => {
  socketService.send(privateChat.send.privateChatCreate, {
    participantId: payload,
  });
});

export const receivePrivateChats = createAsyncThunk(
  'messages/receivePrivateChats',
  async (payload, { getState, dispatch }) => {
    const { recipientIdFromCommunity, activeChat } = getState().privateMessages;

    const { chatId } = activeChat;

    let activeChatId = null;

    const chatsAlreadyIncludeCompanion = payload.some((chat) => {
      return chat.participants.some((participant) => {
        if (participant.id === recipientIdFromCommunity) {
          activeChatId = chat.chatInfo.id;
          dispatch(joinToPrivateChatById(chat.chatInfo.id));
          return true;
        }
        return false;
      });
    });

    if (!recipientIdFromCommunity && !chatsAlreadyIncludeCompanion && !activeChatId) {
      dispatch(joinToPrivateChatById(chatId));
      activeChatId = chatId;
    }

    if (recipientIdFromCommunity && !chatsAlreadyIncludeCompanion) {
      dispatch(createPrivateChat(recipientIdFromCommunity));
    }

    return {
      chats: payload,
      activeChatId,
    };
  },
);

export const setActiveChat = createAsyncThunk(
  'messages/setActiveChat',
  async (payload, { getState }) => {
    const owner = getState().myAccount.accountInfo;
    const companion = payload.participants.find((participant) => participant.id !== owner.id);

    return {
      messages: payload.messages,
      owner,
      companion,
      isLastMessagePresent: payload.pagination.lastMessagePresent,
      isFirstMessagePresent: payload.pagination.firstMessagePresent,
    };
  },
);

export const toggleEmoji = createAction('messages/toggleEmoji', (payload) => {
  const data = {
    messageId: payload.messageId,
    reaction: payload.reaction,
    privateChatId: payload.privateChatId,
  };

  socketService.send(privateChat.send.privateChatEmojiToggle, data);

  return {
    payload,
  };
});

export const sendPrivateMessage = createAction('messages/sendPrivateMessage', (payload) => {
  socketService.send(privateChat.send.privateChatSendMessage, payload);

  return {
    payload,
  };
});

export const receivePrivateMessage = createAsyncThunk(
  'messages/receivePrivateMessage',
  async (payload, { getState }) => {
    const accountId = getState().myAccount.accountInfo.id;

    return { ...payload, accountId };
  },
);

export const getPrivateMessages = createAsyncThunk(
  'messages/getPrivateMessages',
  async (payload, { getState }) => {
    const { messageId, direction } = payload;
    const { chatId } = getState().privateMessages.activeChat;

    const data = {
      privateChatId: chatId,
      limit: 50,
      paginationMessageId: messageId,
      direction,
      skip: 0,
    };

    socketService.send(privateChat.send.privateChatMessagesGet, data);

    return {
      messageId,
    };
  },
);

export const disconnectPrivateChat = createAction('messages/disconnectPrivateChat', () => {
  socketService.send(privateChat.send.privateChatDisconnect);

  return {
    payload: null,
  };
});

export const receivePrivateMessages = createAsyncThunk(
  'messages/receivePrivateMessages',
  async (payload) => {
    return {
      messages: payload.messages,
      isFirstMessagePresent: payload.pagination.firstMessagePresent,
      isLastMessagePresent: payload.pagination.lastMessagePresent,
      direction: payload.pagination.direction,
    };
  },
);

export const editPrivateMessage = createAsyncThunk(
  'messages/editPrivateMessage',
  async (payload, { getState }) => {
    const { message, messageId, attachments } = payload;
    const { chatId } = getState().privateMessages.activeChat;

    const data = {
      message,
      attachments,
      privateChatId: chatId,
      messageId,
    };

    socketService.send(privateChat.send.privateChatMessageEdit, data);
  },
);

export const receiveEditMessage = createAsyncThunk(
  'messages/receiveEditMessage',
  async (payload, { getState }) => {
    const messages = getState().privateMessages.activeChat.messages;

    const updatedMessages = messages.map((message) => {
      if (message.id === payload.id) {
        return payload;
      }
      return message;
    });

    return {
      messages: updatedMessages,
    };
  },
);

export const receiveEmojiToggle = createAsyncThunk(
  'messages/receiveEmojiToggle',
  async (payload) => {
    return payload;
  },
);

export const viewPrivateMessages = createAsyncThunk(
  'messages/viewPrivateMessages',
  async (payload) => {
    const { privateChatId, messageIds } = payload;

    const data = {
      privateChatId,
      messageIds,
    };

    socketService.send(privateChat.send.privateChatMessageView, data);
  },
);

export const setUserActiveStatus = createAsyncThunk(
  'messages/setUserIsOffline',
  async (payload) => {
    return payload;
  },
);

export const receiveViewPrivateMessages = createAsyncThunk(
  'messages/receiveViewPrivateMessages',
  async (payload) => {
    return payload;
  },
);

export const receivePrivateChatError = createAsyncThunk(
  'messages/receivePrivateChatError',
  async (payload) => {
    return payload;
  },
);

export const setRecipientIdFromCommunity = createAsyncThunk(
  'messages/setRecepientFromCommunity',
  async (payload) => {
    return payload;
  },
);

export const receiveCreatedChat = createAsyncThunk(
  'messages/receiveCreatedChat',
  async (payload, { dispatch }) => {
    dispatch(joinToPrivateChatById(payload.chatInfo.id));
    return payload;
  },
);

export const receiveUnreadChat = createAsyncThunk('messages/receiveUnreadChat', async (payload) => {
  return payload;
});

export const viewPrivateMessageLocale = createAsyncThunk(
  'messages/viewPrivateMessageLocale',
  async (payload) => {
    return payload;
  },
);
