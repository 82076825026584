import { yupResolver } from '@hookform/resolvers/yup';
import colors from 'assets/styles/variables.scss';
import API_LIST from 'constants/api/api-list';
import { RecorderAudioProvider, RecorderProvider } from 'context';
import { replaceMentionWithId } from 'pages/community/community.utils';
import { selectCommunityActiveChannel } from 'pages/community/group-chatting/group-chatting.selectors';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { removeHtmlElementsFromString } from 'utils/index';

import { RichTextEditor } from 'components/atoms/fields/rich-text-editor/rich-text-editor';
import { useHandleFiles } from 'components/chat-form/_hooks/use-handle-files';
import { FilesDropArea } from 'components/chat-form/files-drop-area/files-drop-area';
import { FormFooter } from 'components/chat-form/form-footer/form-footer';
import { setModal } from 'components/modal-container/modal-container.slice';
import PRIVATE_MESSAGE_ITEM_TYPES from 'components/private-message-item/private-message-item.constants';
import Button from 'components/ui-core/button';
import BUTTON_TYPES, {
  BUTTON_SIZES,
  BUTTON_VARIANTS,
} from 'components/ui-core/button/button.constants';
import Input from 'components/ui-core/inputs';

import { editPrivateMessage, sendEditCommunityMessage } from '../../../../../../store/actions';

import editMessageFormSchema from './schemas';

import './styles.scss';

const EditMessageForm = ({ messageData: { message, id }, attachments, messageType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { channelId } = useSelector(selectCommunityActiveChannel);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(editMessageFormSchema),
    defaultValues: {
      message:
        messageType === PRIVATE_MESSAGE_ITEM_TYPES.PRIVATE
          ? removeHtmlElementsFromString(message)
          : message,
    },
  });

  // const [isSubmitting, toggleIsSubmitting] = useToggle();

  const editorInstance = useRef(null);
  // const fileUploaderRef = useRef(null);

  const { files, isDragOver, actions } = useHandleFiles();

  const attachmentMediaServerData = {
    url: `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}${API_LIST.MEDIA_SERVER.UPLOAD_CHANNEL_ATTACHMENTS}`,
    formDataInfo: {
      communicationItemId: channelId,
    },
  };

  const onSubmit = (editMessageFormData) => {
    const payload = {
      message: replaceMentionWithId(editMessageFormData.message),
      attachments,
      communicationItemId: channelId,
      messageId: id,
    };
    dispatch(sendEditCommunityMessage(payload));

    dispatch(setModal({ isModalVisible: false }));
  };

  const onSubmitPrivateMessage = (editMessageFormData) => {
    const payload = { messageId: id, message: editMessageFormData.message, attachments };
    dispatch(editPrivateMessage(payload));
    dispatch(setModal({ isModalVisible: false }));
  };

  const setEmoji = (native) => {
    const currentPosition = editorInstance?.current?.state.selection.anchor;
    editorInstance?.current?.chain().focus(currentPosition).insertContent(native).run();
  };

  const renderForm = (params) => {
    const { field } = params;

    return (
      <RichTextEditor
        innerRef={field.ref}
        content={field.value}
        onChange={field.onChange}
        editorRef={editorInstance}
        slotAfter={
          <FormFooter
            hiddenAudioRecordAction={true}
            hiddenVideoRecordAction={true}
            hiddenAttachmentsButton={true}
            hiddenEmojiButton={true}
            // isSubmitting={isSubmitting}
            setEmoji={setEmoji}
            // fileUploaderRef={fileUploaderRef}
            attachmentMediaServerData={attachmentMediaServerData}
            filesList={files}
            setFilesList={actions.onUpdateFiles}
            onClickFileRemove={actions.onRemoveFile}
          />
        }
      />
    );
  };

  return messageType === PRIVATE_MESSAGE_ITEM_TYPES.PRIVATE ? (
    <form onSubmit={handleSubmit(onSubmitPrivateMessage)} className="edit-message-form">
      <Input.TextArea
        name="message"
        register={register}
        label={t('inputs.message-field.label')}
        placeholder={t('inputs.message-field.placeholder')}
        error={t(errors.message?.message)}
      />
      <div className="edit-message__controls">
        <Button
          content={t('shared-content.cancel')}
          onClick={() => {
            dispatch(setModal({ isModalVisible: false }));
          }}
          size={BUTTON_SIZES.SM}
          contentColor={colors.white}
          variant={BUTTON_VARIANTS.OUTLINE}
          backgroundColor={colors['neutral-gray-600']}
        />
        <Button
          content={t('shared-content.save-changes')}
          type={BUTTON_TYPES.SUBMIT}
          size={BUTTON_SIZES.SM}
          contentColor={colors.white}
          backgroundColor={colors['linear-2']}
        />
      </div>
    </form>
  ) : (
    <RecorderProvider>
      <RecorderAudioProvider>
        <form
          onSubmit={handleSubmit(onSubmit)}
          // onKeyDown={handleEnterPress}
          onDragEnter={actions.onDragFiles}
        >
          <FilesDropArea
            isDragOver={isDragOver}
            onDrop={actions.onDropFiles}
            onDrag={actions.onDragFiles}
          />
          <Controller name="message" control={control} id="message-editor" render={renderForm} />
        </form>
      </RecorderAudioProvider>
    </RecorderProvider>
  );
};

EditMessageForm.propTypes = {
  messageData: PropTypes.object.isRequired,
  attachments: PropTypes.array.isRequired,
  messageType: PropTypes.string,
};

EditMessageForm.defaultProps = {
  messageType: '',
};

export default EditMessageForm;
