import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hasError: false,
};

const errorBoundarySlice = createSlice({
  name: 'errorBoundary',
  initialState,
  reducers: {
    setError(state, { payload }) {
      state.hasError = payload;
    },
  },
});

export const { setError } = errorBoundarySlice.actions;

export default errorBoundarySlice.reducer;
