import { cloneElement } from 'react';

import PropTypes from 'prop-types';

import ICONS_LIST from './svg-icon.constants';

const SvgIcon = (props) => {
  const { type, ...restProps } = props;

  return cloneElement(ICONS_LIST[type], { ...restProps });
};

SvgIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(ICONS_LIST)).isRequired,
};

export default SvgIcon;
