import { useSelector } from 'react-redux';

import { USER_PERMISSIONS_KEY } from 'constants/roles/index';
import get from 'lodash.get';
import { userPermissionsSelector } from 'pages/lms/my-account/my-account.selectors';
import PropTypes from 'prop-types';

const AdminComponentsRenderer = (props) => {
  const { children, permission, additionalCondition = true } = props;

  const userPermissions = useSelector(userPermissionsSelector);

  if (permission === USER_PERMISSIONS_KEY) {
    return null;
  }

  const hasAccess = get(userPermissions, permission);

  if (hasAccess && additionalCondition) {
    return children;
  }

  return null;
};

AdminComponentsRenderer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  permission: PropTypes.string.isRequired,
};

export default AdminComponentsRenderer;
