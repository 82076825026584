import React from 'react';

import { Radio } from '../../../../../ui-core';
import SvgIcon from '../../../../../ui-core/svg-icon';
import { PAYMENT_VARIANT } from '../../_constants';

import './styles.scss';

export const SavedCardVariant = (props) => {
  const { name, lastNumber, expMonth, expYear, brand, id, onChange, checked } = props;

  const handleChange = () => {
    onChange({
      id,
      expMonth,
      expYear,
      type: PAYMENT_VARIANT.SAVED_CARD,
    });
  };

  return (
    <div className="stripe-modal-saved-card-variant">
      <div className="stripe-modal-saved-card-variant-wrapper">
        <Radio
          id={`${name}-${lastNumber}-${brand}-${id}`}
          name={name}
          className="stripe-modal-saved-card-variant-radio"
          onChange={handleChange}
          checked={checked}
        >
          <span className="stripe-modal-saved-card-variant-info-card">
            <span className="stripe-modal-saved-card-variant-info-card-number">{`**** ${lastNumber}`}</span>
            <span className="stripe-modal-saved-card-variant-info-card-date">
              {`Exp date ${expMonth}/${expYear}`}
            </span>
          </span>
        </Radio>

        <SvgIcon type={brand} />
      </div>
    </div>
  );
};
