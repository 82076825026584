import { yupResolver } from '@hookform/resolvers/yup';
import colors from 'assets/styles/variables.scss';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { createCategoryChannel, updateCategoryChannel } from 'store/actions/community.action';

import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import BUTTON_TYPES, { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import Input from 'components/ui-core/inputs';

import {
  adaptDataFromSocketForDefaultValues,
  adaptManagementChannelFormDataForSocket,
} from './management-channel-form.utils';
import managementChannelFormSchema from './schemas';

import './styles.scss';

const ManagementChannelForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    additionalData: { itemData },
  } = useSelector(modalSelector);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(managementChannelFormSchema),
    defaultValues: {
      ...adaptDataFromSocketForDefaultValues(itemData),
    },
  });

  const onSubmit = (createNewChannelFormData) => {
    if (itemData) {
      dispatch(
        updateCategoryChannel(
          adaptManagementChannelFormDataForSocket(createNewChannelFormData, {
            communicationItemId: itemData.id,
          }),
        ),
      );
    } else {
      dispatch(
        createCategoryChannel(adaptManagementChannelFormDataForSocket(createNewChannelFormData)),
      );
    }
    dispatch(setModal({ isModalVisible: false }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="management-channel-form">
      <Input.Text
        name="title"
        register={register}
        label={t('inputs.channel-name.label')}
        placeholder={t('inputs.channel-name.placeholder')}
        error={t(errors.title?.message)}
      />
      <Input.Text
        name="description"
        register={register}
        label={t('inputs.channel-description.label')}
        placeholder={t('inputs.channel-description.placeholder')}
        error={t(errors.description?.message)}
      />
      <Controller
        name="discordChannelId"
        control={control}
        render={({ field }) => (
          <Input.Number
            register={field}
            mask="999 999 999 999 999 9999"
            label={t('inputs.discord-channel-id.label')}
            placeholder={t('inputs.discord-channel-id.placeholder')}
            error={t(errors.discordChannelId?.message)}
          />
        )}
      />

      <div className="management-channel-form__view-only-block">
        <div className="management-channel-form__view-only-block__container">
          <p className="management-channel-form__view-only-block__title">
            {t('modals.create-communication-item.channel.view-only.title')}
          </p>
          <p className="management-channel-form__view-only-block__description">
            {t('modals.create-communication-item.channel.view-only.description')}
          </p>
        </div>
        <Input.IosToggle name="type" register={register} />
      </div>
      <Button
        type={BUTTON_TYPES.SUBMIT}
        content={t('modals.create-communication-item.channel.submit-button')}
        backgroundColor={colors['linear-2']}
        contentColor={colors.white}
        size={BUTTON_SIZES.SM}
        className="w-160px"
      />
    </form>
  );
};

export default ManagementChannelForm;
