import parseCookies from 'utils/cookies';

const socketConnectionInstance = (() => {
  const socket = {};

  return {
    getInstance: (instanceName) => {
      if (socket[instanceName]) {
        return socket[instanceName];
      }
      const cookieAccessToken =
        parseCookies().access ||
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwicm9sZSI6IlRFU1QiLCJpYXQiOjE2OTY1MDA3ODEsImV4cCI6NDI4ODUwMDc4MX0.gRH9Y5qWvVmIDH8z5AcgtXqEN8MR3DJEi2MY0LTbI-w';

      if (!cookieAccessToken) {
        throw new Error('Connection error. Where is your cookies?');
      }

      socket[instanceName] = new WebSocket(
        `${process.env.REACT_APP_SOCKET_URL}?access=${cookieAccessToken}`,
      );

      return socket[instanceName];
    },
    killInstance: (instanceName) => {
      socket[instanceName] = null;
    },
  };
})();

const SOCKET_URL_PATHS = {
  COMMUNITY: '/community/en',
  STREAMS: '/stream-chat',
  PRIVATE_MESSAGES: '/private-chat',
  SUPPORT_CHAT: '/support-chat',
};

const SOCKET_READY_STATE = {
  READY: 1,
};

export { SOCKET_READY_STATE, SOCKET_URL_PATHS, socketConnectionInstance };
