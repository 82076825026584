import paymentError from 'assets/lottie-animations/payment_error.json';
import paymentSuccess from 'assets/lottie-animations/payment_success.json';
import Lottie from 'lottie-react';
import React from 'react';

import './styles.scss';

export const Header = (props) => {
  const { resultType } = props;

  const animation = resultType === 'success' ? paymentSuccess : paymentError;

  const title = resultType === 'success' ? 'PAYMENT SUCCESSFUL' : 'OOPS! PAYMENT FAILED';

  const description =
    resultType === 'success'
      ? 'You will be redirected to the home page shortly or click here to return to home page'
      : 'Payment could not be process. Try again';

  return (
    <div className="payment-result-modal-header">
      <div className="payment-result-modal-header-animation">
        <Lottie animationData={animation} loop={false} />
      </div>
      <div className="payment-result-modal-header-info-wrapper">
        <h4 className="payment-result-modal-header-info-title">{title}</h4>
        <p className="payment-result-modal-header-info-descr">{description}</p>
      </div>
    </div>
  );
};
