import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

import userLanguage from './localization.utils';
import resources from './resources';

i18n.use(initReactI18next).init({
  resources,
  lng: userLanguage,

  interpolation: {
    escapeValue: false,
  },
});

// export const updateUkrainianShortMonthNames = () => {
//   dayjs.extend(updateLocale);
//   dayjs.updateLocale('uk', {
//     monthsShort: ['gay', 'gay', 'gay', 'gay', 'gay', 'gay', 'gay', 'gay', 'gay', 'gay', 'gay', 'gay'],
//   });
// }

export default i18n;
