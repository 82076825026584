import {
  validateBitPayPaymentOperation,
  validatePayPalPaymentOperation,
  validateWayForPayPaymentOperation,
} from './payment-status.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  paymentStatus: {},
  error: '',
  isLoading: true,
};

const paymentStatusSlice = createSlice({
  name: 'paymentStatus',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(validateWayForPayPaymentOperation.fulfilled, (state, { payload }) => {
        state.paymentStatus = payload;
        state.error = '';
        state.isLoading = false;
      })
      .addCase(validateWayForPayPaymentOperation.rejected, (state, { payload }) => {
        if (payload.status) {
          state.paymentStatus = payload.status;
          state.error = payload.error;
        } else {
          state.paymentStatus = initialState.paymentStatus;
          state.error = payload;
        }
        state.isLoading = false;
      })
      .addCase(validatePayPalPaymentOperation.fulfilled, (state, { payload }) => {
        state.paymentStatus = payload;
        state.error = '';
        state.isLoading = false;
      })
      .addCase(validatePayPalPaymentOperation.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      })
      .addCase(validateBitPayPaymentOperation.fulfilled, (state, { payload }) => {
        state.paymentStatus = payload;
        state.error = '';
        state.isLoading = false;
      })
      .addCase(validateBitPayPaymentOperation.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
      });
  },
});

export default paymentStatusSlice.reducer;
