const activeCategoryIDSelector = (state) => state.groupChatting.activeCategoryID;
const activeChannelIDSelector = (state) => state.groupChatting.activeChannelID;

const categoriesListSelector = (state) => state.groupChatting.categoriesList;
const selectIsLoadingCommunity = (state) => state.groupChatting.isLoadingCommunity;

const isMessageListLoadingSelector = (state) => state.groupChatting.isMessageListLoading;
const isPinnedMessagesVisibleSelector = (state) => state.groupChatting.isPinnedMessagesVisible;
const isCategoryLoadingSelector = (state) => state.groupChatting.isCategoryLoading;

const activeChannelInfoSelector = (state) => {
  return state.groupChatting.channelsList.find(
    (channel) => channel.id === state.groupChatting.activeChannelID,
  );
};

const activeCategoryInfoSelector = (state) => {
  return state.groupChatting.categoriesList.find(
    (category) => category.id === state.groupChatting.activeCategoryID,
  );
};

const activeCategoryDataSelector = (state) => state.groupChatting.activeCategory.category;

const isActiveChannelHasUnreadMessagesSelector = (state) => {
  return state.groupChatting.activeCategory.channels.find(
    (channel) => channel.id === state.groupChatting.activeCategory.categoryId,
  )?.unreadCount;
};

const selectCommunityActiveChannel = (state) => state.groupChatting.activeChannel;

const selectIsConnectedToCategory = (state) => state.groupChatting.isConnectedToCategory;

const selectIsConnectedToCommunity = (state) => state.groupChatting.isConnectedToCommunity;

const selectActiveCommunityCategory = (state) => state.groupChatting.activeCategory;

const selectActiveCommunityId = (state) => state.groupChatting.activeCommunityId;

const replyMessageSelector = (state) => state.groupChatting.replyMessage;

export {
  activeCategoryDataSelector,
  activeCategoryIDSelector,
  activeCategoryInfoSelector,
  activeChannelIDSelector,
  activeChannelInfoSelector,
  categoriesListSelector,
  isActiveChannelHasUnreadMessagesSelector,
  isCategoryLoadingSelector,
  isMessageListLoadingSelector,
  isPinnedMessagesVisibleSelector,
  replyMessageSelector,
  selectActiveCommunityCategory,
  selectActiveCommunityId,
  selectCommunityActiveChannel,
  selectIsConnectedToCategory,
  selectIsConnectedToCommunity,
  selectIsLoadingCommunity,
};
