import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

export const useHandleFiles = () => {
  const { t } = useTranslation();

  const [files, setFiles] = useState([]);

  const [isDragOver, setIsDragOver] = React.useState(false);

  const handleDragFiles = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setIsDragOver(true);
    } else if (e.type === 'dragleave') {
      setIsDragOver(false);
    }
  }, []);
  const handleDropFiles = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragOver(false);
      if (e.dataTransfer.files && e.dataTransfer.files[0]) {
        const fileList = Array.from(e.dataTransfer.files);

        if (fileList.length > 8) {
          return toast.error(t('errors.max-8-files'));
        }

        const fls = fileList.map((file) => ({
          file,
          uploading: false,
          progress: 0,
          preview: URL.createObjectURL(file),
        }));

        setFiles(() => fls);
      }
    },
    [t],
  );
  const handleRemoveFile = useCallback((file) => {
    setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
  }, []);

  const actions = {
    onDragFiles: handleDragFiles,
    onDropFiles: handleDropFiles,
    onRemoveFile: handleRemoveFile,
    onUpdateFiles: setFiles,
  };

  return {
    files,
    isDragOver,
    actions,
  };
};
