import API_LIST from 'constants/api/api-list';
import { get, post } from 'services/api';

class PaymentStripeRepository {
  getPaymentConfig = async () => {
    try {
      const { data } = await get(API_LIST.PAYMENT.CONFIG);

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  createPaymentIntent = async (body) => {
    try {
      const { data } = await post(API_LIST.PAYMENT.CREATE_PAYMENT_INTENT, body);

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  createSubscription = async (dto) => {
    try {
      const { data } = await post(API_LIST.PAYMENT.CREATE_SUBSCRIPTION, dto);

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  createPaymentMethod = async (token) => {
    const body = {
      paymentMethodToken: token,
    };
    try {
      const { data } = await post(API_LIST.PAYMENT.CREATE_PAYMENT_METHOD, body);

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  getPaymentMethods = async () => {
    try {
      const { data } = await get(API_LIST.PAYMENT.GET_PAYMENT_METHODS);

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  removePaymentMethod = async (id) => {
    try {
      const { data } = await post(API_LIST.PAYMENT.REMOVE_PAYMENT_METHOD, { paymentMethodId: id });

      return data;
    } catch (e) {
      this.catchError(e);
    }
  };

  unsubscribe = async (id) => {
    try {
      await post(API_LIST.PAYMENT.UNSUBSCRIBE, {
        subscriptionId: id,
      });
    } catch (e) {
      this.catchError(e);
    }
  };

  catchError = (err) => {
    if (err.response?.data?.error) {
      throw err.response.data.error;
    }
    throw err.message;
  };
}

export const paymentStripeRepository = new PaymentStripeRepository();
