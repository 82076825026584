import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import {
  checkBitPayPaymentRequest,
  checkPayPalPaymentRequest,
  checkWayForPayPaymentRequest,
} from 'services/requests/payment';

import PAYMENT_STATUS from './payment-status.constants';

import { createAsyncThunk } from '@reduxjs/toolkit';

const validateWayForPayPaymentOperation = createAsyncThunk(
  'payment-status/VALIDATE_WAY_FOR_PAY_PAYMENT',
  async (requestData, { rejectWithValue }) => {
    try {
      const paymentInfo = await checkWayForPayPaymentRequest(requestData);

      if (paymentInfo.transaction.status.toLowerCase() === PAYMENT_STATUS.FAILED) {
        return rejectWithValue({
          status: paymentInfo,
          error: PAYMENT_STATUS.FAILED,
        });
      }

      return paymentInfo;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const checkWayForPayPaymentAndControlLoaderOperation = createAsyncThunk(
  'payment-status/CHECK_WAY_FOR_PAY_PAYMENT_AND_CONTROL_LOADER_OPERATION',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(validateWayForPayPaymentOperation(requestData));

    dispatch(setLoaderVisibility(false));
  },
);

const validatePayPalPaymentOperation = createAsyncThunk(
  'payment-status/VALIDATE_PAY_PAL_PAYMENT',
  async (requestData, { rejectWithValue }) => {
    try {
      const paymentInfo = await checkPayPalPaymentRequest(requestData);

      return paymentInfo;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const checkPayPalPaymentAndControlLoaderOperation = createAsyncThunk(
  'payment-status/CHECK_PAY_PAL_PAYMENT_AND_CONTROL_LOADER_OPERATION',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(validatePayPalPaymentOperation(requestData));

    dispatch(setLoaderVisibility(false));
  },
);

const validateBitPayPaymentOperation = createAsyncThunk(
  'payment-status/VALIDATE_BIT_PAY_PAYMENT',
  async (requestData, { rejectWithValue }) => {
    try {
      const paymentInfo = await checkBitPayPaymentRequest(requestData);

      return paymentInfo;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const checkBitPayPaymentAndControlLoaderOperation = createAsyncThunk(
  'payment-status/CHECK_BIT_PAY_PAYMENT_AND_CONTROL_LOADER_OPERATION',
  async (requestData, { dispatch }) => {
    dispatch(setLoaderVisibility(true));

    await dispatch(validateBitPayPaymentOperation(requestData));

    dispatch(setLoaderVisibility(false));
  },
);

export {
  checkBitPayPaymentAndControlLoaderOperation,
  checkPayPalPaymentAndControlLoaderOperation,
  checkWayForPayPaymentAndControlLoaderOperation,
  validateBitPayPaymentOperation,
  validatePayPalPaymentOperation,
  validateWayForPayPaymentOperation,
};
