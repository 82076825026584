import { USER_CHAT_STATUS } from './live-chat.constants';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  chatSection: {
    list: [],
    isLoading: true,
  },
  onApproveSection: {
    list: [],
    isLoading: true,
  },
  mutedAndMarkedSection: {
    list: [],
    isLoading: true,
  },
  stopWordsSection: {
    list: [],
    isLoading: true,
  },

  streamChatId: null,
  isUserMuted: false,
};

const liveChatSlice = createSlice({
  name: 'liveChatSlice',
  initialState,
  reducers: {
    setConnectionData(state, { payload: { streamChatId, messages, userChatStatus } }) {
      if (userChatStatus === USER_CHAT_STATUS.MUTED) {
        state.isUserMuted = true;
      }

      state.streamChatId = streamChatId;
      state.chatSection.list = messages;
    },
    receiveMessage(state, { payload }) {
      state.chatSection.list.unshift(payload);
    },

    setOnApproveSectionList(state, { payload }) {
      state.onApproveSection.list = payload;
      state.onApproveSection.isLoading = false;
    },

    setMutedSectionList(state, { payload }) {
      state.mutedAndMarkedSection.list = payload;
      state.mutedAndMarkedSection.isLoading = false;
    },

    setIsUserMuted(state, { payload }) {
      state.isUserMuted = payload;
    },

    deleteMessageFromChatSection(state, { payload: { messageId } }) {
      state.chatSection.list = state.chatSection.list.filter((message) => message.id !== messageId);
    },

    deleteMessageFromOnApproveSection(state, { payload: { id } }) {
      state.onApproveSection.list = state.onApproveSection.list.filter(
        (message) => message.id !== id,
      );
    },

    deleteUserFromMutedAndMarkedSection(state, { payload: { authorId } }) {
      state.mutedAndMarkedSection.list = state.mutedAndMarkedSection.list.filter(
        (author) => author.authorId !== authorId,
      );
    },

    setStopWordsSectionList(state, { payload }) {
      const { stopWords } = payload[0];

      state.stopWordsSection.list = stopWords;
      state.stopWordsSection.isLoading = false;
    },

    setStopWordsSectionLoader(state, { payload }) {
      state.stopWordsSection.isLoading = payload;
    },

    addMessagesToChatSectionList(state, { payload }) {
      state.chatSection.list.push(...payload);
    },
  },
});

export const {
  setConnectionData,
  receiveMessage,
  setMutedSectionList,
  setOnApproveSectionList,
  setIsUserMuted,
  deleteMessageFromChatSection,
  deleteMessageFromOnApproveSection,
  deleteUserFromMutedAndMarkedSection,
  setStopWordsSectionLoader,
  setStopWordsSectionList,
  addMessagesToChatSectionList,
} = liveChatSlice.actions;

export default liveChatSlice.reducer;
