import React, { Children, cloneElement } from 'react';

import './styles.scss';

const elementStyles = {
  base: {
    fontFamily: 'Lato, sans-serif',
    fontSize: '16px',
    color: '#ffffff',
  },
};
const cardElementOptions = {
  style: elementStyles,
};
export const StripeField = (props) => {
  const { children } = props;
  return (
    // eslint-disable-next-line jsx-a11y/label-has-for
    <label className="stripe-modal-checkout-form-label">
      <span>Card number</span>
      <div className="stripe-modal-checkout-form-field">
        {Children.map(children, (child) => {
          return cloneElement(child, { options: cardElementOptions });
        })}
      </div>
    </label>
  );
};
