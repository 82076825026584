import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { selectCommunityActiveChannel } from 'pages/community/group-chatting/group-chatting.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { searchUserInCommunication } from 'store/actions/community.action';

import { getAccountImageOrDefault } from 'utils/images';

import './mention-list.scss';

export const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const dispatch = useDispatch();

  const { channelId, mentions } = useSelector(selectCommunityActiveChannel);

  const selectItem = (index) => {
    const user = mentions?.users?.[index];

    if (user) {
      props.command({ id: user.id, label: user.name });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + mentions?.users?.length - 1) % mentions?.users?.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % mentions?.users?.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => {
    if (props.query.length > 1) {
      dispatch(
        searchUserInCommunication({
          communicationItemId: channelId,
          name: props.query,
          limit: 100,
          page: 1,
        }),
      );
    }
  }, [props.query]);

  useEffect(() => setSelectedIndex(0), [mentions?.users]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        event.stopPropagation();
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    props.query.length > 1 && (
      <div className="mention-list">
        {!!mentions?.users?.length &&
          mentions?.users?.map((user, index) => (
            <button
              className={`mention-list__item ${index === selectedIndex ? 'is-selected' : ''}`}
              key={user.id}
              onClick={() => selectItem(index)}
            >
              {user.id !== 'everyone' && user.id !== 'here' && (
                <img src={getAccountImageOrDefault(user.image)} alt="" />
              )}
              <span>{user.name}</span>
            </button>
          ))}
      </div>
    )
  );
});

MentionList.displayName = 'MentionList';
