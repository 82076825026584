import { yupResolver } from '@hookform/resolvers/yup';
import colors from 'assets/styles/variables.scss';
import { socketConnectionInstance } from 'constants/socket';
import LIVE_CHAT_SOCKET_EVENT_TYPES from 'pages/lms/my-workspace/nested-routes/streams/components/live-chat/live-chat.constants';
import { liveChatStreamChatIDSelector } from 'pages/lms/my-workspace/nested-routes/streams/components/live-chat/live-chat.selectors';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { sendMessageThroughSocket } from 'utils';

import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import BUTTON_TYPES, { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import Input from 'components/ui-core/inputs';

import stopWordsFormSchema from './schemas';
import {
  generateAdaptedStopWordsFormDataForSocket,
  generateAdaptedStopWordsFormDefaultValues,
} from './stop-words-form.utils';

import './styles.scss';

const StopWordsForm = ({ stopWordsList }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const streamChatId = useSelector(liveChatStreamChatIDSelector);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(stopWordsFormSchema),
    defaultValues: generateAdaptedStopWordsFormDefaultValues(stopWordsList),
  });

  const onSubmit = async ({ stopWords }) => {
    const adaptedStopWords = generateAdaptedStopWordsFormDataForSocket(stopWords);

    sendMessageThroughSocket(
      {
        action: LIVE_CHAT_SOCKET_EVENT_TYPES.CONFIG_WORDS_STREAM_CHAT,
        stopWords: adaptedStopWords,
        streamChatId,
      },
      socketConnectionInstance.getInstance(),
    );

    dispatch(setModal({ isVisible: false }));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="stop-words-form">
      <div className="stop-words-form__text-area">
        <Input.TextArea
          name="stopWords"
          register={register}
          label={t('inputs.stop-words.label')}
          placeholder={t('inputs.stop-words.placeholder')}
          error={t(errors.stopWords?.message)}
        />
      </div>
      <Button
        type={BUTTON_TYPES.SUBMIT}
        content={t('shared-content.save-changes')}
        backgroundColor={colors['linear-2']}
        contentColor={colors.white}
        size={BUTTON_SIZES.MD}
      />
    </form>
  );
};

StopWordsForm.propTypes = {
  stopWordsList: PropTypes.array.isRequired,
};

export default StopWordsForm;
