import { memo } from 'react';

import cl from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { readAllNotifications } from 'store/actions';
import { selectHasNotifications, selectNotificationLoaders } from 'store/selectors';

import notificationsImage from 'assets/images/empty-content/notifications.png';

import EmptyContent from '../../../../empty-content';
import { SpinnerLoader } from '../../../../ui-core';

import { NotificationList } from './notification-list';

import s from './styles.module.scss';


export const NotificationsPopup = memo((props) => {
  const { toggleIsOpen, ...restProps } = props;

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const loaders = useSelector(selectNotificationLoaders);
  const hasNotifications = useSelector(selectHasNotifications);

  const handleClickReadAll = () => {
    dispatch(readAllNotifications());
  };

  const classesLoaderContainer = cl(s.notificationsPopupLoaderContainer, {
    [s.notificationsPopupLoaderContainerVisible]: Object.entries(loaders).some(([, v]) => v),
  });

  return (
    <div className={s.notificationsPopup} {...restProps}>
      <div className={classesLoaderContainer}>
        <SpinnerLoader />
      </div>
      <div className={s.notificationsPopupTitleContainer}>
        <h4 className={s.notificationsPopupTitle}>{t('dashboard.notifications.title')}</h4>
        <button
          disabled={!hasNotifications}
          onClick={handleClickReadAll}
          className={s.notificationsPopupReadAllBtn}
        >
          Read All
        </button>
      </div>
      <div className={s.notificationsPopupListContainer}>
        {hasNotifications ? (
          <NotificationList toggleIsOpen={toggleIsOpen} />
        ) : (
          <div className={s.notificationsPopupEmptyContainer}>
            <EmptyContent
              iconType={<img src={notificationsImage} alt="empty state" />}
              title={t('dashboard.notifications.empty.title')}
              description={t('dashboard.notifications.empty.description')}
            />
          </div>
        )}
      </div>
    </div>
  );
});
