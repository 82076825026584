import {
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';

import { useToggle } from './use-toggle';

export const useBaseFloating = (props) => {
  const { placement = 'top', elementProps = [], ...restProps } = props;

  const [isOpen, toggleOpen] = useToggle();

  const { refs, context, floatingStyles, ...restElements } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: toggleOpen,
    middleware: [offset(10), flip(), shift({ padding: 8 })],
    whileElementsMounted: autoUpdate,
    ...restProps,
  });

  const elements = elementProps.length
    ? elementProps
    : [
        useDismiss(context, {
          ancestorScroll: true,
          bubbles: false,
        }),
        useRole(context),
        useClick(context),
      ];

  const { getReferenceProps, getFloatingProps } = useInteractions(elements);

  return {
    getFloatingProps,
    getReferenceProps,
    context,
    refs,
    floatingStyles,
    isOpen,
    toggleOpen,
    ...restElements,
  };
};
