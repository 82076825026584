/* eslint-disable jsx-a11y/media-has-caption */

/* eslint-disable no-unused-vars */
import { useState } from 'react';

import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';

import getFileFormat from 'components/attachment/attachment.utils';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import generateTargetURL from 'utils/attachments';

import MODAL_TYPES from '../../../modal-container/modal-container.constants';
import { setModal } from '../../../modal-container/modal-container.slice';

import './styles.scss';

const VideoAttachment = ({ file, attachments, index }) => {
  // eslint-disable-next-line no-unused-vars
  const [isVideoPreviewNotAvailable, setIsVideoPreviewNotAvailable] = useState(false);

  const dispatch = useDispatch();

  const videoUrl = generateTargetURL(file);

  if (isVideoPreviewNotAvailable) {
    return (
      <Button
        leftIcon={<SvgIcon type="video" />}
        onClick={() => window.open(generateTargetURL(file))}
        size={BUTTON_SIZES.ICON}
        className="video-attachment"
      />
    );
  }

  const handleClickVideo = () => {
    if (attachments.length === 1) {
      dispatch(
        setModal({
          modalType: MODAL_TYPES['VIDEO-PLAYER'],
          isModalVisible: true,
          fullScreen: true,
          additionalData: {
            videoUrl,
          },
        }),
      );
    } else {
      dispatch(
        setModal({
          modalType: MODAL_TYPES['MEDIA-GALLERY'],
          isModalVisible: true,
          fullScreen: true,
          additionalData: {
            videoUrl,
            attachments,
            attachmentIndex: index,
          },
        }),
      );
    }
  };

  return (
    <div className="video-attachment" onClick={handleClickVideo}>
      <ReactPlayer
        playing={false}
        width="100%"
        height="100%"
        className="video-attachment-player"
        url={videoUrl}
      />
    </div>
  );
};

VideoAttachment.propTypes = {
  file: PropTypes.string.isRequired,
};

export default VideoAttachment;
