import { memo } from 'react';

import cl from 'classnames';
import { useDispatch } from 'react-redux';
import { readNotificationById } from 'store/actions';

import { notificationType } from '../../../../../../../constants';

import { useRedirectToMention, useRedirectToPrivateMessage } from './_hooks';
import { getRedirectPath } from './_utils';

import s from './styles.module.scss';


export const NotificationItem = memo((props) => {
  const { text, title, image, redirectInfo, id, toggleIsOpen } = props;

  const dispatch = useDispatch();

  const startMentionFlow = useRedirectToMention(redirectInfo);
  const startPrivateMessageFlow = useRedirectToPrivateMessage(redirectInfo);
  const handleClickNotification = async () => {
    const path = getRedirectPath(redirectInfo);

    if (redirectInfo.type === notificationType.communityMention) {
      startMentionFlow(path);
    }

    if (redirectInfo.type === notificationType.newPrivateMessage) {
      startPrivateMessageFlow(path);
    }

    toggleIsOpen(false);

    dispatch(readNotificationById(id));
  };

  const imageContainerStyles = {
    backgroundImage: `url(${image})`,
  };

  const imageContainerClasses = cl(s.notificationItemImageContainer, {
    [s.notificationItemImageLikeAvatar]:
      redirectInfo.type === notificationType.communityMention ||
      redirectInfo.type === notificationType.newPrivateMessage,
  });

  return (
    <li className={s.notificationItem} onClick={handleClickNotification}>
      <div className={s.notificationItemContainer}>
        <div className={imageContainerClasses}>
          <div className={s.notificationItemImageOwner} style={imageContainerStyles}></div>
        </div>
        <div className={s.notificationItemBody}>
          <h4 className={s.notificationItemTitle}>{title}</h4>
          <p className={s.notificationItemText}>{text}</p>
        </div>
      </div>
    </li>
  );
});
