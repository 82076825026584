import ROUTER_BOOK from 'constants/routes/routes-book';
import { isUserHasAccessSelector } from 'pages/auth/auth.selectors';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import Header from 'components/header';
import PageContainerLayout from 'components/page-container-layout';
import Sidebar from 'components/sidebar';

import PROTECTED_ROUTES_WITH_OWN_LAYOUT from './protected-route.constants';

import './styles.scss';

const ProtectedRoute = (props) => {
  const { isProtectedRoute, children, path, isAllowAccess } = props;

  const isUserHasAccess = useSelector(isUserHasAccessSelector);

  const location = useLocation();

  const generateProtectedRouteLayout = () => {
    if (PROTECTED_ROUTES_WITH_OWN_LAYOUT.includes(path)) {
      return children;
    }

    return (
      <>
        <Header />
        <div className="protected-route-container">
          <Sidebar />
          <PageContainerLayout>{children}</PageContainerLayout>
        </div>
      </>
    );
  };

  const allow = isAllowAccess ? isAllowAccess(location) : false;

  if (isProtectedRoute && !isUserHasAccess && !allow) {
    return <Navigate to={ROUTER_BOOK.AUTH.LOGIN} replace />;
  }

  return isProtectedRoute ? generateProtectedRouteLayout() : children;
};

ProtectedRoute.propTypes = {
  isProtectedRoute: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  path: PropTypes.string.isRequired,
};

ProtectedRoute.defaultProps = {
  isProtectedRoute: false,
};

export default ProtectedRoute;
