import { useCallback } from 'react';

import get from 'lodash.get';
import { userPermissionsSelector } from 'pages/lms/my-account/my-account.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { USER_PERMISSIONS_KEY } from 'constants/roles/index';

import { setLoaderVisibility } from '../components/loader/loader.slice';
import { socketActionCollection } from '../constants';
import {
  connectedToGlobalSocket,
  disconnectGlobalSocket,
  downCommunitySocket,
  downGlobalSocket,
  downPrivateChatSocket,
  receiveClosedCommunityCategory,
  receiveCommunicationEmoji,
  receiveCommunicationMessagesById,
  receiveCommunityEditedMessage,
  receiveConnectToCommunication,
  receiveConnectToCommunity,
  receiveConnectToCommunityCategory,
  receiveCreateCategoryChannel,
  receiveCreatedChat,
  receiveEditMessage,
  receiveEmojiToggle,
  receiveMentions,
  receiveMentionsForAdmin,
  receiveMessageCommunication,
  receiveMessagesCommunication,
  receiveNewNotification,
  receivePinnedCommunityMessages,
  receivePrivateChats,
  receivePrivateMessage,
  receivePrivateMessages,
  receiveTogglePinnedMessageCommunity,
  receiveUnreadChat,
  receiveUnreadCommunication,
  receiveUnreadNotifications,
  receiveUpdateCategoryChannel,
  receiveViewCommunicationMessages,
  receiveViewPrivateMessages,
  setActiveChat,
  setUserActiveStatus,
  updateActiveCategoryInfo,
} from '../store/actions';
import { complexUpdateUnreadFlags } from '../store/actions/common.action';

export const useSocketManager = () => {
  const dispatch = useDispatch();

  const userPermissions = useSelector(userPermissionsSelector);

  const isAbleToSendMentions = get(userPermissions, USER_PERMISSIONS_KEY.communityMessageMention);

  const onOpen = useCallback(() => {
    dispatch(setLoaderVisibility(true));
    new Promise((resolve) => {
      setTimeout(() => {
        dispatch(connectedToGlobalSocket());
        resolve();
      }, 100);
    }).finally(() => {
      dispatch(setLoaderVisibility(false));
    });
  }, []);
  const onError = useCallback((err) => {
    dispatch(downGlobalSocket(err));
  }, []);
  const onClose = useCallback(() => {
    dispatch(disconnectGlobalSocket());
  }, []);
  const onErrorAfterAllAttempt = useCallback(() => {
    toast.error('Can`t connect to the socket. Reload page and try again');
  }, []);

  const onMessage = useCallback((message) => {
    const { action, data } = JSON.parse(message.data);

    const receiveAction = (receiver, params = data) => dispatch(receiver(params));

    const { common, privateChat, community, notification } = socketActionCollection;

    switch (action) {
      // ------------- Common
      case common.receive.receiveUnreadStatus:
        return receiveAction(complexUpdateUnreadFlags);
      case common.receive.error:
        return (() => {
          receiveAction(downPrivateChatSocket);
          receiveAction(downCommunitySocket);
        })();
      // ------------- Notification
      case notification.receive.unreadNotifications:
        return receiveAction(receiveUnreadNotifications);
      case notification.receive.newNotification:
        return receiveAction(receiveNewNotification);
      // ------------- Private Chat
      case privateChat.receive.privateChats:
        return receiveAction(receivePrivateChats);
      case privateChat.receive.privateChatConnect:
        return receiveAction(setActiveChat);
      case privateChat.receive.privateChatMessage:
        return receiveAction(receivePrivateMessage);
      case privateChat.receive.privateChatMessages:
        return receiveAction(receivePrivateMessages);
      case privateChat.receive.privateChatEditMessage:
        return receiveAction(receiveEditMessage);
      case privateChat.receive.privateChatEmoji:
        return receiveAction(receiveEmojiToggle);
      case privateChat.receive.privateChatUserOffline:
        return receiveAction(setUserActiveStatus, {
          userId: data.userId,
          isActive: false,
        });
      case privateChat.receive.privateChatUserOnline:
        return receiveAction(setUserActiveStatus, {
          userId: data.userId,
          isActive: true,
        });
      case privateChat.receive.privateMessageViewedCount:
        return receiveAction(receiveViewPrivateMessages);
      case privateChat.receive.privateChatCreate:
        return receiveAction(receiveCreatedChat);
      case privateChat.receive.privateChatUnread:
        return receiveAction(receiveUnreadChat);
      // ------------- Community
      // case community.receive.communityConnect:
      //   return receiveAction(receiveConnectToCommunity);
      // case community.receive.communityConnectCategory:
      //   return receiveAction(receiveConnectToCommunityCategory);
      // case community.receive.communityConnectCommunication:
      //   return receiveAction(receiveConnectToCommunityCommunication);
      // ------------- PREV STORE COMMUNITY
      case community.receive.communityConnect:
        return receiveAction(receiveConnectToCommunity);
      case community.receive.communityConnectCategory:
        return receiveAction(receiveConnectToCommunityCategory);
      case community.receive.communityConnectCommunication:
        return receiveAction(receiveConnectToCommunication);
      case community.receive.communityMessage:
        return receiveAction(receiveMessageCommunication);
      case community.receive.communityMessages:
        return receiveAction(receiveMessagesCommunication);
      case community.receive.communityEmoji:
        return receiveAction(receiveCommunicationEmoji);
      case community.receive.communityUnread:
        return receiveAction(receiveUnreadCommunication);
      case community.receive.communityViewAll:
        return receiveAction(receiveViewCommunicationMessages);
      case community.receive.communityCreatedCommunication:
        return receiveAction(receiveCreateCategoryChannel);
      case community.receive.communityUpdatedCommunication:
        return receiveAction(receiveUpdateCategoryChannel);
      case community.receive.communityMessagesByMessageId:
        return receiveAction(receiveCommunicationMessagesById);
      case community.receive.communityEditedMessage:
        return receiveAction(receiveCommunityEditedMessage);
      case community.receive.communityTogglePinCategory:
        return;
      case community.receive.communityTogglePinMessage:
        return receiveAction(receiveTogglePinnedMessageCommunity);
      case community.receive.communityPinnedMessages:
        return receiveAction(receivePinnedCommunityMessages);
      case community.receive.communityRemovedCategory:
        return receiveAction(receiveClosedCommunityCategory);
      case community.receive.communityUpdatedCategory:
        return receiveAction(updateActiveCategoryInfo);
      case community.receive.communityFoundUsersInCommunication:
        if (isAbleToSendMentions) {
          return receiveAction(receiveMentionsForAdmin);
        }

        return receiveAction(receiveMentions);
      default:
        return;
    }
  }, []);

  return { onMessage, onError, onClose, onOpen, onErrorAfterAllAttempt };
};
