import { toast } from 'react-toastify';

import { coursesRepository, paymentStripeRepository } from '../../services/requests';

import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPaymentConfig = createAsyncThunk(
  'payment/getPaymentConfig',
  async (_, { rejectWithValue }) => {
    try {
      const data = await paymentStripeRepository.getPaymentConfig();

      return data.publishableKey;
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const createPaymentIntent = createAsyncThunk(
  'payment/createPaymentIntent',
  async (id, { rejectWithValue }) => {
    try {
      const data = await paymentStripeRepository.createPaymentIntent(id);

      return data;
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const createSubscription = createAsyncThunk(
  'payment/createSubscription',
  async (dto, { rejectWithValue }) => {
    try {
      const data = await paymentStripeRepository.createSubscription({
        priceCommunityId: dto.id,
        isTrial: dto.isTrial,
        paymentMethodId: dto?.paymentMethodId,
      });

      return data;
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const createPaymentMethod = createAsyncThunk(
  'payment/createPaymentMethod',
  async (token, { rejectWithValue }) => {
    try {
      const data = await paymentStripeRepository.createPaymentMethod(token);

      return data;
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const getPaymentMethods = createAsyncThunk(
  'payment/getPaymentMethods',
  async (_, { rejectWithValue }) => {
    try {
      const data = await paymentStripeRepository.getPaymentMethods();

      return data.userHasPaymentMethod ? data.paymentMethods : [];
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const removePaymentMethod = createAsyncThunk(
  'payment/removePaymentMethod',
  async (id, { rejectWithValue }) => {
    try {
      const data = await paymentStripeRepository.removePaymentMethod(id);

      return { ...data, id };
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const unsubscribe = createAsyncThunk(
  'payment/unsubscribe',
  async (id, { rejectWithValue }) => {
    try {
      await paymentStripeRepository.unsubscribe(id);

      return id;
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);

export const enableCourseTrial = createAsyncThunk(
  'payment/enableCourseTrial',
  async (dto, { rejectWithValue }) => {
    try {
      const { data } = await coursesRepository.enableCourseTrial(dto);

      return data;
    } catch (e) {
      toast.error(e);
      return rejectWithValue(e);
    }
  },
);
