const DISCORD_ATTACHMENT_PATH = 'https://';

const generateTargetURL = (file) => {
  if (file.includes(DISCORD_ATTACHMENT_PATH)) {
    return file;
  }

  return `${process.env.REACT_APP_MEDIA_SERVER_DEFAULT_API_URL_PATH}${file}`;
};

export const isDiscordAttachment = (file) => {
  if (file.includes(DISCORD_ATTACHMENT_PATH)) {
    return true;
  }

  return false;
};

export default generateTargetURL;
