import { useEffect, useMemo, useRef } from 'react';

import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { A11y, Navigation } from 'swiper';
import SwiperCore, { Keyboard } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ACCEPTABLE_VIDEO_FORMATS } from 'components/attachment/attachment.constants';
import getFileFormat from 'components/attachment/attachment.utils';
import modalSelector from 'components/modal-container/modal-container.selectors';
import { setModal } from 'components/modal-container/modal-container.slice';
import Button from 'components/ui-core/button';
import { BUTTON_SIZES } from 'components/ui-core/button/button.constants';
import SvgIcon from 'components/ui-core/svg-icon';

import generateTargetURL from 'utils/attachments';

import './styles.scss';

SwiperCore.use([Keyboard]);

const MediaGallery = () => {
  const dispatch = useDispatch();

  const {
    additionalData: { attachments, attachmentIndex },
  } = useSelector(modalSelector);

  const swiperRef = useRef(null);
  const prevElRef = useRef(null);
  const nextElRef = useRef(null);

  const navigationConfig = useMemo(
    () => ({
      prevEl: prevElRef.current,
      nextEl: nextElRef.current,
      disabledClass: 'slider-btn-disabled',
      lockClass: 'slider-btn-lock',
    }),
    [],
  );

  useEffect(() => {
    const swiper = swiperRef.current?.swiper;

    if (swiper) {
      swiper.params.navigation.prevEl = prevElRef?.current;
      swiper.params.navigation.nextEl = nextElRef?.current;

      swiper.navigation.init();
      swiper.navigation.update();
    }
  }, []);

  useEffect(() => {
    swiperRef.current.swiper.slideTo(attachmentIndex);
  }, [attachmentIndex]);

  const renderMediaPreviewSlide = () => {
    return attachments
      .filter((item) => !item.includes('.pdf'))
      .map((url) => {
        if (ACCEPTABLE_VIDEO_FORMATS.includes(getFileFormat(url, true))) {
          return (
            <SwiperSlide key={url} className="media-gallery__swiper-slide">
              <ReactPlayer
                playing={false}
                width="100%"
                height="100%"
                className="media-gallery__video-player"
                url={generateTargetURL(url)}
                controls={true}
              />
            </SwiperSlide>
          );
        }

        return (
          <SwiperSlide key={url} className="media-gallery__swiper-slide">
            <img src={generateTargetURL(url)} alt="" className="media-gallery__image" />
          </SwiperSlide>
        );
      });
  };

  return (
    <>
      <div className="media-gallery__close-btn">
        <Button
          onClick={() => {
            dispatch(
              setModal({
                isModalVisible: false,
              }),
            );
          }}
          leftIcon={<SvgIcon type="cross-2" />}
          size={BUTTON_SIZES.ICON}
        />
      </div>

      <div className="media-gallery">
        <div className="media-gallery__container">
          <button className="media-gallery__prevEl" ref={prevElRef}>
            <SvgIcon type="arrow-right" />
          </button>
          <button className="media-gallery__nextEl" ref={nextElRef}>
            <SvgIcon type="arrow-right" />
          </button>

          <Swiper
            ref={swiperRef}
            className="media-gallery__swiper-container"
            modules={[Navigation, A11y]}
            navigation={navigationConfig}
            keyboard={true}
            slidesPerView={1}
            speed={100}
          >
            {renderMediaPreviewSlide()}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default MediaGallery;
