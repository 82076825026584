import cn from 'classnames';
import PropTypes from 'prop-types';

import SpinnerCircle from 'components/spinner-circle';

import BUTTON_TYPES, { BUTTON_SIZES, BUTTON_VARIANTS } from './button.constants';

import './styles.scss';

const Button = (props) => {
  const {
    className,
    type,
    content = '',
    onClick,
    leftIcon,
    rightIcon,
    disabled,
    isLoading,
    backgroundColor,
    size,
    contentColor,
    variant,
    ...restProps
  } = props;

  const rootClassName = cn(
    `button`,
    {
      'button--disabled': disabled,
      [`button--${size}`]: size,
      [`button--${variant}`]: variant && size !== BUTTON_SIZES.ICON,
    },
    className,
  );

  const generateInlineStyles = () => {
    if (variant === BUTTON_VARIANTS.FILLED) {
      return {
        background: backgroundColor,
        color: contentColor,
      };
    }

    return { borderColor: backgroundColor, color: contentColor };
  };

  return (
    <button
      className={rootClassName}
      type={type}
      disabled={disabled}
      onClick={onClick}
      style={{
        ...generateInlineStyles(),
      }}
      {...restProps}
    >
      {leftIcon && <span className="button__icon--left">{leftIcon}</span>}
      {content && content}
      {isLoading && <SpinnerCircle />}
      {rightIcon && <span className="button__icon--right">{rightIcon}</span>}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  content: PropTypes.string,
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  leftIcon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  rightIcon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  backgroundColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.oneOf(Object.values(BUTTON_SIZES)), PropTypes.string]),
  contentColor: PropTypes.string,
  variant: PropTypes.PropTypes.oneOf(Object.values(BUTTON_VARIANTS)),
};

Button.defaultProps = {
  onClick: () => {},
  type: BUTTON_TYPES.BUTTON,
  leftIcon: null,
  disabled: false,
  content: '',
  className: '',
  isLoading: false,
  rightIcon: null,
  variant: BUTTON_VARIANTS.FILLED,
  backgroundColor: '',
  contentColor: '',
  size: '',
};

export default Button;
