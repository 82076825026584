/* eslint-disable consistent-return */
import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import i18n from 'localization/localization.config';
import {
  getHomeworkExercisesRequest,
  getHomeworkFromUserCourseRequest,
  sendWritingExerciseAnswerRequest,
} from 'services/requests/homework';

import { getCoursesBlocksOperation } from '../courses/courses.thunks';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getHomeworkFromUserCourseOperation = createAsyncThunk(
  'homework/GET_HOMEWORK_FROM_USER_COURSE',
  async (requestData, { rejectWithValue }) => {
    try {
      const homework = await getHomeworkFromUserCourseRequest({
        courseID: requestData.selectedCourse.value,
        language: requestData.language,
      });

      return {
        homeworkList: homework,
        selectedCourse: requestData.selectedCourse,
      };
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getHomeworkPageOperation = createAsyncThunk(
  'homework/GET_HOMEWORK_PAGE',
  async (requestData, { dispatch, getState }) => {
    dispatch(setLoaderVisibility(true));

    // if we don't have selected course in select
    if (!requestData.selectedCourse) {
      let defaultCourseForSelect = getState().myCourses.coursesList?.[0] || null;

      // request user courses for homework selector
      if (!defaultCourseForSelect) {
        defaultCourseForSelect = await dispatch(
          getCoursesBlocksOperation({ language: requestData.language }),
        )[0];
      }

      // return null and show empty content block
      if (!defaultCourseForSelect) {
        await dispatch(setLoaderVisibility(false));

        return null;
      }

      requestData = {
        ...requestData,
        selectedCourse: {
          label: defaultCourseForSelect.title,
          value: defaultCourseForSelect.groupId,
        },
      };
    }

    const coursesListLanguage = getState().myCourses.coursesListLanguage;

    // if user change language, we will request again all courses
    if (coursesListLanguage !== i18n.language) {
      const { payload: coursesList } = await dispatch(
        getCoursesBlocksOperation({ language: requestData.language }),
      );

      if (requestData.selectedCourse) {
        const selectedCourseBeforeLanguageChanged = coursesList.find(
          (course) => Number(course.groupId) === Number(requestData.selectedCourse.value),
        );

        requestData.selectedCourse = {
          label: selectedCourseBeforeLanguageChanged.title,
          value: selectedCourseBeforeLanguageChanged.groupId,
        };
      }
    }

    await dispatch(getHomeworkFromUserCourseOperation(requestData));

    await dispatch(setLoaderVisibility(false));
  },
);

const getTestExercisesOperation = createAsyncThunk(
  'homework/GET_TEST_EXERCISES',
  async (requestData, { rejectWithValue }) => {
    try {
      const exercises = await getHomeworkExercisesRequest(requestData);

      return exercises.tests;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getWritingExercisesOperation = createAsyncThunk(
  'homework/GET_WRITING_EXERCISES',
  async (requestData, { rejectWithValue }) => {
    try {
      const exercises = await getHomeworkExercisesRequest(requestData);

      return exercises.writings;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getUnverifiedHomeworkAnswersOperation = createAsyncThunk(
  'homework/GET_UNVERIFIED_HOMEWORK_ANSWERS',
  async (requestData, { rejectWithValue }) => {
    try {
      const answers = await getHomeworkExercisesRequest(requestData);

      return answers.writings;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getVerifiedTestAnswersOperation = createAsyncThunk(
  'homework/GET_VERIFIED_TEST_ANSWERS',
  async (requestData, { rejectWithValue }) => {
    try {
      const answers = await getHomeworkExercisesRequest(requestData);

      return answers.tests;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const getVerifiedWritingAnswersOperation = createAsyncThunk(
  'homework/GET_VERIFIED_WRITING_ANSWERS',
  async (requestData, { rejectWithValue }) => {
    try {
      const answers = await getHomeworkExercisesRequest(requestData);

      // BE sends a review when we request hidden content, before this, we don`t have review data
      return {
        answers: answers.writings,
        review: answers.review,
      };
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

const sendWritingExercisesAnswerOperation = createAsyncThunk(
  'homework/SEND_WRITING_ANSWER_EXERCISES',
  async (requestData, { rejectWithValue, dispatch, getState }) => {
    try {
      await sendWritingExerciseAnswerRequest(requestData);

      const selectedCourse = getState().myHomework.selectedCourse;
      dispatch(getHomeworkPageOperation({ selectedCourse, language: 'ua' }));

      return requestData;
    } catch (error) {
      toast.error(error, {
        toastId: error,
      });

      return rejectWithValue(error);
    }
  },
);

export {
  getHomeworkFromUserCourseOperation,
  getHomeworkPageOperation,
  getTestExercisesOperation,
  getUnverifiedHomeworkAnswersOperation,
  getVerifiedTestAnswersOperation,
  getVerifiedWritingAnswersOperation,
  getWritingExercisesOperation,
  sendWritingExercisesAnswerOperation,
};
