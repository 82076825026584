import {
  createPaymentIntent,
  createSubscription,
  getPaymentConfig,
  getPaymentMethods,
  removePaymentMethod,
} from '../actions';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  error: null,
  pk: null,
  paymentMethods: [],
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentConfig.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPaymentConfig.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.pk = payload;
    });
    builder.addCase(getPaymentConfig.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    builder.addCase(createSubscription.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createSubscription.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createSubscription.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    builder.addCase(createPaymentIntent.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(createPaymentIntent.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createPaymentIntent.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    builder.addCase(getPaymentMethods.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(getPaymentMethods.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.paymentMethods = payload;
    });
    builder.addCase(getPaymentMethods.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = payload || null;
    });

    builder.addCase(removePaymentMethod.pending, (state) => {
      state.error = null;
    });
    builder.addCase(removePaymentMethod.fulfilled, (state, { payload }) => {
      state.paymentMethods = state.paymentMethods.filter((method) => method.id !== payload.id);
    });
    builder.addCase(removePaymentMethod.rejected, (state, { payload }) => {
      state.error = payload || null;
    });
  },
});

export const payment = paymentSlice.reducer;
