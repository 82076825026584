import {
  createLessonCommentOperation,
  getCourseViewBlocksOperation,
  getLessonCommentsOperation,
} from './course.thunks';

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseData: {
    lessons: [],
    modules: [],
    currentLessonInPlayer: {
      id: null,
      video: '',
    },
    activeModuleInfo: null,
    comments: {
      loading: true,
      list: [],
      error: '',
    },
  },
  error: '',
};

const courseViewSlice = createSlice({
  name: 'courseView',
  initialState,
  reducers: {
    markTimeCodeState(state, { payload: activeTimeCodeID }) {
      const lessonsWithUpdatedTimeCodesArray = state.courseData.lessons.map((lesson) => {
        // find what is the lesson in player
        if (lesson.id === state.courseData.currentLessonInPlayer.id) {
          lesson.videoTimecodes.map((timeCode, timeCodeIndex) => {
            // find which timecode is active
            if (timeCode.id === activeTimeCodeID) {
              timeCode.isActive = true;
            }

            const previousTimeCodeItem = lesson.videoTimecodes[timeCodeIndex - 1];
            // mark all previous timecodes as watched
            if (timeCode.id === activeTimeCodeID && previousTimeCodeItem) {
              if (!previousTimeCodeItem.isWatched) {
                previousTimeCodeItem.isWatched = true;
                previousTimeCodeItem.isActive = false;
                // fill progress bar (base on timecodes)
                lesson.progress += parseInt(100 / lesson.videoTimecodes.length);
              }
            }

            return timeCode;
          });
        }
        return lesson;
      });

      state.courseData.lessons = lessonsWithUpdatedTimeCodesArray;
    },
    setNextLessonInPlayer(state) {
      const indexOfCurrentLesson = state.courseData.lessons.findIndex(
        (lesson) => lesson.id === state.courseData.currentLessonInPlayer.id,
      );

      const updatedLesson = state.courseData.lessons.map((lesson, lessonIndex) => {
        if (indexOfCurrentLesson === lessonIndex) {
          lesson.isWatched = true;
          lesson.progress = 100;

          lesson.videoTimecodes.forEach((timeCode) => {
            if (!timeCode.isWatched) {
              timeCode.isWatched = true;
              timeCode.isActive = false;
            }
          });
        }

        return lesson;
      });

      const nextLesson = state.courseData.lessons[indexOfCurrentLesson + 1];

      state.courseData.currentLessonInPlayer = {
        id: nextLesson.id,
        video: nextLesson.video,
      };
      state.courseData.lessons = updatedLesson;
    },
    setLessonInPlayerAndMarkTimeCodesState(state, { payload }) {
      state.courseData.currentLessonInPlayer = payload;
      state.courseData.lessons.forEach((lesson) => {
        lesson.videoTimecodes = lesson.videoTimecodes.map((timeCodeItem) => {
          timeCodeItem.isActive = false;

          return timeCodeItem;
        });
      });
    },
    resetLessonInPlayer(state) {
      state.courseData.currentLessonInPlayer = { ...initialState.courseData.currentLessonInPlayer };
    },
    setActiveModuleInfo(state, { payload }) {
      const activeModuleInfo = state.courseData.modules.find((module) => module.id === payload);

      state.courseData.activeModuleInfo = activeModuleInfo;
      state.courseData.lessons = activeModuleInfo.lessons;
      state.courseData.currentLessonInPlayer = activeModuleInfo.lessons.find(
        (lesson) => lesson.progress !== 100,
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCourseViewBlocksOperation.fulfilled, (state, { payload }) => {
        const { lessonsBlock, courseWithModules, ...otherCourseData } = payload;

        let latestWatchedLesson;
        let courseModules = [];
        let courseLessons;
        let activeModuleInfo = null;

        if (!courseWithModules) {
          latestWatchedLesson = lessonsBlock.find((lesson) => lesson.progress !== 100);
          courseLessons = lessonsBlock;

          if (!latestWatchedLesson) {
            latestWatchedLesson = lessonsBlock[0];
          }
        } else {
          courseModules = lessonsBlock;

          for (let moduleIndex = 0; moduleIndex < lessonsBlock.length; moduleIndex++) {
            const module = lessonsBlock[moduleIndex];

            latestWatchedLesson = module.lessons.find((lesson) => lesson.progress !== 100);

            if (latestWatchedLesson) {
              courseLessons = module.lessons;
              activeModuleInfo = module;
              break;
            }
          }

          if (!latestWatchedLesson) {
            const firstModule = lessonsBlock[0];

            latestWatchedLesson = firstModule.lessons[0];
            courseLessons = firstModule.lessons;
            activeModuleInfo = firstModule;
          }
        }

        state.courseData = {
          ...state.courseData,
          ...otherCourseData,
          courseWithModules,
          lessons: courseLessons,
          modules: courseModules,
          activeModuleInfo,
          currentLessonInPlayer: { id: latestWatchedLesson.id, video: latestWatchedLesson.video },
        };
        state.error = '';
      })
      .addCase(getCourseViewBlocksOperation.rejected, (state, { payload }) => {
        state.error = payload;
      })
      .addCase(getLessonCommentsOperation.pending, (state) => {
        state.courseData.comments.loading = true;
      })
      .addCase(getLessonCommentsOperation.fulfilled, (state, { payload }) => {
        state.courseData.comments.list = payload;
        state.courseData.comments.loading = false;
        state.courseData.comments.error = '';
      })
      .addCase(getLessonCommentsOperation.rejected, (state, { payload }) => {
        state.courseData.comments.error = payload;
        state.courseData.comments.loading = false;
      })
      .addCase(createLessonCommentOperation.fulfilled, (state, { payload }) => {
        state.courseData.comments.list.unshift(payload);
      });
  },
});

export const {
  setIsWatchedTimeCode,
  markTimeCodeState,
  setNextLessonInPlayer,
  resetLessonInPlayer,
  setLessonInPlayerAndMarkTimeCodesState,
  setActiveModuleInfo,
} = courseViewSlice.actions;

export default courseViewSlice.reducer;
