import cn from 'classnames';
import PropTypes from 'prop-types';

const Checkbox = ({ name, register, label, error, className }) => {
  const rootClassName = cn(
    `field-container--checkbox`,
    {
      'field-container--checkbox--error': error,
    },
    className,
  );

  return (
    <div className={rootClassName}>
      <input id={name} className="field" type="checkbox" {...register(name)} />
      <label htmlFor={name} className="field__label">
        <span>{label}</span>
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.string,
  className: PropTypes.string,
};

Checkbox.defaultProps = {
  label: '',
  error: '',
  className: '',
};

export default Checkbox;
