import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import './styles.scss';

const UploadStatusCircle = ({ size, strokeWidth, percentage }) => {
  const [offset, setOffset] = useState(0);
  const [circumference, setCircumference] = useState(0);

  useEffect(() => {
    const radius = size / 2 - strokeWidth / 2;
    setCircumference(2 * Math.PI * radius);
    setOffset(circumference - (percentage / 100) * circumference);
  }, [size, strokeWidth, percentage, circumference]);

  return (
    <svg className="progress-ring" width={size} height={size}>
      <circle
        className="progress-ring__circle"
        strokeWidth={strokeWidth}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
        r={size / 2 - strokeWidth / 2}
        cx={size / 2}
        cy={size / 2}
      />
    </svg>
  );
};

UploadStatusCircle.propTypes = {
  size: PropTypes.number.isRequired,
  strokeWidth: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
};

export default UploadStatusCircle;
