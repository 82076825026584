const COMMUNICATION_ITEM_TYPE = {
  CATEGORY: 'category',
  CHANNEL: 'channel',
  CHAT: 'chat',
  MESSAGE: 'message',
};

const CHANNEL_STATUSES = {
  ACTIVE: 'active',
  CLOSED: 'closed',
};

export { CHANNEL_STATUSES, COMMUNICATION_ITEM_TYPE };
