import { toast } from 'react-toastify';

import { setLoaderVisibility } from 'components/loader/loader.slice';
import getBlockStreamByIdRequest from 'services/requests/streams';

import ROUTER_BOOK from '../../../../../../../constants/routes/routes-book';
import { streamRedirect } from './stream.utils';

import { createAsyncThunk } from '@reduxjs/toolkit';

const getStreamByIdOperation = createAsyncThunk(
  'stream-view/GET_STREAM_INFO',
  async ({ requestData, navigate }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoaderVisibility(true));

      const { block } = await getBlockStreamByIdRequest(requestData);

      const {
        stream: { status },
      } = block;

      const redirectionStatus = streamRedirect(status, '', navigate);

      if (redirectionStatus === 'live') {
        dispatch(setLoaderVisibility(false));
      }

      return block;
    } catch (error) {
      navigate(ROUTER_BOOK.DASHBOARD);
      toast.error(error, {
        toastId: error,
      });

      await dispatch(setLoaderVisibility(false));

      return rejectWithValue(error);
    }
  },
);

export default getStreamByIdOperation;
