const userNameSelector = (state) => state.myAccount.accountInfo.name;
const userEmailSelector = (state) => state.myAccount.accountInfo.email;
const userPhoneNumberSelector = (state) => state.myAccount.accountInfo.phoneNumber;
const userTelegramSelector = (state) => state.myAccount.accountInfo.telegramUsername;
const userDiscordIDSelector = (state) => state.myAccount.accountInfo.discordId;
const userAvatarSelector = (state) => state.myAccount.accountInfo.image;
const myAccountSelector = (state) => state.myAccount;
const userInterestsSelector = (state) => state.myAccount.accountInfo.interests;
const userSubscriptionSelector = (state) => state.myAccount.accountInfo.subscription;
const userRoleSelector = (state) => state.myAccount.accountInfo.role;
const userPermissionsSelector = (state) => state.myAccount.accountInfo?.permissions;

const selectMeIsAdmin = (state) => {
  if (
    state.myAccount.accountInfo &&
    state.myAccount.accountInfo.permissions &&
    Object.keys(state.myAccount.accountInfo.permissions).length > 0
  ) {
    return true;
  }

  return false;
};

const selectBusinessAccount = (state) => state.myAccount.businessAccount;

export {
  myAccountSelector,
  selectBusinessAccount,
  selectMeIsAdmin,
  userAvatarSelector,
  userDiscordIDSelector,
  userEmailSelector,
  userInterestsSelector,
  userNameSelector,
  userPermissionsSelector,
  userPhoneNumberSelector,
  userRoleSelector,
  userSubscriptionSelector,
  userTelegramSelector,
};
