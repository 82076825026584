/* eslint-disable spaced-comment */
import { ReactComponent as MastercardGray } from 'assets/images/payments/mastercard-gray.svg';
import { ReactComponent as PowerByStripe } from 'assets/images/payments/stripe.svg';
import { ReactComponent as TrashCan } from 'assets/images/payments/trash-can.svg';
import { ReactComponent as VisaGray } from 'assets/images/payments/visa-gray.svg';
import {
  ReactComponent as MasterCard,
  ReactComponent as Visa,
} from 'assets/images/payments/visa.svg';
import { ReactComponent as Inbox } from 'assets/images/vector/Inbox.svg';
import { ReactComponent as Apple } from 'assets/images/vector/apple.svg';
import { ReactComponent as ArrowRight } from 'assets/images/vector/arrow-right.svg';
import { ReactComponent as Arrow } from 'assets/images/vector/arrow.svg';
import { ReactComponent as Check } from 'assets/images/vector/check.svg';
import { ReactComponent as Checkmark } from 'assets/images/vector/checkmark.svg';
import { ReactComponent as Clock } from 'assets/images/vector/clock.svg';
import { ReactComponent as CommunityBurger } from 'assets/images/vector/community/burger.svg';
import { ReactComponent as Channel } from 'assets/images/vector/community/channel.svg';
import { ReactComponent as ChatOutlined } from 'assets/images/vector/community/chat-outlined.svg';
import { ReactComponent as Chat } from 'assets/images/vector/community/chat.svg';
import { ReactComponent as Info } from 'assets/images/vector/community/info.svg';
import { ReactComponent as MessageReply } from 'assets/images/vector/community/message-reply.svg';
import { ReactComponent as MessageUnwatched } from 'assets/images/vector/community/message-status-unwatched.svg';
import { ReactComponent as MessageWatched } from 'assets/images/vector/community/message-status-watched.svg';
import { ReactComponent as Plus } from 'assets/images/vector/community/plus.svg';
import { ReactComponent as SmilePlus } from 'assets/images/vector/community/smile-plus.svg';
import { ReactComponent as Smile } from 'assets/images/vector/community/smile.svg';
import { ReactComponent as Alarm } from 'assets/images/vector/courses/alarm.svg';
import { ReactComponent as Cross } from 'assets/images/vector/courses/cross.svg';
import { ReactComponent as LockClosedPink } from 'assets/images/vector/courses/lock-closed-pink.svg';
import { ReactComponent as LockClosed } from 'assets/images/vector/courses/lock-closed.svg';
import { ReactComponent as LockOpened } from 'assets/images/vector/courses/lock-opened.svg';
import { ReactComponent as Paperclip } from 'assets/images/vector/courses/paperclip.svg';
import { ReactComponent as Play } from 'assets/images/vector/courses/play.svg';
import { ReactComponent as StarOutlined } from 'assets/images/vector/courses/star-outlined.svg';
import { ReactComponent as Cross2 } from 'assets/images/vector/cross-2.svg';
import { ReactComponent as Doc } from 'assets/images/vector/doc.svg';
import { ReactComponent as Dots } from 'assets/images/vector/dots.svg';
import { ReactComponent as Download } from 'assets/images/vector/download.svg';
import { ReactComponent as EmptyComments } from 'assets/images/vector/empty-comments.svg';
import { ReactComponent as EmptyCourses } from 'assets/images/vector/empty-courses.svg';
import { ReactComponent as EmptyMessages } from 'assets/images/vector/empty-messages.svg';
import { ReactComponent as EmptyNotifications } from 'assets/images/vector/empty-notifications.svg';
import { ReactComponent as EyeCrossedOut } from 'assets/images/vector/eye-crossed-out.svg';
import { ReactComponent as Eye } from 'assets/images/vector/eye.svg';
import { ReactComponent as File } from 'assets/images/vector/file.svg';
import { ReactComponent as FingerPrint } from 'assets/images/vector/finger-print.svg';
import { ReactComponent as EnSquare } from 'assets/images/vector/flags/en.svg';
import { ReactComponent as UaSquare } from 'assets/images/vector/flags/ua.svg';
import { ReactComponent as Google } from 'assets/images/vector/google.svg';
import { ReactComponent as Hamburger } from 'assets/images/vector/hamburger.svg';
import { ReactComponent as User } from 'assets/images/vector/header/user.svg';
import { ReactComponent as Incorrect } from 'assets/images/vector/homework/incorrect.svg';
import { ReactComponent as Selected } from 'assets/images/vector/homework/selected.svg';
import { ReactComponent as Unselected } from 'assets/images/vector/homework/unselected.svg';
import { ReactComponent as MinimalLogo } from 'assets/images/vector/logo/logo-minimal.svg';
import { ReactComponent as Logo } from 'assets/images/vector/logo/logo.svg';
import { ReactComponent as MarkAllAsRead } from 'assets/images/vector/mark-all-as-read.svg';
import { ReactComponent as BurgerMenu } from 'assets/images/vector/menu/burger.svg';
import { ReactComponent as Community } from 'assets/images/vector/menu/community.svg';
import { ReactComponent as Dashboard } from 'assets/images/vector/menu/dashboard.svg';
import { ReactComponent as Events } from 'assets/images/vector/menu/events.svg';
import { ReactComponent as Messages } from 'assets/images/vector/menu/messages.svg';
import { ReactComponent as MyAccount } from 'assets/images/vector/menu/my-account.svg';
import { ReactComponent as MyWorkspace } from 'assets/images/vector/menu/my-workspace.svg';
import { ReactComponent as Micro } from 'assets/images/vector/micro.svg';
import { ReactComponent as Minus } from 'assets/images/vector/minus.svg';
import { ReactComponent as Moderate } from 'assets/images/vector/moderate.svg';
import { ReactComponent as Bell } from 'assets/images/vector/notifications/bell.svg';
import { ReactComponent as PauseFilled } from 'assets/images/vector/pause-filled.svg';
import { ReactComponent as PauseInner } from 'assets/images/vector/pause.svg';
import { ReactComponent as Pencil } from 'assets/images/vector/pencil.svg';
import { ReactComponent as Pin } from 'assets/images/vector/pin.svg';
import { ReactComponent as PlayFilled } from 'assets/images/vector/play-filled.svg';
import { ReactComponent as PlayInner } from 'assets/images/vector/play.svg';
import { ReactComponent as Population } from 'assets/images/vector/population.svg';
import { ReactComponent as Rate } from 'assets/images/vector/rate.svg';
import { ReactComponent as RecordingCamera } from 'assets/images/vector/recording-camera.svg';
import { ReactComponent as Redo } from 'assets/images/vector/redo.svg';
import { ReactComponent as ResendAgain } from 'assets/images/vector/resend-again.svg';
import { ReactComponent as Resend } from 'assets/images/vector/resend.svg';
import { ReactComponent as TextEditorAt } from 'assets/images/vector/rich-text-editor/at.svg';
import { ReactComponent as TextEditorBold } from 'assets/images/vector/rich-text-editor/bold.svg';
import { ReactComponent as TextEditorBullet } from 'assets/images/vector/rich-text-editor/bulleted.svg';
import { ReactComponent as TextEditorLink } from 'assets/images/vector/rich-text-editor/link.svg';
import { ReactComponent as TextEditorOrder } from 'assets/images/vector/rich-text-editor/numbered.svg';
import { ReactComponent as TextEditorStrikethrough } from 'assets/images/vector/rich-text-editor/strikethrough.svg';
import { ReactComponent as TextEditorItalic } from 'assets/images/vector/rich-text-editor/talic.svg';
import { ReactComponent as TextEditorUnderline } from 'assets/images/vector/rich-text-editor/underline.svg';
import { ReactComponent as Search } from 'assets/images/vector/search.svg';
import { ReactComponent as SendInner } from 'assets/images/vector/send-inner.svg';
import { ReactComponent as Send } from 'assets/images/vector/send.svg';
import { ReactComponent as Settings } from 'assets/images/vector/settings.svg';
import { ReactComponent as Share } from 'assets/images/vector/share.svg';
import { ReactComponent as ShieldError } from 'assets/images/vector/shield-error.svg';
import { ReactComponent as ShieldSuccess } from 'assets/images/vector/shield-success.svg';
import { ReactComponent as Star } from 'assets/images/vector/star.svg';
import { ReactComponent as Calendar } from 'assets/images/vector/subscription/calendar.svg';
import { ReactComponent as Warning } from 'assets/images/vector/subscription/warning.svg';
import { ReactComponent as ThreeDots } from 'assets/images/vector/three-dots.svg';
import { ReactComponent as Triangle } from 'assets/images/vector/triangle.svg';
import { ReactComponent as Undo } from 'assets/images/vector/undo.svg';
import { ReactComponent as Union } from 'assets/images/vector/union.svg';
import { ReactComponent as Verified } from 'assets/images/vector/verified.svg';
import { ReactComponent as Video } from 'assets/images/vector/video.svg';
import { ReactComponent as VolumeOff } from 'assets/images/vector/volume-off.svg';
import { ReactComponent as VolumeUp } from 'assets/images/vector/volume-up.svg';

const ICONS_LIST = {
  at: <TextEditorAt />,
  bold: <TextEditorBold />,
  bullet: <TextEditorBullet />,
  link: <TextEditorLink />,
  strikethrough: <TextEditorStrikethrough />,
  italic: <TextEditorItalic />,
  underline: <TextEditorUnderline />,
  order: <TextEditorOrder />,

  inbox: <Inbox />,

  //languages
  'ua-square': <UaSquare />,
  'en-square': <EnSquare />,

  //menu
  account: <MyAccount />,
  community: <Community />,
  workspace: <MyWorkspace />,
  dashboard: <Dashboard />,
  events: <Events />,
  messages: <Messages />,

  //logo
  logo: <Logo />,
  'minimal-logo': <MinimalLogo />,

  'finger-print': <FingerPrint />,
  google: <Google />,
  apple: <Apple />,
  eye: <Eye />,
  'eye-crossed-out': <EyeCrossedOut />,
  star: <Star />,
  alarm: <Alarm />,
  'lock-opened': <LockOpened />,
  'lock-closed': <LockClosed />,
  'lock-closed-pink': <LockClosedPink />,
  play: <Play />,
  arrow: <Arrow />,
  'arrow-right': <ArrowRight />,
  'star-outlined': <StarOutlined />,
  send: <Send />,
  paperclip: <Paperclip />,
  cross: <Cross />,
  file: <File />,
  'cross-2': <Cross2 />,
  'shield-success': <ShieldSuccess />,
  'shield-error': <ShieldError />,
  resend: <Resend />,
  'resend-again': <ResendAgain />,
  hamburger: <Hamburger />,
  bell: <Bell />,
  triangle: <Triangle />,
  check: <Check />,
  pin: <Pin />,
  'empty-courses': <EmptyCourses />,
  'empty-notifications': <EmptyNotifications />,
  'empty-comments': <EmptyComments />,
  'empty-messages': <EmptyMessages />,
  'chat-outlined': <ChatOutlined />,
  'message-reply': <MessageReply />,
  info: <Info />,
  search: <Search />,
  moderate: <Moderate />,
  pencil: <Pencil />,
  settings: <Settings />,
  download: <Download />,
  minus: <Minus />,
  doc: <Doc />,
  video: <Video />,
  'three-dots': <ThreeDots />,
  clock: <Clock />,
  union: <Union />,
  verified: <Verified />,
  'mark-all-as-read': <MarkAllAsRead />,
  rate: <Rate />,
  population: <Population />,
  calendar: <Calendar />,
  warning: <Warning />,
  user: <User />,

  plus: <Plus />,
  chat: <Chat />,
  channel: <Channel />,
  smile: <Smile />,
  'smile-plus': <SmilePlus />,
  'message-status-unwatched': <MessageUnwatched />,
  'message-status-watched': <MessageWatched />,
  'burger-menu': <BurgerMenu />,
  selected: <Selected />,
  unselected: <Unselected />,
  incorrect: <Incorrect />,
  'community-burger': <CommunityBurger />,

  mastercard: <MasterCard />,
  visa: <Visa />,
  'visa-gray': <VisaGray />,
  'mastercard-gray': <MastercardGray />,
  'power-by-stripe': <PowerByStripe />,
  'trash-can': <TrashCan />,
  'recording-camera': <RecordingCamera />,
  'volume-off': <VolumeOff />,
  'volume-up': <VolumeUp />,
  'play-inner': <PlayInner />,
  'pause-inner': <PauseInner />,
  micro: <Micro />,
  'play-filled': <PlayFilled />,
  'pause-filled': <PauseFilled />,
  'send-inner': <SendInner />,
  share: <Share />,
  checkmark: <Checkmark />,
  dots: <Dots />,

  undo: <Undo />,
  redo: <Redo />,
};

export default ICONS_LIST;
