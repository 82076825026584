export const getCameraPermission = async () => {
  if ('MediaRecorder' in window) {
    try {
      const videoConstraints = {
        audio: true,
        video: true,
        preferCurrentTab: true,
      };
      const audioConstraints = { audio: true, preferCurrentTab: true };

      const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      const videoStream = await navigator.mediaDevices.getUserMedia(videoConstraints);

      const combinedStream = new MediaStream([
        ...videoStream.getVideoTracks(),
        ...audioStream.getAudioTracks(),
      ]);

      return { combinedStream, videoStream, audioStream };
    } catch (err) {
      throw new Error(err.message);
    }
  } else {
    throw new Error('The MediaRecorder API is not supported in your browser.');
  }
};
